import {
  Box,
  Button,
  Text,
  UnorderedList,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import ReactGA from "react-ga4";

import LineItem from "./LineItem";

const trackCheckout = (checkout: any) => {
  const lineItems = checkout.lineItems.edges.map((lineItem: any) => {
    return {
      item_id: lineItem.node.id.toString(),
      item_name: lineItem.node.title,
      item_variant: lineItem.node.variant.title.toString(),
      price: lineItem.node.variant.price,
      quantity: lineItem.node.quantity,
      currency: "USD",
      affiliation: "Feet And Eyes Guys Website",
      item_brand: "Feet And Eyes Guys",
      item_list_id: "main_product_list",
      item_list_name: "Main Product List",
    };
  });
  ReactGA.event("begin_checkout", {
    currency: "USD",
    value: checkout.totalPrice,
    items: lineItems,
  });
};

function Cart(props: any) {
  const bg = useColorModeValue("black", "grey.100");
  const color = useColorModeValue("white", "gray.100");
  const cartBackgroundColor = useColorModeValue("white", "black");
  const { checkout, handleCartClose, removeLineItemInCart, updateLineItemInCart } = props;
  const openCheckout = () => {
    window.open(checkout.webUrl);
    trackCheckout(checkout);
  };

  let line_items = checkout.lineItems.edges.map((line_item: any) => {
    return (
      <LineItem
        removeLineItemInCart={removeLineItemInCart}
        updateLineItemInCart={updateLineItemInCart}
        key={line_item.node.id.toString()}
        line_item={line_item.node}
      />
    );
  });

  return (
    <Box
      className={`Cart ${props.isCartOpen ? "Cart--open" : ""}`}
      backgroundColor={cartBackgroundColor}
    >
      <header className="Cart__header">
        <Text>Your Cart</Text>
        <button onClick={handleCartClose} className="Cart__close">
          ×
        </button>
      </header>
      <UnorderedList className="Cart__line-items">{line_items}</UnorderedList>
      <footer className="Cart__footer">
        <Box className="Cart-info clearfix">
          <Box className="Cart-info__total Cart-info__small">Subtotal</Box>
          <Box className="Cart-info__pricing">
            <Box>$ {checkout.subtotalPrice}</Box>
          </Box>
        </Box>
        <Box className="Cart-info clearfix">
          <Box className="Cart-info__total Cart-info__small">Taxes</Box>
          <Box className="Cart-info__pricing">
            <Box>$ {checkout.totalTax}</Box>
          </Box>
        </Box>
        <Box className="Cart-info clearfix">
          <Box className="Cart-info__total Cart-info__small">Total</Box>
          <Box className="Cart-info__pricing">
            <Box>$ {checkout.totalPrice}</Box>
          </Box>
        </Box>
        <Button
          mt="7"
          w="100%"
          color={color}
          backgroundColor={bg}
          onClick={openCheckout}
          isDisabled={checkout.lineItems.edges.length < 1}
        >
          Checkout
        </Button>
      </footer>
    </Box>
  );
}

export default Cart;
