import * as React from "react";
import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import ReactGA from "react-ga4";

import VideoModal from "./VideoModal";
import VideoPlayer from "./VideoPlayer";

import guy from "../assets/images/guys/DECKED_OUT_GUY.png";

const Player = <VideoPlayer url="/videos/progressive_reveal.mp4" />;

const trackNav = () => {
  ReactGA.event("page_event", {
    category: "interaction event",
    action: "click",
    label: "progressiveRevealVideo",
  });
};

const ProgressiveReveal: React.FC<any> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box width={"100%"} backgroundColor={"black"} as="section">
      <Container
        maxW={{ xl: "1200px" }}
        px={0}
        as="section"
        bgColor={"black"}
        py={100}
      >
        <Flex
          align="stretch"
          w={"100%"}
          direction={{ base: "column", md: "row" }}
          px={"1rem"}
        >
          <Box p={5} pb={0} width={"100%"} textColor="white">
            <Heading textAlign={"center"} mb={8}>
              Progressive Reveal
            </Heading>
            <Heading textAlign={"center"} mb={8} size="lg">
              TL;DR
            </Heading>
            <VStack spacing={4} textAlign={"center"}>
              <Text>
                - Progressive reveal means that the traits of your Guy will be
                revealed one at a time, post-mint.
              </Text>
              <Text>
                - Each day, you can play a game in our <Link href="/arcade" variant="standard">ARCADE</Link>, and if you complete
                the game, your next trait will be revealed
              </Text>
              <Text>- Only one trait can be revealed per day</Text>
              <Text>
                - If you don’t play the games, your Guy will still be revealed on
                a pre-determined day, which will be announced soon!
              </Text>
              <Link
                variant={"standard"}
                onClick={() => {
                  onOpen();
                  trackNav();
                }}
              >
                WATCH THE VIDEO!
              </Link>
              <Link
                variant={"standard"}
                href="#faq">
                READ MORE IN OUR FAQS!
              </Link>
            </VStack>
          </Box>
          <Flex
            width={"100%"}
            p={5}
            shadow="md"
            borderWidth="1px"
            borderRadius="40px"
            backgroundColor={"white"}
            justifyContent={"center"}
          >
            <Center
              flexDirection="column"
              onClick={() => {
                onOpen();
                trackNav();
              }}
              cursor="pointer"
            >
              <Image
                src={guy}
                maxW={340}
                alignContent={"center"}
                alt="Fancy Guy"
                mb={"20px"}
              />
              <Heading textColor="white">
                <motion.span
                  animate={{ color: ["#ffffff", "#333333"] }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    repeatType: "reverse",
                    ease: "easeOut",
                  }}
                >
                  Watch Now
                </motion.span>
              </Heading>
            </Center>
          </Flex>
        </Flex>
      </Container>
      {/* <Container maxW={{ xl: "1200px" }} px={0} as="section" backgroundColor={"black"}>
       
      </Container> */}
      <VideoModal isOpen={isOpen} onClose={onClose} BodyComponent={Player} />
    </Box>
  );
};

export default ProgressiveReveal;
