import * as React from "react";
import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./arcade.css";

import arcadeCabinet from "../assets/images/video_game_cabinet_small_square.png";
import { CSSProperties } from "react";
import { motion } from "framer-motion";

const indicatorStyles: CSSProperties = {
  background: "transparent",
  width: 16,
  height: 16,
  display: "inline-block",
  margin: "0 16px",
};

const ArcadeHomepage: React.FC<any> = (props) => {
  const handleRenderIndicator = (
    onClickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
    isSelected: boolean,
    index: number
  ) => {
    if (isSelected) {
      return (
        <li
          className="carouselItem selected"
          style={{ ...indicatorStyles }}
          aria-label={`Selected: Arcade slide ${index + 1}`}
          title={`Selected: Arcade slide ${index + 1}`}
        />
      );
    }
    return (
      <li
        className="carouselItem"
        style={indicatorStyles}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index}
        key={index}
        role="button"
        tabIndex={0}
        aria-label={`Arcade slide ${index + 1}`}
        title={`Arcade slide ${index + 1}`}
      />
    );
  };

  const stackStyle = {
    spacing: 4,
    textAlign: "center" as any,
    pb: "70px",
    px: "30px",
  };

  return (
    <Box as="section" w={"100%"}>
      <Container maxW={{ xl: "1200px" }} px={0} pb={20}>
        <Flex
          align="stretch"
          w={"100%"}
          direction={{ base: "column", md: "row" }}
        >
          <Box py={0} px={0} width={{ base: "100%", md: "55%" }} flex={"1"}>
            <Heading textAlign={"center"} mb={8} mt="8">
              The Arcade
            </Heading>
            <Carousel
              emulateTouch={true}
              autoPlay={false}
              infiniteLoop={true}
              showArrows={true}
              showIndicators={true}
              showStatus={false}
              swipeable={true}
              showThumbs={false}
              useKeyboardArrows={true}
              ariaLabel="The Arcade information slides"
              renderIndicator={handleRenderIndicator}
            >
              <VStack {...stackStyle}>
                <Text>INTRODUCING THE FEET AND EYES ARCADE!</Text>
                <Text>
                  The Feet And Eyes Guys team is proud to present: THE ARCADE!
                </Text>
                <Text>
                  We have been working hard to create a vintage ARCADE
                  experience for our community, to continue the theme of FUN
                  that we believe in so wholeheartedly!
                </Text>
                <Text>
                  Our belief at Feet And Eyes Guys is that FUN is paramount, and
                  we can’t wait to get you all into THE ARCADE!
                </Text>
                <Text>
                  When the first Feet And Eyes Guy was drawn, games like
                  Frogger, Space Invaders and Pong were all the rage. These
                  classic games are challenging but CAN be mastered, and that’s
                  what we want to see here!
                </Text>
                <Text display={{ base: "block", md: "none" }}>
                  Swipe for gameplay details
                </Text>
              </VStack>
              <VStack {...stackStyle}>
                <Text>
                  Connect your wallet (the one holding your GUY or GUYS) and
                  play one of our ARCADE GAMES! (More games coming soon!)
                </Text>
                <Text>
                  When you play the game to completion (instructions on the game
                  page) your score will be submitted and you will see a message
                  letting you know your next trait is being revealed!
                </Text>
                <Text>
                  Head over to OpenSea and check out your GUYS with their next
                  trait revealed! If you have MORE than one GUY in your wallet,
                  completing the game ONCE will reveal the next trait on ALL the
                  GUYS you hold!
                </Text>
                <Text>
                  Make sure you are playing the game connected to the same
                  wallet that holds your GUYS! If you do NOT complete the game,
                  your next trait will not be revealed until you do.
                </Text>
                <Text>
                  You can play as much and as often as you like, but only ONE
                  TRAIT will be revealed PER DAY! Miss a day? You can play
                  whenever you want to catch up! The traits will ALWAYS reveal
                  IN ORDER.
                </Text>
              </VStack>
            </Carousel>
          </Box>
          <Box
            flex={"1"}
            alignSelf="center"
            display={"flex"}
            position="relative"
            justifyContent="center"
            marginBottom={{ base: "40px", md: "0" }}
            marginTop={{ base: "40px", md: "0" }}
          >
            <Link
              href="/arcade"
              title="Goto Arcade"
              maxW="80%"
              variant={"noHover"}
            >
              <Box>
                <Image src={arcadeCabinet} />
              </Box>
              <Box
                position={"absolute"}
                top="0"
                left="0"
                width="100%"
                height="100%"
                zIndex={50}
              >
                <Center height={"100%"}>
                  <Text
                    marginTop="-14.0%"
                    fontWeight={"regular"}
                    color="black"
                    fontFamily="heading"
                    fontSize={["24px", "36px", "27px", "36px"]}
                  >
                    <motion.span
                      animate={{ color: ["#dddddd", "#333333"] }}
                      transition={{
                        duration: 1.5,
                        repeat: Infinity,
                        repeatType: "reverse",
                        ease: "easeOut",
                      }}
                    >
                      Enter Arcade
                    </motion.span>
                  </Text>
                </Center>
              </Box>
            </Link>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default ArcadeHomepage;
