

function isDev() {
  return process.env.NODE_ENV === "development";
}

const appConfig = {
  isDev: isDev(),
  functions: {
    // formBaseUrl: "", // production - mapped to functions via firebase.json config
    // formBaseUrl: "/fn", // Local Proxy is just empty.  see firebase.json for routing
    // formBaseUrl: "https://us-central1-feetandeyesguys-b3e2f.cloudfunctions.net/form", // remote.  watch for CORS
    // formBaseUrl: "http://localhost:5001/feetandeyesguys-b3e2f/us-central1/form", // functions emulator (not good b/c of cookie requirements.  use proxy on 3001 instead)
    // formBaseUrl: "http://feetandeyesguys-local.io:5000", // hosting emulator - not good as requires build
    formBaseUrl: isDev() ? "http://localhost:5001/feetandeyesguys-b3e2f/us-central1/form" : "",
    // formBaseUrl: isDev() ? "/fn" : "",
  },
  moralis: {

  }
}

export default appConfig;