import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const colorSwitch = (colorMode: string, light: string, dark: string) =>
  colorMode === "dark" ? dark : light;

const theme = extendTheme({
  fonts: {
    heading:
      '"King Of The Hill 2", -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    body: '"Andale Mono", -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  },
  initialColorMode: 'light',
  components: {
    Heading: {
      baseStyle: {
        fontWeight: "normal",
      },
      sizes: {
        xl: {
          fontSize: "3em",
        },
      },
      variants: {},
    },
    Alert: {
      variants: {
        standard: (props: any) => { // only applies to `standard` variant
          return {
            container: {
              bg: `#ffffff`,
            },
          }
        }
      }
    },
    Button: {
      baseStyle: ({ colorMode }: { colorMode: string }) => ({
        _focus: {
          boxShadow: "none",
          // textDecoration: "none",
          // backgroundColor: colorSwitch(colorMode, "white", "black"),
          // color: colorSwitch(colorMode, "white", "black"),
        },
        _hover: {
          textDecoration: "none",
          backgroundColor: colorSwitch(colorMode, "black", "transparent"),
          color: colorSwitch(colorMode, "white", "gray.500"),
        },
        _active: {
          textDecoration: "none",
          backgroundColor: colorSwitch(colorMode, "black", "transparent"),
          color: colorSwitch(colorMode, "white", "gray.500"),
        },
      }),
      variants:  {
        mint: ({ colorMode }: { colorMode: string }) => ({
          backgroundColor: "gray.100",
          fontWeight: "bold",
          textTransform: "uppercase",
          fontFamily: 'body',
          color: "gray.700",
          _hover: {
            textDecoration: "none",
            backgroundColor: colorSwitch(colorMode, "gray.100", "gray.100"),
            color: colorSwitch(colorMode, "gray.900", "gray.900"),
          },
          _active: {
            textDecoration: "none",
            backgroundColor: colorSwitch(colorMode, "black", "transparent"),
            color: colorSwitch(colorMode, "white", "gray.500"),
          },
        }),
        clear: {
          backgroundColor: "transparent",
          _hover: {
            textDecoration: "none",
            backgroundColor: "transparent",
            color: "transparent",
          },
          _active: {
            textDecoration: "none",
            backgroundColor: "transparent",
            border: "2px",
            borderColor: "black",
          },  
        },
        textButton: {
          fontWeight: "bold",
          textTransform: "uppercase",
          fontSize: "4xl",
          fontFamily: 'heading',
          _hover: {
            textDecoration: "none",
            backgroundColor: "white",
            color: "black",
          },  
        },
        invertTextButton: (props: any) => ( {
          fontWeight: "bold",
          textTransform: "uppercase",
          fontSize: "4xl",
          fontFamily: 'heading',
          color: mode("white", "white")(props),
          _hover: {
            textDecoration: "none",
            backgroundColor: mode("white", "black")(props),
            color: mode("black", "white")(props),
          },
        }),
        nudge: (props: any) => ({
          fontSize: "2em",
          fontFamily: 'body',
          border: "1px solid",
          paddingX: 0,
          _hover: {
            textDecoration: "none",
            backgroundColor: mode("white", "black")(props),
            color: mode("black", "white")(props),
          },
        }),
      },
    },
    Link: {
      baseStyle: ({ colorMode }: { colorMode: string }) => ({
        px: 2,
        py: 1,
        rounded: "md",
        width: "100%",
        _focus: {
          boxShadow: "none",
          textDecoration: "none",
          backgroundColor: colorSwitch(colorMode, "black", "black"),
          color: colorSwitch(colorMode, "white", "black"),
        },
        _hover: {
          textDecoration: "none",
          backgroundColor: colorSwitch(colorMode, "black", "transparent"),
          color: colorSwitch(colorMode, "white", "gray.500"),
        },
        _active: {
          textDecoration: "none",
          backgroundColor: colorSwitch(colorMode, "black", "black"),
          color: colorSwitch(colorMode, "white", "gray.500"),
        },
      }),
      variants: {
        standard: {
          px: 0,
          py: 2,
          textDecoration: "underline",
          _focus: {
            textDecoration: "underline",
          },
          _hover: {
            textDecoration: "underline",
          },
          _active: {
            textDecoration: "underline",
          },
        },
        noHover: {
          _focus: {
            boxShadow: "none",
            textDecoration: "none",
            backgroundColor: "transparent",
            color: "transparent",
          },
          _hover: {
            textDecoration: "none",
            backgroundColor: "transparent",
            color: "transparent",
          },
          _active: {
            textDecoration: "none",
            backgroundColor: "transparent",
            color: "transparent",
          },
        }
      },
    },

  },
});

export default theme;
