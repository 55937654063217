import {
  // FC,
  forwardRef,
  memo,
  // useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useCanvasContext } from "../../../hooks/useCanvas";
import FroggerGame from "./entity/Frogger";
// import useResponsiveSize from "../../../hooks/useResponsiveSize";


const scale = 2;
const width = 480 * scale;
const height =  (660 * scale) + 260; // for the controls

const Frogger = memo(forwardRef((props: any, ref: any) => {
  const { onGameOver, onReady } = props;
  const { context } = useCanvasContext();
  const [game, setGame] = useState<FroggerGame | null>(null);
  // const { width, height } = useResponsiveSize();
  useEffect(() => {
    if (context && !game) {
      // console.log("mounting game engine");
      const froggerGame = new FroggerGame(null, context, width, height);
      froggerGame.setOnGameOverHandler((data: any) => {
        if (onGameOver) {
          onGameOver(data);
        }
      });
      setGame(froggerGame);
    }
    return () => {
      if (game) {
        console.log("un-mounting game engine");
        game.close();
        setGame(null);
      }
    };
  }, [context, game, onGameOver, onReady]);

  useEffect(() => {
    if (game) {
      onReady(true);
    }
  }, [game, onReady]);

  useImperativeHandle(ref, () => ({
    startGame() {
      if (game) {
        game.start();
      } else {
        alert('There was an error starting the game.  Please report this to @FeetAndEyesGuys or let a mod know via Discord')
      }
    },
    moveUp() {
      if (game) {
        game.moveUp();
      }
    },
    moveDown() {
      if (game) {
        game.moveDown();
      }
    },
    moveLeft() {
      if (game) {
        game.moveLeft();
      }
    },
    moveRight() {
      if (game) {
        game.moveRight();
      }
    },

  }));

  // useEffect(()=>{
  //   if (game) {
  //     game.setDims(height, width);
  //   }
  // }, [height, width, game]);

  const render = () => {
    // if content then game.update()
    // requestAnimationFrame(render)
  };
  if (context) render();
  return null;
}));

export default Frogger;
