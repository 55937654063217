import * as React from "react";
import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";

import rareGuys from "../assets/images/RARE_GUYS_PAGE.png";
import { Carousel } from "react-responsive-carousel";
import { CSSProperties } from "react";

const stackStyle = {
  spacing: 4,
  textAlign: "center" as any,
  pb: "70px",
  px: "30px",
};

const indicatorStyles: CSSProperties = {
  background: "transparent",
  width: 16,
  height: 16,
  display: "inline-block",
  margin: "0 16px",
};

const Rarity: React.FC<any> = (props) => {
  const handleRenderIndicator = (
    onClickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
    isSelected: boolean,
    index: number
  ) => {
    if (isSelected) {
      return (
        <li
          className="carouselItem selected"
          style={{ ...indicatorStyles }}
          aria-label={`Selected: Arcade slide ${index + 1}`}
          title={`Selected: Arcade slide ${index + 1}`}
        />
      );
    }
    return (
      <li
        className="carouselItem"
        style={indicatorStyles}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index}
        key={index}
        role="button"
        tabIndex={0}
        aria-label={`Arcade slide ${index + 1}`}
        title={`Arcade slide ${index + 1}`}
      />
    );
  };

  return (
    <Box as="section" w={"100%"}>
      <Container maxW={{ xl: "1200px" }} px={0} pb={20}>
        <Flex
          align="stretch"
          w={"100%"}
          direction={{ base: "column", md: "row" }}
        >
          <Box
            py={0}
            px={"40px"}
            width={{ base: "100%", md: "55%" }}
            flex={"1"}
            textAlign="center"
          >
            <Heading textAlign={"center"} mb={8} mt="8">
              Rarity
            </Heading>

            <Carousel
              emulateTouch={true}
              autoPlay={false}
              infiniteLoop={true}
              showArrows={true}
              showIndicators={true}
              showStatus={false}
              swipeable={true}
              showThumbs={false}
              useKeyboardArrows={true}
              ariaLabel="The Arcade information slides"
              renderIndicator={handleRenderIndicator}
            >
              <VStack {...stackStyle}>
                <Text mt="5">
                  FEET AND EYES GUYS worked with rarity partner{" "}
                  <Link
                    href="https://www.raraavis.app/feet-and-eyes-guys-tokens"
                    target="_blank"
                    variant="standard"
                    rel="noopener"
                  >
                    Rara Avis
                  </Link>{" "}
                  exclusively on rarity rankings for the project in order to
                  accurately reflect the true rarity of all tokens in the
                  collection. The Rara Avis approach centers around
                  creator-guided, mathematically sound, thematic rarity rankings
                  tailored specifically to each individual collection.
                </Text>
                <Text mt="5">
                  The Rara Avis certified rankings for FEET AND EYES GUYS will
                  be live at Rara Avis on June 24th 2022. Until then please keep
                  in mind that rankings on other rarity sites will not reflect
                  the entire collection and the percentages of traits shown on
                  NFT marketplaces are subject to change.{" "}
                </Text>
              </VStack>
              <VStack {...stackStyle}>
                <Text mt="5">
                  What makes Rara Avis different than the other rarity tool
                  platforms on the market?
                </Text>
                <Text mt="5">
                  They provide end-to-end metadata planning for generative NFT
                  projects so that project creators can visualize the impact of
                  their trait planning on thematic rankings throughout the
                  conceptualization and pre-mint phase of their projects.{" "}
                </Text>
                <Text mt="5">What are thematic rarity rankings?</Text>
                <Text mt="5">
                  Thematic rarity rankings were developed by Rara Avis to
                  mathematically quantify the relative rarity of aesthetically
                  matching traits that randomly appear in generative PFP
                  projects.
                </Text>
              </VStack>
              <VStack {...stackStyle}>
                <Text mt="5">Who calculates the rarity?</Text>
                <Text mt="5">
                  Meghna is a data science practitioner with over two decades of
                  experience developing a range of artificial
                  intelligence/machine learning solutions for fortune fifty
                  companies.
                </Text>
                <Text mt="5">
                  Badteeth is a degen from the Class of 2021 who was lucky
                  enough to mint apes and got a freeroll to make every mistake
                  possible trading NFTs.
                </Text>
              </VStack>
            </Carousel>
          </Box>
          <Box
            flex={"1"}
            alignSelf="center"
            display={"flex"}
            position="relative"
            justifyContent="center"
            marginBottom={{ base: "40px", md: "0" }}
            marginTop={{ base: "40px", md: "0" }}
            px="40px"
            pt="20px"
          >
            <Box>
              <Image src={rareGuys} maxH={{ base: "320px", md: "550px" }} />
            </Box>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default Rarity;
