// import { allowList } from './allow-list';

// Main FEQ Contract
const contractAddress = '0xbf6301236d71C0d49144b35241905794F5a10BB9'; // mainnet, TL
// const contractAddress = '0x759457197B71bcD06Cf93bAFC45c82952751d2B8'; // testnet, TL
// const contractAddress = '0x7414e38377D6DAf6045626EC8a8ABB8a1BC4B97a'; // local ganache


// const allowList = [
//   '0x0ee70a32Ce77C5e5e1b52Ef0766410A20Ad27C0e',// cc
//   '0xF4DB918906946B53C8db2292239aC1c8B94145f6',// mp
//   '0x47378d00e55ebc8867D25c7488ceC71411904219',// mp
//   '0x181f59eB6490c8Bf73D291af0d5a56dc90D7cd8B',// mp
//   '0xF95aBA9A9517E3A4EA9A004045F51fC67c52B010',// ah FeetTest1
//   '0xfA2d4230f60e18eb45a859dA5C416d93b7c08D69',// ah FeetTest2
//   '0x90F8bf6A479f320ead074411a4B0e7944Ea8c9C1',// ah local-ganache
//   '0x6453B4A77b11fe5fAC528c00311E6ff465f14eF7',// mp
//   '0xaF3d5030A781c2e186fAf7EF313B55EFd119886E',// bs
//   '0x21F3eada6058d37FAD84F20C9Da08c9A5b217058',// bs
// ];

// const openSeaURL = 'https://testnets.opensea.io/assets';
// const openSeaCollectionURL = 'https://testnets.opensea.io/collection/feet-and-eyes-guys';


// const shoppeTokenContractAddress = '0xE2596b1EFc5B82fE89617Eb796e449A749C9b3e7'; // testnet
const shoppeTokenContractAddress = '0x1bf0431760a5e6afece77f1071942879b168c934'; // production

const openSeaURL = 'https://opensea.io/assets';
// const openSeaURL = 'https://testnets.opensea.io/assets/rinkeby';
const openSeaCollectionURL = 'https://opensea.io/collection/feet-and-eyes-guys';

const netName = 'Mainnet';
const requiredChainId = 0x1; // mainnet

// const netName = 'Testnet';
// const requiredChainId = 0x4; // testnet

// const requiredChainId = 51337;

const allowList: string[] = [];

export {
  allowList,
  contractAddress,
  netName,
  openSeaCollectionURL,
  openSeaURL,
  requiredChainId,
  shoppeTokenContractAddress,
}