import * as React from "react";
import { Helmet } from "react-helmet";

import AppContainer from "../../components/AppContainer";
import AllowListClosed from "../../components/AllowListClosed";

const AllowListClosedRoute: React.FC<any> = () => {
  return (
    <AppContainer>
      <Helmet>
        <title>Allow List: Too Late</title>
      </Helmet>
      <AllowListClosed />
    </AppContainer>
  );
};

export default AllowListClosedRoute;
