import { createContext, useContext, useState } from "react"
import { useLocalStorage } from "react-use";
import { TeamType } from '../Games/GameStructures';

export interface GameContextData {
  team: TeamType;
  setTeam: (teamType: TeamType) => void;
}

const GameContext = createContext<GameContextData>({
  team: TeamType.none,
  setTeam: () => {},
})

interface ProviderProps {
  children: React.ReactNode,
}

export const GameContextProvider = ({children} : ProviderProps) => {
  const [userTeam, setUserTeam] = useLocalStorage<TeamType>('userTeam', TeamType.none);
  const [team, setTeamState] = useState<TeamType>(userTeam || TeamType.none);
  const setTeam = (team: TeamType) => {
    setUserTeam(team);
    setTeamState(team);
  }
  return (
    <GameContext.Provider value={{ team, setTeam }}>
      {children}
    </GameContext.Provider>
  );
};

export const useGameContext = () => useContext(GameContext);
