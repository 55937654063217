import * as React from "react";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  FormLabel,
  Heading,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

import {
  openSeaURL,
  shoppeTokenContractAddress,
} from "../../contract/contract";
import useFEGWallet from "../../hooks/useFEGWallet";
import { ethers } from "ethers";

const twitterHref = (id: number) =>
  encodeURI(
    `https://twitter.com/intent/tweet?text=I just minted my @FeetAndEyesGuys Paint Shoppe Access Token!\n${openSeaURL}/${shoppeTokenContractAddress}/${id}\n&url=https://feetandeyesguys.io/`
  );

const osHref = (id: number) =>
  `${openSeaURL}/${shoppeTokenContractAddress}/${id}`;

const ConfirmBody = () => (
  <Text>
    By agreeing to mint this token you understand that the Paint Shoppe Access
    Token cannot be sold, transferred or burned. Please be certain you are
    minting this token to the wallet that contains your Feet And Eyes Guys NFTs.
  </Text>
);

const MintConfirmModal = ({
  onClose,
  onCancel,
  isOpen,
  title,
  BodyComponent,
  mintAddress,
}: {
  onClose: any;
  onCancel: any;
  isOpen: boolean;
  title: string;
  BodyComponent: any;
  mintAddress: string;
}) => {
  return (
    <Modal onClose={onCancel} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent zIndex="999999">
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton
          _active={{
            border: "2px",
            borderColor: "black",
          }}
          _focus={{
            border: "2px",
            borderColor: "black",
          }}
        />
        <ModalBody zIndex="999999">
          <BodyComponent />
          <Text mt="5" fontSize={14} fontWeight={"bold"}>
            MINT TO WALLET: {mintAddress}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onCancel} variant="ghost" mr={3}>
            Cancel
          </Button>
          <Button onClick={onClose}>Let's do it!</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const BuyPaintShoppeToken: React.FC<any> = (props) => {
  const {
    account,
    buyPaintShoppeAccessToken,
    connectWallet,
    shoppeTokenContractState,
    disconnect,
    provider,
    queryGuyBalance,
    queryingGuyBalance,
    queryingShoppeContractStatus,
    signerAddress,
    txSubmittingPaintShoppeAccessToken,
    mintedShoppeAccessTokenId,
  } = useFEGWallet(true, false);

  const [displayMintInfo, setDisplayMintInfo] = useState<boolean>(false);
  const [mintAddress, setMintAddress] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (mintedShoppeAccessTokenId > 0) {
      setDisplayMintInfo(true);
    } else {
      setDisplayMintInfo(false);
    }
  }, [mintedShoppeAccessTokenId]);

  const checkGuyBalance = (address: string) => {
    queryGuyBalance(address).then((res) => {
      console.debug(
        `resolved balanceOf is: ${res.balanceOf}, balance of guys ${res.balanceOfGuys}`
      );
      if (res.balanceOfGuys > 0 && res.balanceOf < 1) {
        onOpen();
      } else {
        if (res.balanceOfGuys < 1) {
          alert(
            "The wallet you are MINTING TO does NOT contain any Feet And Eyes Guys NFTs. Please input a wallet address that contains at least ONE Feet And Eyes Guys NFT.\n\nREMINDER: Your Paint Shoppe Access Token CANNOT be transferred, burned or sold. It must be in the same wallet as your Feet And Eyes Guys in order to color them."
          );
        } else if (res.balanceOf > 0) {
          alert(
            "The wallet address you are minting to already has a Paint Shoppe Access Token."
          );
        } else {
          alert(
            "There was an error fetching the details about your tokens.  Please reload the page and try again.  If the problem persists, please DM @FeetAndEyesGuys."
          );
        }
      }
    });
  };

  const onMintToken = () => {
    if (!signerAddress) {
      alert(
        "Unable to determine your wallet address.\n\nPlease check your wallet is still connected and try reloading the page."
      );
      return;
    }
    if (mintAddress) {
      try {
        if (mintAddress.indexOf(".eth") > -1) {
          if (provider && provider.resolveName) {
            provider.resolveName(mintAddress).then((res: string | null) => {
              if (!res) {
                alert(
                  "The Mint address provided is not valid.  Please enter a valid address and try again."
                );
                return;
              } else {
                console.log(`ENS resolved address is: ${res}`);
                checkGuyBalance(res);
              }
            });
          } else {
            alert(
              "The Mint address provided is not valid.  Please enter a valid address and try again."
            );
            return;
          }
        } else {
          const checkedAddress = ethers.utils.getAddress(mintAddress);
          checkGuyBalance(checkedAddress);
        }
      } catch (e) {
        alert(
          "The Mint address provided is not valid.  Please enter a valid address and try again."
        );
        return;
      }
    } else {
      checkGuyBalance(signerAddress);
    }
  };

  const onCloseConfirm = () => {
    if (!signerAddress) {
      alert("Internal error.  Cannot resolve wallet address.");
      return;
    }
    onClose();
    if (mintAddress) {
      try {
        if (mintAddress.indexOf(".eth") > -1) {
          if (provider && provider.resolveName) {
            provider.resolveName(mintAddress).then((res: string | null) => {
              if (!res) {
                alert(
                  "The Mint address provided is not valid.  Please enter a valid address and try again."
                );
                return;
              } else {
                console.log(`will use ENS resolved address: ${res} to mint`);
                buyPaintShoppeAccessToken(res);
              }
            });
          } else {
            alert(
              "The Mint address provided is not valid.  Please enter a valid address and try again."
            );
            return;
          }
        } else {
          const checkedAddress = ethers.utils.getAddress(mintAddress);
          console.log(`will use ${checkedAddress} to mint`);
          buyPaintShoppeAccessToken(checkedAddress);
        }
      } catch (e) {
        alert(
          "The Mint address provided is not valid.  Please enter a valid address and try again."
        );
      }
    } else {
      buyPaintShoppeAccessToken(signerAddress);
    }
  };

  const onCancelConfirm = () => {
    onClose();
  };

  const handleOnWalletAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMintAddress(event.target.value);
    console.log(`setting mint address to ${event.target.value}`);
  };

  return (
    <Box as="section" w={"100%"}>
      <Container maxW={{ xl: "1200px" }} px={0}>
        <Flex
          align="stretch"
          w={"100%"}
          margin="auto"
          direction={{ base: "column", md: "row" }}
        >
          <Box p={5} width={"100%"} margin="auto" textAlign={"center"}>
            <Heading textAlign={"center"} mb={8} mt="8">
              {displayMintInfo
                ? "Mint Complete!"
                : "Mint a Paint Shoppe Access Token!"}
            </Heading>
            <VStack spacing={4} textAlign={"center"} pb="10">
              {!account ? (
                <div>
                  <Text>Please connect the wallet you wish to MINT FROM.</Text>
                  <Button
                    onClick={connectWallet}
                    wordBreak="break-word"
                    whiteSpace={"normal"}
                    mt="10"
                  >
                    Connect Wallet to Begin
                  </Button>
                </div>
              ) : null}
              {!displayMintInfo && queryingShoppeContractStatus ? (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="black"
                  size="xl"
                />
              ) : null}
              {displayMintInfo && account ? (
                <>
                  <Text>
                    You have successfully minted the Paint Shoppe Access Token!
                  </Text>
                  <Text>
                    Time to paint your Guys! Head over to the{" "}
                    <Link variant={"standard"} href="/paintshoppe">
                      Paint Shoppe
                    </Link>{" "}
                    to get started!
                  </Text>
                  <Text mt="5">
                    View your token on{" "}
                    <Link
                      variant={"standard"}
                      target="_blank"
                      href={osHref(mintedShoppeAccessTokenId)}
                      referrerPolicy={"no-referrer"}
                    >
                      OpenSea
                    </Link>{" "}
                    and don't forget to let the WORLD know via{" "}
                    <Link
                      variant={"standard"}
                      target="_blank"
                      href={twitterHref(mintedShoppeAccessTokenId)}
                      referrerPolicy={"no-referrer"}
                    >
                      Twitter
                    </Link>
                    !
                  </Text>
                </>
              ) : null}
              {!displayMintInfo && !queryingShoppeContractStatus && account ? (
                <>
                  {shoppeTokenContractState.canBuy &&
                  shoppeTokenContractState.saleOpen ? (
                    <>
                      <Text
                        marginTop="6px !important"
                      >
                        Paint Shoppe Access Tokens are available to mint for{" "}
                        {shoppeTokenContractState.mintPrice} ETH. The Paint
                        Shoppe Access Token is a SOULBOUND TOKEN. This means it
                        CANNOT be sold, burned, or transferred between wallets.
                        For the Paint Shoppe Access Token to work, it must be
                        held in the wallet in which you store the Feet And Eyes
                        Guys NFTs you wish to color. You may use any wallet to
                        mint, but the wallet you MINT TO must contain at least
                        ONE Feet And Eyes Guys NFT in order to complete the
                        transaction.
                      </Text>
                      <Text>
                        We know that the idea of a NON-TRANSFERRABLE token is
                        relatively new, but this token is an access pass only,
                        and has no inherent value, therefore does not need to be
                        part of the secondary marketplace. There are an
                        unlimited number of Paint Shoppe Access Tokens so that
                        new members of our community do not miss out on the fun.
                        The Paint Shoppe Access Token is required to access the
                        color features of the Feet And Eyes Guys ecosystem,
                        including Standard Color and all future limited editions
                        and collab colorways.
                      </Text>
                      <FormLabel textAlign={"left"}>
                        Mint to wallet address (the wallet with FEG NFTs)
                        <Input
                          placeholder="Wallet address (required)"
                          disabled={txSubmittingPaintShoppeAccessToken}
                          value={mintAddress}
                          onChange={handleOnWalletAddressChange}
                        ></Input>
                      </FormLabel>
                      <Button
                        onClick={onMintToken}
                        isLoading={
                          txSubmittingPaintShoppeAccessToken ||
                          queryingShoppeContractStatus ||
                          queryingGuyBalance
                        }
                        disabled={!mintAddress}
                        loadingText={
                          queryingGuyBalance ? "Checking" : "Minting"
                        }
                        mb="3"
                      >
                        Mint Token
                      </Button>
                      <Text fontSize={12} fontWeight="bold" mt="0px !important">
                        {shoppeTokenContractState.mintPrice} ETH
                      </Text>
                    </>
                  ) : null}
                  {!shoppeTokenContractState.saleOpen &&
                  shoppeTokenContractState.canBuy ? (
                    <>
                      <Text>Mint is not yet open, please check back soon!</Text>
                      <Text>
                        Follow us on Twitter and Discord for the lastest
                        updates.
                      </Text>
                    </>
                  ) : null}
                  {shoppeTokenContractState.balanceOf > 0 && !mintAddress ? (
                    <>
                      <Text>
                        Your connected wallet already contains a Paint Shoppe
                        Access Token!
                      </Text>
                      <Text>
                        You're ready to paint in the{" "}
                        <Link variant={"standard"} href="/paintshoppe">
                          Paint Shoppe!
                        </Link>
                      </Text>
                    </>
                  ) : null}
                  {/* {shoppeTokenContractState.balanceOf === 0 &&
                  shoppeTokenContractState.balanceOfGuys < 1 ? (
                    <>
                      <Text>
                        The wallet you are sending your Paint Shoppe Access
                        Token to does NOT contain any Feet And Eyes Guys. Please
                        change this to the wallet you want to send your Paint
                        Shoppe Access Token to (it must hold at least one Feet
                        And Eyes Guys token). REMINDER: Your Paint Shoppe Access
                        Token CANNOT be transferred, burned or sold. It must be
                        in the same wallet as your Feet And Eyes Guys in order
                        to color them.
                      </Text>
                    </>
                  ) : null} */}

                  <Box pt="20">
                    <Text wordBreak="break-word" whiteSpace={"normal"} mb={"2"}>
                      You're connected using wallet address: {signerAddress}
                    </Text>
                    <Button
                      onClick={disconnect}
                      wordBreak="break-word"
                      whiteSpace={"normal"}
                    >
                      Disconnect Wallet
                    </Button>
                  </Box>
                </>
              ) : null}
            </VStack>
            {/* <Divider mt="200px" />
            <Text>
              Just debug data below. This will not be on the live site
            </Text>
            <Text>[debug] contract address is: {contractAddress}</Text>
            <Text>
              [debug] shoppe contract address is: {shoppeTokenContractAddress}
            </Text>
            <Text>[debug] signer address is: {signerAddress}</Text>
            <Text>[debug] chainId is: {chainId}</Text>
            <Text>
              [debug] network is: {network ? network.name : "unknown"}
            </Text>
            <Text>
              [debug] shoppeTokenContractState:{" "}
              {shoppeTokenContractState
                ? JSON.stringify(shoppeTokenContractState, null, 2)
                : "unknown"}
            </Text> */}
          </Box>
        </Flex>
        <MintConfirmModal
          isOpen={isOpen}
          onClose={onCloseConfirm}
          onCancel={onCancelConfirm}
          title="Confirm Mint"
          BodyComponent={ConfirmBody}
          mintAddress={mintAddress}
        />
      </Container>
      {/* Modal */}
      {/* By agreeing to mint this token you understand that the Paint Shoppe Access Token cannot be sold, transferred or burned. Please be certain you are minting this token to the wallet that contains your Feet And Eyes Guys NFTs. */}
    </Box>
  );
};

export default BuyPaintShoppeToken;
