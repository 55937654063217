const WinSequenceCount = 10;
class SimonGame {
  engine;
  width = 0;
  height = 0;

  constructor(context, width, height) {
    this.width = width;
    this.height = height;
    function init(SimonEngine) {
      // mods by Patrick OReilly
      // Twitter: @pato_reilly Web: http://patricko.byethost9.com
      let game;
      let simon;
      var N = 1;
      let userCount = 0;
      let currentCount = 0;
      let sequenceList = [];
      let simonSez = false;
      let timeCheck;
      let litSquare;
      let winner;
      let loser;
      let intro;
      let correctSquare;
      let thisSquare;
      let currentText = null;
      let score = 0;

      game = new window.Phaser.Game(SimonEngine.width, SimonEngine.height, window.Phaser.AUTO, context, { preload: preload, create: create, update: update, render: render });
      
      function preload() {
        game.scale.scaleMode = window.Phaser.ScaleManager.SHOW_ALL;
        game.load.spritesheet('item', '/buttons/number-buttons_SMALL.png', 160, 160);
      }

      function create() {
        simon = game.add.group();
        let item;
        const padSize = 168;
        for (let i = 0; i < 3; i++) {
          item = simon.create(padSize * i, 0, 'item', i);
          // Enable input.
          item.inputEnabled = true;
          item.input.start(0, true);
          item.events.onInputDown.add(select);
          item.events.onInputUp.add(release);
          item.events.onInputOut.add(moveOff);
          simon.getAt(i).alpha = 0;
        }

        for (var i = 0; i < 3; i++) {
          item = simon.create(padSize * i, padSize, 'item', i + 3);
          // Enable input.
          item.inputEnabled = true;
          item.input.start(0, true);
          item.events.onInputDown.add(select);
          item.events.onInputUp.add(release);
          item.events.onInputOut.add(moveOff);
          simon.getAt(i + 3).alpha = 0;
        }

        introTween();
        setUp();
        setTimeout(function () { simonSequence(); intro = false; }, 3500);
      }

      // function restart() {
      //   N = 1;
      //   userCount = 0;
      //   currentCount = 0;
      //   sequenceList = [];
      //   winner = false;
      //   loser = false;
      //   score = 0;
      //   introTween();
      //   setUp();
      //   setTimeout(function () { simonSequence(); intro = false; }, 6000);
      // }

      function introTween() {

        intro = true;
        const repeatCount = 1;
        const yoyo = true;
        const delay = 0;
        const autoStart = true;
        const duration = 500;
        for (let i = 0; i < 6; i++) {
          const flashing = game.add.tween(simon.getAt(i)).to({ alpha: 1 }, duration, window.Phaser.Easing.Linear.None, autoStart, delay, repeatCount, yoyo);
          const final = game.add.tween(simon.getAt(i)).to({ alpha: .25 }, duration, window.Phaser.Easing.Linear.None, autoStart);

          flashing.chain(final);
          flashing.start();
        }

      }

      function update() {

        if (simonSez) {
          if (game.time.now - timeCheck > 700 - N * 40) {
            simon.getAt(litSquare).alpha = .25;
            game.paused = true;

            setTimeout(function () {
              if (currentCount < N) {
                game.paused = false;
                simonSequence();
              }
              else {
                simonSez = false;
                game.paused = false;
              }
            }, 400 - N * 20);
          }
        }
      }

      function playerSequence(selected) {

        correctSquare = sequenceList[userCount];
        userCount++;
        thisSquare = simon.getIndex(selected);
        
        if (thisSquare === correctSquare) {
          score += 150;
          if (userCount === N) {
            if (N === WinSequenceCount) {
              winner = true;
              SimonEngine.gameOverHandler({
                game: 'norman',
                stage: String(WinSequenceCount),
                winner: true,
                state: 'gameOver',
                score: String(score),
              })
            }
            else {
              userCount = 0;
              currentCount = 0;
              N++;
              simonSez = true;
            }
          }
        }
        else {
          loser = true;
          SimonEngine.gameOverHandler({
            game: 'norman',
            stage: String(N),
            winner: false,
            state: 'gameOver',
            score: String(score),
          })
          // setTimeout(function () { restart(); }, 3000);
        }

      }

      function simonSequence() {

        simonSez = true;
        litSquare = sequenceList[currentCount];
        simon.getAt(litSquare).alpha = 1;
        timeCheck = game.time.now;
        currentCount++;

      }

      function setUp() {

        for (var i = 0; i < WinSequenceCount; i++) {
          thisSquare = game.rnd.integerInRange(0, 5);
          sequenceList.push(thisSquare);
        }

      }

      function select(item, pointer) {

        if (!simonSez && !intro && !loser && !winner) {
          item.alpha = 1;
        }

      }

      function release(item, pointer) {

        if (!simonSez && !intro && !loser && !winner) {
          item.alpha = .25;
          playerSequence(item);
        }
      }

      function moveOff(item, pointer) {

        if (!simonSez && !intro && !loser && !winner) {
          item.alpha = .25;
        }

      }

      function renderText(text) {
        if (SimonEngine.gameStatusHandler) {
          if (currentText !== text) {
            SimonEngine.gameStatusHandler(text);
            currentText = text;
            // console.log(`calling render text:${text}`);
          }
        }
      }

      function render() {
        // const font = undefined;
        if (!intro) {
          if (simonSez) {
            renderText('Norman Says');
            // game.debug.text('Simon Says', 360, 96, 'rgb(255,0,0)', font);
          }
          else {
            renderText('Your Turn');
            // game.debug.text('Your  Turn', 220, 96, 'rgb(0,255,0)', font);
          }
        }
        else {
          renderText('Get Ready');
          // game.debug.text('Get Ready', 360, 96, 'rgb(0,0,255)', font);
        }

        if (winner) {
          // game.debug.text('You Win!', 360, 32, 'rgb(0,0,255)', font);
        }
        else if (loser) {
          // game.debug.text('You Lose!', 360, 32, 'rgb(0,0,255)', font);
        }
      }
    }
    init(this);
  }
  /**
 * 
 * @param {() => void)} handler 
 */
  setOnGameOverHandler(handler) {
    this.gameOverHandler = handler;
  }

  /**
* 
* @param {() => void)} handler 
*/
  setOnGameStatusHandler(handler) {
    this.gameStatusHandler = handler;
  }

  close() {
    console.log('simon close');
  }

  start() {
    console.log('simon start');
  }

  press(id) {
    console.log(`simon press: ${id}`);
  }
}

export default SimonGame;