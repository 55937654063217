import * as React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet";
import AppContainer from "../../components/AppContainer";
import Arcade from "../../components/Arcade/index-simon";
import { GameContextProvider } from "../../components/Arcade/GameContext";
import recaptchaConfig from "../../lib/recapture";

const ArcadeRoute: React.FC<any> = () => {
  return (
    <GoogleReCaptchaProvider
    reCaptchaKey={recaptchaConfig.id}
    scriptProps={{
      async: false, // optional, default to false,
      defer: false, // optional, default to false
      appendTo: 'head', // optional, default to "head", can be "head" or "body",
      nonce: undefined // optional, default undefined
      }}
      >
      <AppContainer>
        <Helmet>
          <title>Arcade: Norman</title>
          <meta name="apple-mobile-web-app-capable" content="yes"></meta>
        </Helmet>
        <GameContextProvider>
          <Arcade />
        </GameContextProvider>
    </AppContainer>
    </GoogleReCaptchaProvider>
  );
};

export default ArcadeRoute;
