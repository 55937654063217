import * as React from 'react';
import {
  Box, Flex, IconButton, VStack, Image,
} from "@chakra-ui/react";
import { useRef, useEffect, useState, forwardRef, useImperativeHandle } from "react";

import { CanvasContext } from "../../../hooks/useCanvas";
import { GameData } from "../GameStructures";
import Frogger from "./frogger";


import arrowUp from '../../../assets/images/games/ARROW_UP.png';
import arrowLeft from '../../../assets/images/games/ARROW_LEFT.png';
import arrowRight from '../../../assets/images/games/ARROW_RIGHT.png';
import arrowDown from '../../../assets/images/games/ARROW_DOWN.png';
interface Props {
  onGameOver: (gameData: GameData) => void;
  onReady: (ready: boolean) => void;
}

const FroggerCanvas = React.memo(forwardRef(({ onGameOver, onReady }: Props, ref: any) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const gameRef = useRef<any>();
  const [context, setContext] = useState<
    CanvasRenderingContext2D | undefined
  >();

  useEffect(() => {
    const ctx = canvasRef?.current?.getContext("2d");
    if (ctx) setContext(ctx);
  }, []);

  useImperativeHandle(ref, () => ({
    startGame() {
      if (gameRef.current) {
        console.log('starting game from canvas')
        gameRef.current.startGame();
        return true;
      }
      return false;
    },
  }));

  const move = (direction: string) => {
    if (gameRef.current) {
      switch (direction) {
        case "up": gameRef.current.moveUp(); break;
        case "down": gameRef.current.moveDown(); break;
        case "left": gameRef.current.moveLeft(); break;
        case "right": gameRef.current.moveRight(); break;
      }
    }
  }

  const scale = 2;
  const w = 480 * scale;
  const h = 660 * scale;

  console.log('render frogger canvas');

  return (
    <Box position={"relative"}>
      <CanvasContext.Provider value={{ context: context }}>
        <canvas
          id="canvas"
          className="canvas"
          ref={canvasRef}
          width={w}
          height={h}
        ></canvas>
        <Frogger ref={gameRef} onGameOver={onGameOver} onReady={onReady} />
        <Box backgroundColor={"purple"} width="100%" height="0px">

        </Box>
        <VStack width="480px" maxWidth="80%" margin="auto" display={{ base: 'flex', md: 'none' }}>
          <VStack maxH={"20px"}>
            <IconButton
              variant={"unstyled"}
              aria-label='Up'
              onClick={() => move("up")}
              icon={<Image src={arrowUp} maxH="36px" />}
            /></VStack>
          <Flex width="100%" justifyContent="space-around" maxH={"20px"}>
            <IconButton
              variant={"unstyled"}
              aria-label='Left'
              onClick={() => move("left")}
              icon={<Image src={arrowLeft} maxH="36px" />} />
            <IconButton
              variant={"unstyled"}
              aria-label='Right'
              onClick={() => move("right")}
              icon={<Image src={arrowRight} maxH="36px" />} />
          </Flex>
          <VStack maxH={"20px"}>
            <IconButton
              variant={"unstyled"}
              aria-label='Down'
              onClick={() => move("down")}
              icon={<Image src={arrowDown} maxH="36px" />} />
          </VStack>
        </VStack>
      </CanvasContext.Provider>
    </Box>
  );
}));

export default FroggerCanvas;
