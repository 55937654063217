import { useState, useMemo } from "react";
import {
  Box,
  Flex,
  Image,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

import { GameScore, TeamType } from "../Games/GameStructures";
import { useGameContext } from "./GameContext";

import leaderboardBlankImage from "../../assets/images/games/blankLeaderboard.png";
import leaderboardPullTabImage from "../../assets/images/games/leaderboardPullTab.png";
import leaderboardFeet from "../../assets/images/games/FEET.png";
import leaderboardEyes from "../../assets/images/games/EYES.png";


interface LeaderboardProps {
  score: GameScore;
  onPickTeam: () => void;
  isConnected: boolean;
}

const TeamImage = ({ team }: { team: TeamType }) => {
  return (
    <>
      <Text as="span" className="leaderboardText" fontWeight={"bold"}>
        Your team
      </Text>
      {team && team !== TeamType.none && (
        <Text as="span" className="leaderboardText" fontWeight={"bold"}>
          {team === TeamType.eyes && (
            <Image src={leaderboardEyes} w="25px" ml="3px" mt="3px" />
          )}
          {team === TeamType.feet && (
            <Image src={leaderboardFeet} w="25px" ml="3px" mt="2px" />
          )}
        </Text>
      )}
      {(!team || team === TeamType.none) && (
        <Text as="span" className="leaderboardText" fontWeight={"bold"}>
          &nbsp;?
        </Text>
      )}
    </>
  );
};

const marginOffset = 40;
const variants = {
  open: { opacity: 1, y: `${marginOffset}px`, marginTop: `-${marginOffset}px` },
  closed: { opacity: 1, y: "-100%", zIndex: 100 },
};

const LeaderBoard = ({ score, onPickTeam, isConnected }: LeaderboardProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { team } = useGameContext();
  const toggleOpen = () => {
    setOpen(!open);
  };
  const items = useMemo(() => {
    const scores = [];
    if (score.eyes && score.eyes > score.feet) {
      scores.push({
        name: "eyes",
        score: score.eyes,
      });
      scores.push({
        name: "feet",
        score: score.feet,
      });
    } else {
      scores.push({
        name: "feet",
        score: score.feet,
      });
      scores.push({
        name: "eyes",
        score: score.eyes,
      });
    }
    for (let i = 0; i < 8; i += 1) {
      scores.push({
        name: "",
        score: "",
      });
    }
    return scores;
  }, [score]);
  return (
    <Box
      as={motion.div}
      position={"fixed"}
      zIndex="100"
      right="1%"
      w={"280px"}
      marginTop={`${marginOffset}px`}
      animate={open ? "open" : "closed"} variants={variants}
    >
        <VStack
          rounded={10}
          spacing={0}
          backgroundColor={open ? "rgba(0,0,0,0.75)" : "transparent"}
          cursor="pointer"
        >
          {/* <Image src={leaderboardImage} maxW="280px" opacity={open ? 1 : 0.5} /> */}
          <Box
            maxW="280px"
            width="100%"
            height="320px"
            opacity={open ? 1 : 0.5}
            backgroundImage={leaderboardBlankImage}
            backgroundPosition="center"
            bgSize={"contain"}
            bgRepeat="no-repeat"
          >
            <TableContainer
              mt="35px"
              marginX={"10px"}
              className="leaderboardTable"
            >
              <Table size="sm" variant={"unstyled"}>
                <Thead color={"white"}>
                  <Tr>
                    <Th>rank/name</Th>
                    <Th textAlign="right">score</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.map((item, i) => (
                    <Tr m="0" p="0" key={i}>
                      <Td w="10%" py="0.1em">
                        {String(i + 1).padStart(2, "0")}&nbsp;&nbsp;{item.name}
                      </Td>
                      <Td textAlign={"right"} py="0">
                        {item.score}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
            <Box
              bgColor={"black"}
              w="235px"
              mx="auto"
              height="30px"
              position={"absolute"}
              bottom="65px"
              left="23px"
              pt="1"
            >
              {isConnected && (
                <Flex alignItems={"stretch"}>
                  <TeamImage team={team} />
                  <Spacer />
                  <Text
                    className="leaderboardText"
                    onClick={onPickTeam}
                    title="Switch Team"
                  >
                    {team && team !== TeamType.none && "switch?"}
                    {(!team || team === TeamType.none) && "pick now"}
                  </Text>
                </Flex>
              )}
            </Box>
          </Box>
          <Image
            onClick={toggleOpen}
            src={leaderboardPullTabImage}
            maxW="180px"
            backgroundColor={open ? "transparent" : "rgba(0,0,0,0.75)"}
          />
        </VStack>
    </Box>
  );
};

export default LeaderBoard;
