import React from 'react';
import {
  Select,
  Text,
} from '@chakra-ui/react';

const VariantSelector = (props) => {
  const { option, handleOptionChange } = props;
  return (
    <>
      <Text>{option.name}:</Text>
      <Select
        // placeholder={`Select ${option.name}`}
        className="Product__option"
        name={option.name}
        key={option.name}
        onChange={handleOptionChange}
      >
        {option.values.map((value) => {
          if (value === 'Default Title') {
            return (<option value={value} type="hidden" key={`${option.name}-${value}`}>{`${value}`}</option>);
          }
          return (
            <option value={value} key={`${option.name}-${value}`}>{`${value}`}</option>
          )
        })}
      </Select>
    </>
  );
}

export default VariantSelector;
