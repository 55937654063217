import * as React from "react";
import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Link,
} from "@chakra-ui/react";

import froggerCabinet from "../../assets/images/games/CABINET_JUMPYGUY.png";
import simonCabinet from "../../assets/images/games/CABINET_NORMAN.png";

const ArcadeLandingScreen: React.FC<any> = (props) => {
  const maxWidth = "350px";
  return (
    <Box as="section" w={"100%"} backgroundColor={"black"}>
      <Container
        maxW={{ xl: "1200px" }}
        px={"30px"}
        textColor="white"
        backgroundColor={"black"}
        pb="60px"
      >
        <Center>
          <Heading mt="50px">Arcade</Heading>
        </Center>
        <Center>
          <Heading textAlign="center" size="lg" my="20px">
            What do you want to play today?
          </Heading>
        </Center>
        <Flex
          align="stretch"
          w={"100%"}
          direction={{ base: "column", md: "row" }}
          px={"1rem"}
        >
          <Flex
            justifyContent={"center"}
            alignContent="center"
            width={"100%"}
            direction={{ base: "column", md: "row" }}
            flexWrap="wrap"
          >
            <Link
              href="/arcade/jumpyguy"
              maxW={maxWidth}
              flex="0.5"
              alignContent={"center"}
            >
              <Image
                src={froggerCabinet}
                maxW={maxWidth}
                width="100%"
                flex="0.5"
                alignContent={"center"}
                alt="Jumpy Guy Cabinet"
              />
            </Link>
            <Link
              href="/arcade/norman"
              maxW={maxWidth}
              flex="0.5"
              alignContent={"center"}
            >
              <Image
                src={simonCabinet}
                maxW={maxWidth}
                flex="0.5"
                alignContent={"center"}
                alt="Jumpy Guy Cabinet"
                width="100%"
              />
            </Link>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default ArcadeLandingScreen;
