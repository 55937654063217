import { Box, Button, Center, Image } from "@chakra-ui/react";
import { FC } from "react";
import ButtonBox from "./ButtonBox";

import screenshot from "../../assets/images/games/toedder-screenshot.png";

interface StartProps {
  onStart?: () => void;
}

const StartButton: FC<StartProps> = ({ onStart }) => {
  return (
    <ButtonBox>
      <Box position={"absolute"} top={0} left={0} bottom={0} right={0}>
        <Image src={screenshot} />
        <Box
          position={"absolute"}
          top={0}
          left={0}
          bottom={0}
          right={0}
          backgroundColor={"#00000099"}
        />
      </Box>
      <Center height={"100%"}>
        <Box backgroundColor="#000000ee" py="10" height={"auto"} zIndex={50} width={"100%"} border="1px">
          <Button onClick={onStart} variant={"invertTextButton"}>
            Start
          </Button>
        </Box>
      </Center>
    </ButtonBox>
  );
};

export default StartButton;