import { useState } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Text,
  Link,
  Box,
} from "@chakra-ui/react";
import "./roadmap.css";

const cards = [
  {
    heading: "COLOR WAYS",
    body: (
      <>
        <Text>
          STANDARD COLOR is pretty great, but wouldn’t it be cool if there were
          MORE OPTIONS?{" "}
          {/* </Text>
      <Text mt="5"> */}
          LIMITED EDITION COLOR WAYS are in our future, giving you even more
          choices for your GUYS!
        </Text>
      </>
    ),
  },
  {
    heading: "PAINT SHOPPE",
    body: (
      <Text>
        We are giving you the ability to have your GUY in ANY state you’d like!
        Meaning you can choose to have your GUY in FULL COLOR, or FULL BLACK AND
        WHITE, or a COMBO of both! You can decide you want ONE special trait to
        stay in COLOR with the rest in BLACK AND WHITE one day, and the next
        day, SWITCH IT UP! You can change your GUY as often as you’d like for NO
        gas fees, and it will show up in your wallet exactly as you create it! A
        different COMBO for every mood, and every day!
      </Text>
    ),
  },
  {
    heading: "PAINT SHOPPE ACCESS TOKEN",
    body: (
      <>
        <Text>
          Holders will be able to purchase a PAINT BUCKET ACCESS TOKEN which
          will be their access pass to the PAINT SHOPPE.
        </Text>
        <Text mt="5">
          Own tons of GUYS? We got you. One purchase of a PAINT BUCKET ACCESS
          token will give the holder PAINT SHOPPE access for ALL of the GUYS in
          that wallet (including future tokens).
        </Text>
        <Text mt="5">
          Inside the PAINT SHOPPE you will gain access to STANDARD COLOR for all
          of your GUYS. STANDARD COLOR is a curated COLOR version of every
          trait, designed by our artists, Sam and Frank.
        </Text>
      </>
    ),
  },
  {
    heading: "BACKGROUNDS",
    body: (
      <Text>
        BACKGROUNDS are coming! A STANDARD COLOR set of backgrounds will be the
        first to arrive in the PAINT SHOPPE, so keep an eye out! SPECIAL EDITION
        backgrounds may begin to appear as well…
      </Text>
    ),
  },
  {
    heading: "MERCH",
    body: (
      <Text>
        We have{" "}
        <Link href="/merch" variant={"standard"}>
          merch
        </Link>
        ! And we will have MORE merch! Have an idea? Let us know! We love ideas!
      </Text>
    ),
  },
  {
    heading: "ARCADE",
    body: (
      <Text>
        We all loved playing our{" "}
        <Link href="/arcade" variant={"standard"}>
          ARCADE GAMES
        </Link>{" "}
        to reveal our GUYS but wait, there’s MORE! More games are coming, with
        more levels and more challenges! We’re adding a classic arcade ticket
        system where the more you play, the more tickets you can earn to redeem
        for all sorts of prizes! Coming soon…
      </Text>
    ),
  },
  {
    heading: "MYSTERY BOXES",
    body: <Text>MYSTERY BOXES will remain a MYSTERY… For now…</Text>
  },
  {
    heading: "COLLABS",
    body: (
      <Text>
        Not only will we continue to collab with other amazing projects to bring
        opportunities to our community, but we will take it one step further
        with ARTISTIC COLLABS! Stay tuned…
      </Text>
    ),
  },
  {
    heading: "",
    body: (
      <Text>
        It’s 4:20 somewhere dudes…
      </Text>
    ),
  },
  {
    heading: "",
    body: (
      <Text>
        Stay caffeinated fam!
      </Text>
    ),
  },
  {
    heading: "",
    body: (
      <Text>
        Woof!  Woof!
      </Text>
    ),
  },
  {
    heading: "",
    body: (
      <>
      <Text>
        Hmmmm… what’s this?
      </Text>
      </>
    ),
  },
];

const RoadmapItem = (props: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isCloseFromComponent, setIsCloseFromComponent] =
    useState<boolean>(false);
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
//  const isActiveCard = props.activeCardId === props.cardId;
  const onClick = () => {
    if (!isOpen && !isCloseFromComponent) {
      setIsOpen(true);
    }
    setIsCloseFromComponent(false);
    setIsMouseDown(false);
  };

  const onClose = () => {
    setIsOpen(false);
    if (isMouseDown) {
      setIsCloseFromComponent(true);
    }
  };

  const onMouseDown = () => {
    setIsMouseDown(true);
  };

  const onMouseUp = (e: any) => {
    setIsMouseDown(false);
  };

  const boxShadowStyle = "0 0 0 3px rgba(128, 128, 128, 0.6)";

  return (
    <>
      <Popover
        placement={"bottom"}
        onClose={onClose}
        openDelay={0}
        isOpen={isOpen}
        arrowSize={20}
        arrowShadowColor="#888888"
        strategy="fixed"
      >
        <PopoverTrigger>
          <Box
            // filter={isActiveCard ? "auto" : "none"}
            // brightness={isActiveCard ? "150%" : "none"}
            zIndex="9"
            // className="roadmapItem"
            // backgroundColor={"yellow"}
            // opacity="0.5"
            position={"absolute"}
            cursor={"pointer"}
            {...props}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseUp}
            onMouseOut={onMouseUp}
            onClick={onClick}
          />
        </PopoverTrigger>
        <PopoverContent
          zIndex={"999999999999999"}
          _active={{ boxShadow: boxShadowStyle }}
          _focus={{ boxShadow: boxShadowStyle }}
          _focusVisible={{ boxShadow: "none" }}
          w={["370px", "370px", "600px"]}
          maxW={"95%"}
        >
          {cards[props.cardId].heading ? (
          <PopoverHeader fontWeight="semibold" textAlign={"center"}>
            {cards[props.cardId].heading}
          </PopoverHeader>
          ) : null}
          <PopoverArrow />
          <PopoverCloseButton
            _active={{ boxShadow: boxShadowStyle }}
            _focus={{ boxShadow: boxShadowStyle }}
          />
          <PopoverBody py="20px">{cards[props.cardId].body}</PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default RoadmapItem;
