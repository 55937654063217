import * as React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  // Divider,
  // Flex,
  Heading,
  IconButton,
  Image as ChakraImage,
  Link,
  // Menu,
  // MenuButton,
  // MenuItem,
  // MenuList,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
// import { ChevronDownIcon } from "@chakra-ui/icons";
import { useResizeDetector } from "react-resize-detector";
import { isEmpty } from "@chakra-ui/utils";

import useFEGWallet, { SigningStatus } from "../../hooks/useFEGWallet";
import usePaintshopAPI from "../../hooks/usePaintshopAPI";
import useImagePreloader from "../../hooks/useImagePreload";
import backgroundImage from "../../assets/images/PAINTSHOPPEBG.jpg";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

interface GuyListProps {
  guys: any[];
  paintData: any;
  isLoading: boolean;
  onSelect: (data: any) => void;
}

const GuyList = ({ onSelect, isLoading, guys, paintData }: GuyListProps) => {
  const containerProps = {
    top: { base: "0.1%", md: "2%" },
    left: { base: "0.1%", md: "3.4%" },
    height: { base: "71.95%", md: "67.95%" },
    width: { base: "17.8%", md: "11.7%" },
    backgroundColor: "#c5c5c5",
    padding: { base: "2px", md: "10px" },
    overflow: "scroll",
  };
  const guyContainerProps = {
    w: "100%",
    h: { base: "34.8%", md: "21.8%" },
    mb: { base: "15px", md: "30px" },
    backgroundColor: "white",
    borderRadius: "2px",
    borderColor: "black",
    borderWidth: "2px",
  };
  if (isLoading && (!guys || guys.length === 0)) {
    return (
      <Box position={"absolute"} {...containerProps}>
        <Box {...guyContainerProps} textAlign="center" position={"relative"}>
          <Spinner
            size={"xl"}
            position="absolute"
            top="32%"
            left="30%"
            speed="0.9s"
          />
        </Box>
      </Box>
    );
  }
  return (
    <Box position={"absolute"} {...containerProps}>
      {guys.map((guy, index) => {
        const defaultGuyColors = {
          ...changeAllTraits(GuyColorWays[0], guy),
          background: BackgroundColorWays[0],
        };
        const guyColors = paintData[guy.id]
          ? changeAllTraitsList(guy, paintData[guy.id].traits)
          : defaultGuyColors;

        return (
          <Box
            {...guyContainerProps}
            cursor={"pointer"}
            onClick={() => {
              onSelect({ index });
            }}
            key={guy.id}
          >
            <PaintedGuy guy={guy} guyColors={guyColors} size="128" />
            <Box>
              <Text
                textAlign={"center"}
                fontSize={{ base: "10px", md: "15px" }}
              >
                #{guy.id}
              </Text>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

interface TraitListProps {
  onTraitPress: (data: any) => void;
  onSavePress: (data: any) => void;
  guy: any;
  disabled: boolean;
}

const TraitList = ({
  disabled,
  onTraitPress,
  onSavePress,
  guy,
}: TraitListProps) => {
  return (
    <Box height="100%" width={"100%"} position="relative">
      <VStack
        key={`guy-traits-${guy.id}`}
        height="100%"
        width={"100%"}
        alignItems="flex-start"
        paddingStart="0px"
        justifyContent={"center"}
      >
        {guy.elements.map((element: any, index: number) => {
          if (element.isBlank) {
            return null;
          }
          const label = element.layerType.toUpperCase();
          return (
            <ButtonGroup isAttached width="80%" pb="0">
              <IconButton
                disabled={disabled}
                onClick={() => {
                  onTraitPress({
                    index,
                    action: "previous",
                    layerType: element.layerType,
                  });
                }}
                aria-label={`Previous ${label}`}
                icon={<ChevronLeftIcon />}
              />
              <Button
                disabled={disabled}
                width="100%"
                key={`guy-traits-${guy.id}-${element.layerType}`}
                onClick={() => {
                  onTraitPress({ index, layerType: element.layerType });
                }}
              >
                {element.layerType.toUpperCase()}
              </Button>
              <IconButton
                disabled={disabled}
                onClick={() => {
                  onTraitPress({
                    index,
                    layerType: element.layerType,
                  });
                }}
                aria-label={`Next ${label}`}
                icon={<ChevronRightIcon />}
              />
            </ButtonGroup>
          );
        })}
        <Box height={"0.5em"} />
        {guy.is_reserved ? (
          <Box width="80%" pb="0">
            <Button
              disabled={disabled}
              width="100%"
              key={`guy-traits-${guy.id}-guy`}
              onClick={() => {
                onTraitPress({ all: "next" });
              }}
            >
              {"COLOR ME"}
            </Button>
          </Box>
        ) : null}
        <Box width="80%" pb="0">
          <Button
            disabled={disabled}
            width="100%"
            key={`guy-traits-${guy.id}-random`}
            onClick={() => {
              onTraitPress({ all: "random" });
            }}
          >
            {"I'M FEELING LUCKY!"}
          </Button>
        </Box>
        <Button
          disabled={disabled}
          width="80%"
          key={`guy-traits-${guy.id}-bw-all`}
          onClick={() => {
            onTraitPress({ all: "b/w" });
          }}
        >
          B/W
        </Button>
        <Button
          disabled={disabled}
          width="80%"
          key={`guy-traits-${guy.id}-bw-color`}
          onClick={() => {
            onTraitPress({ all: "colour" });
          }}
        >
          COLOR
        </Button>

        {/* <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            width="80%"
            disabled={disabled}
          >
            ALL TRAITS
          </MenuButton>
          <MenuList>
            <MenuItem
              disabled={disabled}
              onClick={() => {
                onTraitPress({ all: "b/w" });
              }}
            >
              B/W
            </MenuItem>
            <MenuItem
              disabled={disabled}
              onClick={() => {
                onTraitPress({ all: "colour" });
              }}
            >
              COLOR
            </MenuItem>
          </MenuList>
        </Menu> */}
        <Box width="80%" pb="0" height={"0.5em"}></Box>
        <Button
          disabled={disabled}
          width="80%"
          key={`guy-traits-${guy.id}-random`}
          onClick={() => {
            onSavePress(guy);
          }}
          loadingText="Saving"
          isLoading={disabled}
        >
          SAVE
        </Button>
      </VStack>
    </Box>
  );
};

const layerName = (name: string) => {
  if (name === "BACKGROUND") {
    return "BG";
  }
  return name;
};

const MobileTraitList = ({
  disabled,
  onTraitPress,
  onSavePress,
  guy,
}: TraitListProps) => {
  const visibleTraits = guy
    ? guy.elements.filter((el: any) => !el.isBlank).length
    : 0;
  const buttonFontSize = 10;
  return (
    <Box
      height="100%"
      width={"100%"}
      position="relative"
      // backgroundColor="orange"
    >
      <SimpleGrid
        columns={2}
        spacing={1}
        key={`guy-traits-${guy.id}`}
        height="auto"
        width={"100%"}
        alignItems="flex-start"
        paddingStart="0px"
        paddingEnd={"1"}
        marginTop={"10px"}
        justifyContent={"center"}
        alignContent={"center"}
      >
        {guy.elements.map((element: any, index: number) => {
          if (element.isBlank) {
            return null;
          }
          const label = element.layerType.toUpperCase();
          return (
            <ButtonGroup isAttached width="100%" pb="0">
              <IconButton
                disabled={disabled}
                height={"30px"}
                maxW="20px"
                minW="20px"
                // backgroundColor="purple"
                onClick={() => {
                  onTraitPress({
                    index,
                    action: "previous",
                    layerType: element.layerType,
                  });
                }}
                aria-label={`Previous ${label}`}
                icon={<ChevronLeftIcon />}
              />
              <Button
                disabled={disabled}
                fontSize={buttonFontSize}
                width="30px"
                minW={"20px"}
                h={"30px"}
                key={`guy-traits-${guy.id}-${element.layerType}`}
                onClick={() => {
                  onTraitPress({ index, layerType: element.layerType });
                }}
              >
                {layerName(label)}
              </Button>
              <IconButton
                disabled={disabled}
                height={"30px"}
                maxW="20px"
                minW="20px"
                // backgroundColor="purple"

                onClick={() => {
                  onTraitPress({
                    index,
                    layerType: element.layerType,
                  });
                }}
                aria-label={`Next ${label}`}
                icon={<ChevronRightIcon />}
              />
            </ButtonGroup>
          );
        })}
        {guy.is_reserved ? (
          <Box>
            <Button
              disabled={disabled}
              width="100%"
              fontSize={buttonFontSize}
              h={"30px"}
              key={`guy-traits-${guy.id}-next`}
              onClick={() => {
                onTraitPress({ all: "next" });
              }}
            >
              {"ALL"}
            </Button>
          </Box>
        ) : null}
        <Box>
          <Button
            disabled={disabled}
            width="100%"
            fontSize={buttonFontSize}
            h={"30px"}
            key={`guy-traits-${guy.id}-random`}
            onClick={() => {
              onTraitPress({ all: "random" });
            }}
          >
            {"?"}
          </Button>
        </Box>
        {(visibleTraits % 2 === 0 && !guy.is_reserved) || guy.is_reserved ? (
          <Box pb="0" h={"30px"}></Box>
        ) : null}
        <Box mt="3">
          <Button
            disabled={disabled}
            width="100%"
            fontSize={buttonFontSize}
            // p={0}
            // m={0}
            h={"30px"}
            key={`guy-traits-${guy.id}-colour`}
            onClick={() => {
              onTraitPress({ all: "colour" });
            }}
          >
            {"COLOR"}
          </Button>
        </Box>
        <Box mt="3">
          <Button
            disabled={disabled}
            width="100%"
            fontSize={buttonFontSize}
            // p={0}
            // m={0}
            h={"30px"}
            key={`guy-traits-${guy.id}-b-w`}
            onClick={() => {
              onTraitPress({ all: "b/w" });
            }}
          >
            {"B/W"}
          </Button>
        </Box>
        <Box mt="5" pb="0">
          <Button
            disabled={disabled}
            width="100%"
            fontSize={buttonFontSize}
            // p={0}
            // m={0}
            h={"30px"}
            key={`guy-traits-${guy.id}-save`}
            onClick={() => {
              onSavePress(guy);
            }}
            // loadingText="Wait"
            isLoading={disabled}
          >
            SAVE
          </Button>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

interface PaintedGuyProps {
  guy: any;
  guyColors: GuyColorState;
  size: string;
  onClick?: (event: any) => void;
}

const getGuyTraitUrlForColor = (el: any, layerColor: string, size = "128") => {
  let filename = el.filename.toLowerCase();
  if (layerColor && layerColor !== "b/w") {
    filename = el.filename.toLowerCase().replace(".png", `_${layerColor}.png`);
  }
  if (filename.startsWith("rare/")) {
    filename = filename.substring("rare/".length);
  }
  return `/images/guys-min${size ? `-${size}` : ""}/${filename}`;
};

const getGuyBackgroundTraitUrlForColor = (
  el: any,
  layerColor: string,
  size = "128"
) => {
  return `/images/backgrounds/${layerColor}.png`;
};

const getGuyTraitUrl = (el: any, guyColors: GuyColorState, size = "128") => {
  const layerColor = guyColors[el.layerType];
  if (el.layerType === "background") {
    return getGuyBackgroundTraitUrlForColor(el, layerColor, size);
  } else {
    return getGuyTraitUrlForColor(el, layerColor, size);
  }
};

const getGuyTraitImages = (guys: any[]) => {
  const imageFilenameList: string[] = [];
  guys.forEach((guy) => {
    guy.elements.forEach((element: any) => {
      if (element.layerType !== "background") {
        GuyColorWays.forEach((layerColor) => {
          const url = getGuyTraitUrlForColor(element, layerColor);
          if (!imageFilenameList.includes(url)) {
            imageFilenameList.push(url);
          }
          const urlLarge = getGuyTraitUrlForColor(element, layerColor, "");
          if (!imageFilenameList.includes(urlLarge)) {
            imageFilenameList.push(urlLarge);
          }
        });
      }
    });
  });
  return imageFilenameList;
};

const PaintedGuy = ({ guy, guyColors, onClick, size }: PaintedGuyProps) => {
  if (!guy) {
    return <Text>Unable to load GUY</Text>;
  }
  const bgImages = guy.elements.map((el: any, index: number) => {
    if (!size && el.layerType === "background") {
      return `url(/images/guys-min/blank.png)`;
    }
    return `url(${getGuyTraitUrl(el, guyColors, size)})`;
  });
  return (
    <Box
      backgroundImage={bgImages.reverse().join(",")}
      w="100%"
      height="100%"
      backgroundSize={"contain"}
      backgroundPosition={"center"}
      cursor={"pointer"}
      backgroundRepeat={"no-repeat"}
      onClick={onClick ? onClick : () => {}}
    />
  );
};

/**
 * Store a lookup between trait and color
 */
interface GuyColorState {
  [trait: string]: string;
}

const GuyColorWays = ["b/w", "colour"];

const BackgroundColorWays = [
  "sclera",
  "background_zombie_teal",
  "background_wonder_red",
  "background_that_is_yellow",
  "background_socks_blue",
  "background_pants_green",
  "background_guys_grey",
  "background_duck_orange",
  "background_beast_purple",
  "background_ballet_pink",
  "background_plaid",
  "background_tikivermin",
  "background_kimono",
  "background_tattoo",
  "background_90s",
  "background_kinky_red",
];

interface BackgroundIndex {
  [id: number]: string;
}

const ReservedBackgroundColorWays: BackgroundIndex = {
  8084: "background_rainbow_guy",
  990: "background_zombie_guy",
  2468: "background_gold_guy",
  8436: "background_ice_guy",
  4040: "background_leopard_guy",
  3191: "background_mermaid_guy",
  5488: "background_plaid_guy",
  9059: "background_standard_guy",
  4357: "background_tie_dye_guy",
  5713: "background_tiki_guy",
  3809: "background_fire_guy",
  4333: "background_static_guy",
  10293: "background_ringer_guy",
  2053: "background_robot_guy",
};

const nextColor = (currentColor: string) => {
  // console.debug(`nextColor: currentColor: ${currentColor}`);
  const currentIndex = GuyColorWays.indexOf(currentColor);
  if (currentIndex < 0) {
    return GuyColorWays[0];
  }
  return GuyColorWays[(currentIndex + 1) % GuyColorWays.length];
};

const nextBackgroundColor = (currentColor: string, guy: any) => {
  // console.debug(`nextBackgroundColor: currentColor: ${currentColor}`);
  const backgroundOptions = [...BackgroundColorWays];
  if (guy.is_reserved) {
    const id: number = guy.id;
    backgroundOptions.push(ReservedBackgroundColorWays[id]);
  }
  const currentIndex = backgroundOptions.indexOf(currentColor);
  if (currentIndex < 0) {
    return BackgroundColorWays[0];
  }
  return backgroundOptions[(currentIndex + 1) % backgroundOptions.length];
};

const previousColor = (currentColor: string) => {
  // console.debug(`previousColor: currentColor: ${currentColor}`);
  const currentIndex = GuyColorWays.indexOf(currentColor);
  if (currentIndex < 0) {
    return GuyColorWays[0];
  }
  if (currentIndex === 0) {
    return GuyColorWays[GuyColorWays.length - 1];
  }
  return GuyColorWays[(currentIndex - 1) % GuyColorWays.length];
};

const previousBackgroundColor = (currentColor: string, guy: any) => {
  // console.debug(`previousBackgroundColor: currentColor: ${currentColor}`);
  const backgroundOptions = [...BackgroundColorWays];
  if (guy.is_reserved) {
    const id: number = guy.id;
    backgroundOptions.push(ReservedBackgroundColorWays[id]);
  }
  const currentIndex = backgroundOptions.indexOf(currentColor);
  if (currentIndex < 0) {
    return BackgroundColorWays[0];
  }
  if (currentIndex === 0) {
    return backgroundOptions[backgroundOptions.length - 1];
  }
  return backgroundOptions[(currentIndex - 1) % backgroundOptions.length];
};

/**
 * Change all traits of a guy to a given color name
 * Does not include background color
 */
const changeAllTraits = (color: string, guy: any) => {
  const colorIndex = GuyColorWays.indexOf(color);
  const traits: GuyColorState = guy.elements.reduce((acc: any, cur: any) => {
    if (cur.layerType !== "background") {
      acc[cur.layerType] = GuyColorWays[colorIndex];
    } else {
      acc[cur.layerType] = BackgroundColorWays[0];
    }
    return acc;
  }, {} as GuyColorState);
  return traits;
};

/**
 * Change all traits of a guy to a random color from the given list of possible values
 * Does not include background color
 */
const changeAllTraitsRandom = (colors: string[], guy: any) => {
  const traits: GuyColorState = guy.elements.reduce((acc: any, cur: any) => {
    if (cur.layerType === "background") {
      const backgroundOptions = [...BackgroundColorWays];
      if (guy.is_reserved) {
        const id: number = guy.id;
        backgroundOptions.push(ReservedBackgroundColorWays[id]);
      }
      const colorIndex = Math.floor(Math.random() * backgroundOptions.length);
      if (colorIndex === -1) {
        console.error(`cannot match color index for background:${colorIndex}`);
      } else {
        acc[cur.layerType] = backgroundOptions[colorIndex];
      }
    } else {
      const randomColor = Math.floor(Math.random() * colors.length);
      const colorIndex = GuyColorWays.indexOf(colors[randomColor]);
      if (colorIndex === -1) {
        console.error(
          `cannot match color index for layer:${cur.layerType}:${colorIndex}`
        );
      } else {
        acc[cur.layerType] = GuyColorWays[colorIndex];
      }
    }
    return acc;
  }, {} as GuyColorState);
  return traits;
};

/**
 * Change all traits of a guy to a given color name
 */
const changeAllTraitsList = (guy: any, layerColors: any[] | null) => {
  const traits: GuyColorState = guy.elements.reduce((acc: any, cur: any) => {
    let colorWay;
    if (layerColors) {
      colorWay = layerColors.find((val: any) => val.name === cur.layerType);
    }
    if (colorWay) {
      acc[cur.layerType] = colorWay.color;
    } else {
      if (cur.layerType === "background") {
        acc[cur.layerType] = BackgroundColorWays[0];
      } else {
        acc[cur.layerType] = GuyColorWays[0];
      }
    }
    return acc;
  }, {} as GuyColorState);
  return traits;
};

const backgroundCanvasUrl = (color: string) => {
  const trimmedColor = color.replace("background_", "");
  return `/images/backgrounds/background_canvas_${trimmedColor}.png`;
};

//1.294921875 - h to w
// const sizeRatio = 663 / 512;
// const dim = 250;
// const width = `${dim}px`;
// const height = `${dim * sizeRatio}px`;
// const widthRaw = dim;
// const heightRaw = dim * sizeRatio;

const WalletAddress = ({ address }: { address: undefined | string }) => {
  if (!address) {
    return null;
  }
  return (
    <Text
      wordBreak="break-word"
      whiteSpace={"normal"}
      mb={"0"}
      fontSize={["12px", "md"]}
    >
      You're connected using wallet address: {address}
    </Text>
  );
};

const defaultColors = {
  hats: "b/w",
  sides: "b/w",
  feet: "b/w",
  neck: "b/w",
  eyes: "b/w",
  reserved: "b/w",
  background: "sclera",
};

const PaintShoppe: React.FC<any> = (props) => {
  const {
    // account,
    // chainId,
    connectWallet,
    // contractState,
    disconnect,
    doSignRequest,
    isConnected,
    isLoggingOut,
    isSigning,
    isVerifyingSession,
    // queryingContractStatus,
    queryGuyBalance,
    queryingGuyBalance,
    // refreshState,
    // signer,
    signerAddress,
    shoppeTokenContractState,
    signingState,
    // txSubmitting,
    verifySession,
  } = useFEGWallet(false);
  const {
    loadGuys,
    isLoadingGuys,
    saveColourChange,
    isSaving,
    guyData,
    paintData,
  } = usePaintshopAPI();
  const { imagesPreloaded, preloadImages } = useImagePreloader();
  const [selectedGuyIndex, setSelectedGuyIndex] = useState<number>(-1);
  const [canvases, setCanvases] = useState<any[]>([]);
  const [guyColors, setGuyColors] = useState<GuyColorState>({});
  const [eventState, updateState] = React.useState<any>();
  const forceUpdate = useCallback((event) => updateState(event), []);
  const handleOnSelect = (data: any) => {
    setSelectedGuyIndex(data.index);
    const guy = guyData[data.index];
    if (paintData[guy.id]) {
      console.debug("there is paint data for default guy [0]", guy.id);
      setGuyColors(changeAllTraitsList(guy, paintData[guy.id].traits));
    } else {
      setGuyColors({
        ...changeAllTraits(GuyColorWays[0], guy),
        background: BackgroundColorWays[0],
      });
    }
  };
  // const [currentColorMeColor, setCurrentColorMeColor] = useState<string>(GuyColorWays[0]);
  // const onResize = useCallback(() => {
  //   console.debug(`on resize: ${guyWidth}, ${guyHeight}`);
  // }, []);
  const {
    width: guyWidth,
    height: guyHeight,
    ref: guyRef,
  } = useResizeDetector({
    handleHeight: false,
    refreshMode: "debounce",
    refreshRate: 1000,
    //    onResize,
  });
  useEffect(() => {
    if (!guyData || isEmpty(guyData)) {
      return;
    }
    if (
      shoppeTokenContractState.balanceOf < 1 ||
      shoppeTokenContractState.balanceOfGuys < 1
    ) {
      console.debug("user does not have guys or access token");
      return;
    }
    if (selectedGuyIndex > -1) {
      return;
    }
    const defaultGuyIndex = 0;
    setSelectedGuyIndex(-2);
    setTimeout(() => {
      const defaultGuy = guyData[defaultGuyIndex];
      if (paintData[defaultGuy.id]) {
        console.debug("there is paint data for default guy [0]", defaultGuy.id);
        setGuyColors(
          changeAllTraitsList(defaultGuy, paintData[defaultGuy.id].traits)
        );
      } else {
        // console.debug('there is no paint data for default guy [0]', JSON.stringify({paintData, defaultGuy}));
        setGuyColors({
          ...changeAllTraits(GuyColorWays[0], defaultGuy),
          background: BackgroundColorWays[0],
        });
      }
      setSelectedGuyIndex(0);
    }, 1000);
  }, [
    guyData,
    paintData,
    selectedGuyIndex,
    shoppeTokenContractState.balanceOf,
    shoppeTokenContractState.balanceOfGuys,
  ]);

  useEffect(() => {
    if (!guyData || isEmpty(guyData)) {
      return;
    }
    const imageList = getGuyTraitImages(guyData);
    // console.debug("preloading images...", imageList.length, imageList);
    preloadImages(imageList);
  }, [preloadImages, guyData]);

  // useEffect(() => {
  //   if (!isConnected) {
  //     console.debug("no connected, not making sign request");
  //     return;
  //   }
  //   if (
  //     signingState !== SigningStatus.notSigned &&
  //     signingState !== SigningStatus.errorSigning
  //   ) {
  //     console.debug(
  //       "signing state either signed or unknown. not making signin request"
  //     );
  //     return;
  //   }
  //   if (
  //     shoppeTokenContractState.balanceOf < 1 ||
  //     shoppeTokenContractState.balanceOfGuys < 1
  //   ) {
  //     console.debug("user does not have guys or access token");
  //     return;
  //   }
  //   doSignRequest().then(() => {
  //     console.debug("completed doSignRequest");
  //   });
  // }, [
  //   doSignRequest,
  //   isConnected,
  //   signingState,
  //   shoppeTokenContractState.balanceOf,
  //   shoppeTokenContractState.balanceOfGuys,
  // ]);

  const handleSignRequest = useCallback(() => {
    doSignRequest()
      .then((res) => {
        console.debug("res from sign request then block", res);
        if (res && res.code) {
          const { code } = res;
          if (code === 4001) {
            // siging error
          }
        }
      })
      .catch((e) => {
        console.error("res from sign request then block", e);
      });
  }, [doSignRequest]);

  useEffect(() => {
    if (!isConnected) {
      console.debug("no connected, not making login check");
      return;
    }
    if (!signerAddress) {
      console.debug("no signer address");
      return;
    }
    if (signingState !== SigningStatus.unknown) {
      console.debug("signing state is not unknown. not making login check");
      return;
    }
    verifySession();
  }, [verifySession, isConnected, signingState, signerAddress]);

  // console.debug(`isConnected: ${isConnected}`);

  useEffect(() => {
    if (
      !isConnected ||
      !signerAddress ||
      signingState !== SigningStatus.signed
    ) {
      console.debug("not connected or not signed.  not loading guys yet");
      return;
    }
    if (
      shoppeTokenContractState.balanceOf < 1 ||
      shoppeTokenContractState.balanceOfGuys < 1
    ) {
      console.debug("user does not have guys or access token");
      return;
    }
    // console.log("load guys...", signerAddress);
    loadGuys(signerAddress);
  }, [
    loadGuys,
    signingState,
    isConnected,
    signerAddress,
    shoppeTokenContractState.balanceOf,
    shoppeTokenContractState.balanceOfGuys,
  ]);

  useEffect(() => {
    if (queryingGuyBalance) {
      console.debug("already querying balance.  not checking balance guys yet");
      return;
    }
    if (!isConnected || !signerAddress) {
      console.debug(
        "not connected or not signed.  not checking balance guys yet"
      );
      return;
    }
    if (
      shoppeTokenContractState.balanceOf > -1 ||
      shoppeTokenContractState.balanceOfGuys > -1
    ) {
      console.debug("already have balance details");
      return;
    }
    console.log("queryGuyBalance...", signerAddress);
    queryGuyBalance(signerAddress);
  }, [
    queryGuyBalance,
    queryingGuyBalance,
    isConnected,
    signerAddress,
    shoppeTokenContractState.balanceOf,
    shoppeTokenContractState.balanceOfGuys,
  ]);

  useEffect(() => {
    if (!guyWidth || !guyHeight) {
      console.debug("no guy dimensions.  not rendering");
      return;
    }
    if (!guyData || isEmpty(guyData)) {
      console.debug("no guy data.  not rendering");
      return;
    }

    if (selectedGuyIndex < 0) {
      console.debug("no selected guy.  not rendering");
      return;
    }
    // we don't care about colors for point detection

    // console.debug(
    //   "rendering canvases:",
    //   guyWidth,
    //   guyHeight,
    //   selectedGuyIndex,
    //   defaultColors
    // );
    const canvasItems = [];
    for (let i = 0; i < guyData[selectedGuyIndex].elements.length; i += 1) {
      const canvas = document.createElement("canvas");
      canvas.width = guyWidth;
      canvas.height = guyHeight;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        const img = new Image(guyWidth, guyHeight);
        img.onload = () => {
          img.width = guyWidth;
          img.height = guyHeight;
          ctx.drawImage(img, 0, 0, guyWidth, guyHeight);
        };
        const url = getGuyTraitUrl(
          guyData[selectedGuyIndex].elements[i],
          defaultColors
        );
        img.src = url;
      }
      // console.debug(`loading for ${guyData[selectedGuyIndex].elements[i].layerType}`);
      canvasItems.push(canvas);
    }
    // console.debug(canvasItems);
    setCanvases(canvasItems);
  }, [selectedGuyIndex, guyWidth, guyHeight, guyData]);

  const handleOnTraitPress = useCallback(
    (data: any) => {
      const currentGuy = guyData[selectedGuyIndex];
      if (data.all) {
        // console.debug(`data all:${data.all}`);
        switch (data.all) {
          case "b/w": {
            setGuyColors(changeAllTraits(GuyColorWays[0], currentGuy));
            return;
          }
          case "colour": {
            setGuyColors(changeAllTraits(GuyColorWays[1], currentGuy));
            return;
          }
          case "random": {
            setGuyColors(changeAllTraitsRandom(GuyColorWays, currentGuy));
            return;
          }
          case "next": {
            setGuyColors(
              changeAllTraits(nextColor(guyColors["reserved"]), currentGuy)
            );
            return;
          }
          default: {
            console.debug(`unhandled all change: ${data.all}`);
            return;
          }
        }
      }
      let nextLayerColor: string;
      if (data.action === "previous") {
        nextLayerColor =
          data.layerType === "background"
            ? previousBackgroundColor(guyColors[data.layerType], currentGuy)
            : previousColor(guyColors[data.layerType]);
      } else {
        nextLayerColor =
          data.layerType === "background"
            ? nextBackgroundColor(guyColors[data.layerType], currentGuy)
            : nextColor(guyColors[data.layerType]);
      }

      // console.debug(
      //   `selected layer index: ${data.index}, layer type: ${data.layerType}, nextLayerColor: ${nextLayerColor}`
      // );
      setGuyColors((current) => {
        return {
          ...current,
          [data.layerType]: nextLayerColor,
        };
      });
    },
    [guyColors, setGuyColors, guyData, selectedGuyIndex]
  );

  const handleOnCanvasPress = useCallback(
    (event: React.MouseEvent<HTMLCanvasElement>) => {
      // console.debug("onregiontest press");
      // const ctx = canvasRef?.current?.getContext("2d");
      let found = false;
      // console.debug(`canvases length: ${canvases.length}`);
      if (canvases.length === 0) {
        console.log("canvases are empty.  forcing update");
        forceUpdate(event);
        return;
      }
      for (let i = canvases.length - 1; i >= 0 && !found; i -= 1) {
        // console.debug(canvases[i]);
        const ctx = canvases[i].getContext("2d");
        if (ctx) {
          let bounds = event.currentTarget.getBoundingClientRect();
          const x = event.clientX - bounds.left;
          const y = event.clientY - bounds.top;
          // console.debug('canvas ctx is', ctx, event.clientX, event.clientY, x, y, bounds);
          const pixelData = ctx.getImageData(x, y, 1, 1).data;
          // console.debug(pixelData);
          for (let p = 0, n = pixelData.length; p < n && !found; p += 4) {
            const val = pixelData[p + 3];
            if (val > 0) {
              // console.debug(pixelData[p + 3]);
              // console.debug(
              //   `found hit for ${guyData[selectedGuyIndex].elements[i].layerType} (${i})`
              // );
              handleOnTraitPress({
                layerType: guyData[selectedGuyIndex].elements[i].layerType,
              });
              found = true;
            }
          }
        }
      }
      if (!found) {
        handleOnTraitPress({
          layerType: "background",
        });
      }
    },
    [canvases, forceUpdate, handleOnTraitPress, guyData, selectedGuyIndex]
  );

  useEffect(() => {
    if (eventState) {
      console.debug("updating event");
      updateState(null);
      handleOnCanvasPress(eventState);
    }
  }, [eventState, handleOnCanvasPress]);

  const handleOnSavePress = (guy: any) => {
    if (!signerAddress) {
      alert("You need to be signed in to save your changes");
      return;
    }
    if (guy) {
      // console.debug("save guycolors are:", guyColors);
      const traits = Object.keys(guyColors).map((key) => ({
        name: key,
        color: guyColors[key],
      }));
      saveColourChange(signerAddress, guy.id, traits);
    }
  };

  let selectedBackground = null;
  if (guyColors["background"]) {
    selectedBackground = backgroundCanvasUrl(guyColors["background"]);
  }

  // console.debug(`guy detected ${guyWidth}, ${guyHeight}.  `);

  const canUsePaintShop =
    shoppeTokenContractState.balanceOf > 0 &&
    shoppeTokenContractState.balanceOfGuys > 0;

  return (
    <Container maxW={{ xl: "1200px" }} px={0}>
      {/* <Box
        margin="auto"
        alignItems={"center"}
        justifyContent={"space-around"}
        display={{ base: "block", md: "none" }}
      >
        <Box w="100%" textAlign={"center"} p="20px" mb="200px">
          <Heading>Paint Shoppe</Heading>
          <Text>
            Please visit the Paint Shoppe on desktop (for now). Check back soon
            for mobile!
          </Text>
        </Box>
      </Box> */}
      <Box
        margin="auto"
        maxW={"96%"}
        alignItems={"center"}
        justifyContent={"space-around"}
        //        display={{ base: "none", md: "block" }}
      >
        {isLoggingOut ? (
          <Box w="100%" textAlign={"center"}>
            <Heading>Paint Shoppe</Heading>
            <Spinner />
            <Text>Cleaning up</Text>
          </Box>
        ) : null}
        {!isConnected && !isLoggingOut ? (
          <Box textAlign={"center"}>
            <Heading>Paint Shoppe</Heading>
            <Text>Use your Paint Shoppe Access Token to paint your GUYS!</Text>
            <Text mt="5">
              Make sure that you are using a wallet that has at least one
              FeetAndEyesGuys NFT and also a Paint Shoppe Access Token.
            </Text>
            <Button
              mt="20"
              onClick={connectWallet}
              wordBreak="break-word"
              whiteSpace={"normal"}
            >
              CONNECT WALLET TO BEGIN
            </Button>
          </Box>
        ) : null}

        {isVerifyingSession ? (
          <Box w="100%" textAlign={"center"}>
            <Heading>Paint Shoppe</Heading>
            <Spinner />
            <Text>Checking status</Text>
          </Box>
        ) : null}
        {isSigning ? (
          <Box w="100%" textAlign={"center"}>
            <Heading>Paint Shoppe</Heading>
            <Spinner />
            <Text>Creating signing request</Text>
          </Box>
        ) : null}
        {!isVerifyingSession &&
        (shoppeTokenContractState.balanceOf === 0 ||
          shoppeTokenContractState.balanceOfGuys === 0) ? (
          <Box textAlign={"center"}>
            <Heading>Paint Shoppe</Heading>
            {shoppeTokenContractState.balanceOfGuys === 0 ? (
              <Text>
                Your wallet does not have any Feet And Eyes Guys NFTs.
              </Text>
            ) : null}
            {shoppeTokenContractState.balanceOf === 0 ? (
              <Text>
                Your wallet does not have a Paint Shoppe Access Token.
              </Text>
            ) : null}
            <Text mt="5">
              Make sure that you are using a wallet that has at least one Feet
              And Eyes Guys NFT and also a Paint Shoppe Access Token.
            </Text>
            <WalletAddress address={signerAddress} />
            <Button
              mt="5"
              onClick={disconnect}
              wordBreak="break-word"
              whiteSpace={"normal"}
            >
              DISCONNECT WALLET
            </Button>
          </Box>
        ) : null}
        {isConnected &&
        canUsePaintShop &&
        !isVerifyingSession &&
        !isSigning &&
        signingState !== SigningStatus.signed &&
        signingState !== SigningStatus.unknown ? (
          <Box textAlign={"center"}>
            <Heading>Paint Shoppe</Heading>
            <Button
              mt="10"
              onClick={handleSignRequest}
              wordBreak="break-word"
              whiteSpace={"normal"}
            >
              AUTHENTICATE WALLET
            </Button>
            <Text mt="5" mb="10">
              Make sure that you are using a wallet that has at least one Feet
              And Eyes Guys NFT and also a Paint Shoppe Access Token.
            </Text>
            <WalletAddress address={signerAddress} />
            <Button
              mt="5"
              onClick={disconnect}
              wordBreak="break-word"
              whiteSpace={"normal"}
            >
              DISCONNECT WALLET
            </Button>
          </Box>
        ) : null}
        {isConnected &&
        !isVerifyingSession &&
        !isSigning &&
        signingState === SigningStatus.unknown ? (
          <Box textAlign={"center"}>
            <Heading>Paint Shoppe</Heading>
            <Box w="100%" textAlign={"center"}>
              <Spinner />
              <Text>Please wait. Initlializing</Text>
            </Box>
          </Box>
        ) : null}

        {isConnected &&
        signingState === SigningStatus.signed &&
        imagesPreloaded &&
        canUsePaintShop ? (
          <Box w="100%" h="100%" position="relative">
            <ChakraImage src={backgroundImage} />
            {selectedBackground ? (
              <ChakraImage
                src={selectedBackground}
                position="absolute"
                top="0"
                left="0"
                w={"100%"}
                height="100%"
              />
            ) : null}
            <GuyList
              onSelect={handleOnSelect}
              guys={guyData}
              isLoading={isLoadingGuys}
              paintData={paintData}
            />
            <Box
              ref={guyRef}
              position={"absolute"}
              top={"21.7%"}
              left={"23.9%"}
              height="60.4%"
              width={"35%"}
            >
              <Box
                width={"100%"}
                height={"100%"}
                position="relative"
                // top="20%"
                // left="27%"
              >
                {selectedGuyIndex > -1 && (
                  <PaintedGuy
                    size=""
                    guy={guyData[selectedGuyIndex]}
                    guyColors={guyColors}
                    onClick={handleOnCanvasPress}
                  />
                )}
              </Box>
            </Box>
            {selectedGuyIndex > -1 && (
              <>
                <Box
                  position={"absolute"}
                  top={"0"}
                  left={"76.2%"}
                  height="100%"
                  width={"24%"}
                  minWidth="225px"
                  minHeight="380px"
                  display={{ base: "none", md: "block" }}
                >
                  <TraitList
                    onTraitPress={handleOnTraitPress}
                    onSavePress={handleOnSavePress}
                    guy={guyData[selectedGuyIndex]}
                    disabled={isSaving}
                  />
                </Box>
                <Box
                  position={"absolute"}
                  top={"0%"}
                  right={"0%"}
                  height="100%"
                  // width={"24%"}
                  // minWidth="225px"
                  // minHeight="380px"
                  display={{ base: "block", md: "none" }}
                >
                  <MobileTraitList
                    onTraitPress={handleOnTraitPress}
                    onSavePress={handleOnSavePress}
                    guy={guyData[selectedGuyIndex]}
                    disabled={isSaving}
                  />
                </Box>
              </>
            )}
          </Box>
        ) : null}
        {isConnected && !imagesPreloaded && isLoadingGuys ? (
          <Box width={"100%"} height={"100%"} textAlign="center">
            <Heading>Paint Shoppe</Heading>
            <Text>Loading, please wait.</Text>
            <Spinner />
          </Box>
        ) : null}
        {isConnected && signingState === SigningStatus.signed ? (
          <Box textAlign={"center"}>
            {shoppeTokenContractState.balanceOf < 1 && !queryingGuyBalance ? (
              <Box mb="10">
                <Text>
                  The connected wallet does not have a Paint Shoppe Access
                  Token.
                </Text>
                <Text>
                  <Link href="/paintshoppe/buy" variant={"standard"}>
                    Mint a Paint Shoppe Access Token
                  </Link>
                </Text>
              </Box>
            ) : null}
            {shoppeTokenContractState.balanceOfGuys < 1 &&
            shoppeTokenContractState.balanceOf > 0 &&
            !queryingGuyBalance ? (
              <Box mb="10">
                <Text>
                  The connected wallet does not have any Feet And Eyes Guys
                  Tokens - To be able to color your GUYs, you will need to
                  transfer them to this wallet.
                </Text>
              </Box>
            ) : null}
            {queryingGuyBalance ? (
              <Box width={"100%"} height={"100%"} textAlign="center">
                <Heading>Paint Shoppe</Heading>
                <Text>Checking tokens, please wait.</Text>
                <Spinner />
              </Box>
            ) : null}
            <WalletAddress address={signerAddress} />
            <Button
              onClick={disconnect}
              wordBreak="break-word"
              whiteSpace={"normal"}
            >
              DISCONNECT WALLET
            </Button>
          </Box>
        ) : null}
      </Box>
      {/* <Divider mt="200px" />
      <Text>Just debug data below. This will not be on the live site</Text>
      <Text>[debug] signer address is: {signerAddress}</Text>
      <Text>[debug] chainId is: {chainId}</Text>
      <Text>
        [debug] shoppeTokenContractState:{" "}
        {shoppeTokenContractState
          ? JSON.stringify(shoppeTokenContractState, null, 2)
          : "unknown"}
      </Text> */}
    </Container>
  );
};

export default PaintShoppe;
