import * as React from "react";

import ReactPlayer from "react-player";

interface Props {
  url: string;
  size?: string;
}

function VideoPlayer({url, size = "100%"} : Props) {
  return (
      <ReactPlayer
        playing
        playsinline={true}
        width={size}
        height={size}
        url={[url]}
        controls
        config={{ file: { attributes: { disablepictureinpicture: 'true' }}}}
      />
  );
}

export default VideoPlayer;
