import * as React from "react";
import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import ReactGA from "react-ga4";

import VideoModal from "./VideoModal";
import VideoPlayer from "./VideoPlayer";

import guy from "../assets/images/guy.png";

const Player = (
  <VideoPlayer url="/videos/feet_eye_guy-basic-crop-1920-small.mp4" />
);

const trackNav = () => {
  ReactGA.event('page_event', {
    category: "interaction event",
    action: "click",
    label: "aboutVideo",
  });
};

const About: React.FC<any> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Container maxW={{ xl: "1200px" }} px={0} as="section">
        <Flex
          align="stretch"
          w={"100%"}
          direction={{ base: "column-reverse", md: "row" }}
          px={"1rem"}
        >
          <Flex
            width={"100%"}
            p={5}
            shadow="md"
            borderWidth="1px"
            borderRadius="40px"
            backgroundColor={"black"}
            justifyContent={"center"}
          >
            <Center
              flexDirection="column"
              onClick={() => {
                onOpen();
                trackNav();
              }}
              cursor="pointer"
            >
              <Image src={guy} maxW={340} alignContent={"center"} alt="NFT Guy" />
              <Heading textColor="white">
                <motion.span
                  animate={{ color: ["#ffffff", "#333333"] }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    repeatType: "reverse",
                    ease: "easeOut",
                  }}
                >
                  Press Me
                </motion.span>
              </Heading>
            </Center>
          </Flex>
          <Box p={5} width={"100%"}>
            <Heading textAlign={"center"} mb={8}>
              Who are these guys?
            </Heading>
            <VStack spacing={4} textAlign={"center"}>
              <Text>From the mind of <Link
                    href="https://twitter.com/caitcronenberg"
                    target="_blank"
                    variant="standard"
                    rel="noopener"
                  >Caitlin Cronenberg</Link>…</Text>
              <Text>They’ve got feet. They’ve got eyes.</Text>
              <Text>They are generative cartoon guys!</Text>
              <Text>
                Each Feet and Eyes Guy is a completely unique combination of
                over 200 hand-drawn attributes.
              </Text>
              <Text>
                The original Feet and Eyes Guy was born on a school notebook
                page in 1989. Nothing but pencil on paper.
              </Text>
              <Text>
                This crowd lives on the Ethereum blockchain as ERC-721 tokens.
              </Text>
            </VStack>
          </Box>
        </Flex>
      </Container>
      <VideoModal isOpen={isOpen} onClose={onClose} BodyComponent={Player} />
    </>
  );
};

export default About;
