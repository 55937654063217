import ky, { HTTPError } from "ky";
import { useCallback, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useToast } from "@chakra-ui/react";

import { contractAddress } from "../contract/contract";
import appConfig from "../lib/appConfig";

export default function usePaintshopAPI() {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoadingGuys, setIsLoadingGuys] = useState<boolean>(false);
  const [guyData, setGuyData] = useState<any[]>([]);
  const [paintData, setPaintData] = useState<any>({});
  const { executeRecaptcha } = useGoogleReCaptcha();
  const toast = useToast();

  const saveColourChange = async (
    walletAddress: string,
    id: number,
    traits: any[]
  ) => {
    try {
      // console.debug("save colour change", { walletAddress, id, traits });
      if (!executeRecaptcha) {
        console.error("Execute recaptcha not yet available");
        return false;
      }
      setIsSaving(true);
      const res: any = await ky
        .post(`${appConfig.functions.formBaseUrl}/paint/guy`, {
          timeout: 60 * 1000,
          json: {
            id,
            wallet_address: walletAddress,
            token: await executeRecaptcha(`savecolorchange`),
            traits,
          },
          credentials: "same-origin",
        })
        .json();
      // console.debug(`saveColorChange res`, res);
      console.debug(`res.status: ${res.status}`);
      try {
        const metaRes: any = await ky
        .get(
          `https://api.opensea.io/api/v1/asset/${contractAddress}/${id}/?force_update=true`
          )
          .json();
          console.debug("refresh res", metaRes);
      } catch (e) {
        console.error("error from meta request", e);
      }
      toast({
        title: 'Successfully saved your GUY',
        description: "We have requested a refresh of the metadata from OpenSea. If you want to see the updates quickly, then use the refresh metadata button on OpenSea.",
        status: 'success',
        containerStyle: {
          border: '2px solid black',
          borderRadius: '0px',
        },
        variant: "standard",
        position: "top",
        duration: 6000,
        isClosable: true,
      })
      // alert(
      //   "Successfully saved your GUY\n\nWe have requested a refresh of the metadata from OpenSea.\n\nIf you want to see the updates quickly, then use the refresh metadata button on OpenSea."
      // );
      return loadGuys(walletAddress);
    } catch (e) {
      if (e instanceof HTTPError) {
        try {
          const res = await e.response.json();
          // status: 'no', code: 620, relogin: true
          console.error("saveColorChange error", res);
          let code = "001";
          if (res.code) {
            code = res.code;
          }
          if (res.relogin) {
            alert(
              `There was an error saving the color updates (e:2) - ${code}.\n\nPlease press the "Disconnect Wallet" button and log back in.\n\nIf the issue persists, please grab a screenshot (and, if you can, grab a screenshot of your browser's console too) and send a DM to @FeetAndEyesGuys`
            );
          } else {
            alert(
              `There was an error saving the color updates (e:2) - ${code}.  Please grab a screenshot (and, if you can, grab a screenshot of your browser's console too) and send a DM to @FeetAndEyesGuys`
            );
          }
          return {
            success: false,
            code,
            ...res,
          };
        } catch (e) {
          console.error("unable to parse response.  maybe a 404?");
          alert(
            "There was an error saving the color updates (e:3).  Please grab a screenshot (and, if you can, grab a screenshot of your browser's console too) and send a DM to @FeetAndEyesGuys"
          );
        }
      } else {
        let unknownError: any = e;
        console.error("error with saveColorChange");
        console.error({
          errorInstance: typeof e,
          errorCode: unknownError.code,
          errorMesage: unknownError.message,
        });
        // Possibly a CORS issue - make sure to be on correct domain
        console.error(e);
        alert(
          `There was an error saving the color updates (e:1) - ${unknownError.code}.  Please grab a screenshot (and, if you can, grab a screenshot of your browser's console too) and send a DM to @FeetAndEyesGuys`
        );
      }
      return {
        success: false,
        error: e,
      };
    } finally {
      setIsSaving(false);
    }
  };

  const loadGuys = useCallback(
    async (walletAddress: string) => {
      try {
        // console.debug("load guys for wallet", { walletAddress });
        if (!executeRecaptcha) {
          console.error("Execute recaptcha not yet available");
          return false;
        }
        setIsLoadingGuys(true);
        const res: any = await ky
          .post(`${appConfig.functions.formBaseUrl}/paint/my_guys`, {
            timeout: 60 * 1000,
            json: {
              wallet_address: walletAddress,
              token: await executeRecaptcha(`loadGuys`),
            },
            credentials: "same-origin",
          })
          .json();
        // console.debug(`loadGuys res`, res);
        console.debug(`res.status: ${res.status}`);
        if (res.status !== "yes") {
          throw new Error("Unable to load guys");
        }
        setGuyData(res.guys);
        setPaintData(res.paintData);
        return res;
      } catch (e) {
        if (e instanceof HTTPError) {
          try {
            const res = await e.response.json();
            console.error("loadGuys error", res);
            let code = "001";
            if (res.code) {
              code = res.code;
            }
            alert(
              `There was an error loading your guys (e:2)-${code}.  Please grab a screenshot (and, if you can, grab a screenshot of your browser's console too) and send a DM to @FeetAndEyesGuys`
            );
            // return {
            //   success: true,
            //   code,
            //   ...res,
            // };
            setIsLoadingGuys(false);
          } catch (e) {
            console.error(
              "error loadGuys unable to parse response.  maybe a 404?"
            );
            alert(
              "There was an error loading your guys (e:3).  Please grab a screenshot (and, if you can, grab a screenshot of your browser's console too) and send a DM to @FeetAndEyesGuys"
            );
          }
        } else {
          let unknownError: any = e;
          console.error("error with loadGuys");
          console.error({
            errorInstance: typeof e,
            errorCode: unknownError.code,
            errorMesage: unknownError.message,
          });
          console.error(e);
          alert(
            `There was an error loading your guys (e:2)-${
              unknownError.code ? unknownError.code : "?"
            }.  Please grab a screenshot (and, if you can, grab a screenshot of your browser's console too) and send a DM to @FeetAndEyesGuys`
          );
        }
        return {
          success: false,
          error: e,
        };
      } finally {
        setIsLoadingGuys(false);
      }
    },
    [executeRecaptcha]
  );

  return {
    isSaving,
    saveColourChange,
    isLoadingGuys,
    loadGuys,
    guyData,
    paintData,
  };
}
