import * as React from "react"
import {
  useColorMode,
  useColorModeValue,
  IconButton,
  IconButtonProps,
} from "@chakra-ui/react"
import { FaMoon, FaSun } from "react-icons/fa"
import ReactGA from 'react-ga4';

type ColorModeSwitcherProps = Omit<IconButtonProps, "aria-label">

const trackNav = (name: string) => {
  ReactGA.event('page_event', {
    category: "navigation event",
    action: "click",
    label: name,
  });
}

const ColorModeSwitcher: React.FC<ColorModeSwitcherProps> = (props) => {
  const { toggleColorMode } = useColorMode()
  const text = useColorModeValue("dark", "light")
  const SwitchIcon = useColorModeValue(FaMoon, FaSun)

  return (
    <IconButton
      size="md"
      fontSize="lg"
      variant="unstyled"
      color="current"
      marginLeft="2"
      pl="3"
      onClick={() => {
        toggleColorMode();
        trackNav(`colorMode:${text}`);
      }}
      icon={<SwitchIcon />}
      aria-label={`Switch to ${text} mode`}
      {...props}
    />
  )
}

export default ColorModeSwitcher;
