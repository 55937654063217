import React from 'react';
import { Global } from "@emotion/react"

const Fonts = () => (
  <Global
    styles={`
      @font-face {
          font-family: 'King Of The Hill 2';
          src: url('/fonts/KingOfTheHill2.eot');
          src: url('/fonts/KingOfTheHill2.eot?#iefix') format('embedded-opentype'),
              url('/fonts/KingOfTheHill2.woff2') format('woff2'),
              url('/fonts/KingOfTheHill2.woff') format('woff');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
      }
      @font-face {
          font-family: 'Andale Mono';
          src: url('/fonts/AndaleMono.eot');
          src: url('/fonts/AndaleMono.eot?#iefix') format('embedded-opentype'),
              url('/fonts/AndaleMono.woff2') format('woff2'),
              url('/fonts/AndaleMono.woff') format('woff');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
      }
      @font-face {
        font-family: 'Arcade Classic';
        src: url('/fonts/arcadeclassic.eot');
        src: url('/fonts/arcadeclassic.eot?#iefix') format('embedded-opentype'),
             url('/fonts/arcadeclassic.woff') format('woff'),
             url('/fonts/arcadeclassic.ttf') format('truetype'),
             url('/fonts/arcadeclassic.svg#arcadeclassic') format('svg');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }
    `}
  />
)

export default Fonts;
