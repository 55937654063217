import { Box, Button, Center, Heading, Link, Text } from "@chakra-ui/react";
import { FC } from "react";
import ButtonBox from "./ButtonBox";
import { GameData, GameDataState } from "./GameStructures";

interface CompleteProps {
  onComplete?: () => void;
  gameData: GameData | null;
  revealData?: any;
  address?: string;
}
const CompleteButton: FC<CompleteProps> = ({
  onComplete,
  gameData,
  revealData,
  address,
}) => {
  if (!gameData) {
    return null;
  }
  let title =
    gameData.state === GameDataState.won ? "Well Done!" : "Game Over";
  let msg = (
    <Text>
      You can play again whenever you like, but only your highest daily score
      will be added to the leaderboard. Remember: only ONE TRAIT will be revealed PER DAY!
    </Text>
  );
  if (revealData && revealData.code) {
    if (revealData.code === "revealed") {
      title = "Well Done!";
      msg = (
        <><Text>
          You completed the game!
        </Text>
        <Text>
          A new trait has been revealed on {revealData.fileList.length} GUY{revealData.fileList.length === 1 ? '' : 'S'}. Please refresh on <Link variant={"standard"} target="_blank" href={`https://opensea.io/${address || ''}`}>OpenSea</Link> to
          see your next trait.
        </Text>
        </>
      );
    } else if (revealData.code === "no-guys-can-reveal" || revealData.code === "no-guys-can-reveal-all-updated") {
      title = "Well Done!";
      msg = (
        <Text>
          You can play as much and as often as you like, but only ONE TRAIT will
          be revealed PER DAY!
        </Text>
      );
    }
  }

  return (
    <ButtonBox>
      <Center height={"100%"}>
        <Box
          backgroundColor="#000000ee"
          border="1px"
          py="10"
          px="2"
          zIndex={50}
          width={"100%"}
        >
          <Heading>{title}</Heading>
          {/* <Text>Your score has been submitted.</Text> */}
          {msg}
          <Button onClick={onComplete} variant={"invertTextButton"} my="5">
            Got it
          </Button>
        </Box>
      </Center>
    </ButtonBox>
  );
};

export default CompleteButton;
