import * as React from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { openSeaCollectionURL } from "../contract/contract";
import soldOut from "../assets/images/SOLDOUT.png";

const WhatsNextButton = () => {
  const bg = useColorModeValue("white", "grey.100");
  const color = useColorModeValue("black", "gray.100");
  return (
    <Link variant={"standard"} href={`/#progressive-reveal`}>
      <Button
          variant={"standard"}
          color={color}
          backgroundColor={bg}
          mb="3"
        >
        Learn about what's next
      </Button>
    </Link>
  );
}

const Status: React.FC = () => {
  let msg: any = "";
    msg = (
      <>
        <Text>
          You can check out ALL THE GUYS on{" "}
          <Link
            variant={"standard"}
            target="_blank"
            href={openSeaCollectionURL}
          >
            OpenSea
          </Link>
        </Text>
        <Text mt="5">
          <WhatsNextButton />
        </Text>
      </>
    );
  if (typeof msg === "string") {
    return <Text>{msg}</Text>;
  } else {
    return (
      <>
        <Text></Text>
        {msg}
      </>
    );
  }
};

const Mint: React.FC<any> = (props) => {

  return (
    <Box
      as="section"
      w={"100%"}
    >
      <Container maxW={{ xl: "1200px" }} px={0}>
        <Flex
          align="stretch"
          w={"100%"}
          margin="auto"
          direction={{ base: "column", md: "row" }}
        >
          <Box p={5} width={"100%"} margin="auto" textAlign={"center"}>
            <Image src={soldOut} margin="auto" mb="5" />
            <Status />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default Mint;
