import * as React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet";

import AppContainer from "../../components/AppContainer";
import PaintShoppe from "../../components/PaintShoppe/PaintShop";
import recaptchaConfig from "../../lib/recapture";

const PaintShoppeRoute: React.FC<any> = () => {
  return (
    <GoogleReCaptchaProvider
    reCaptchaKey={recaptchaConfig.id}
    scriptProps={{
      async: false, // optional, default to false,
      defer: false, // optional, default to false
      appendTo: "head", // optional, default to "head", can be "head" or "body",
      nonce: undefined, // optional, default undefined
    }}
  >

      <AppContainer>
        <Helmet>
          <title>Paint Shoppe</title>
          <meta name="apple-mobile-web-app-capable" content="yes"></meta>
        </Helmet>
        <PaintShoppe />
    </AppContainer>
    </GoogleReCaptchaProvider>
  );
};

export default PaintShoppeRoute;
