import { useCallback, useEffect, useMemo, useState } from "react";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { get } from "@chakra-ui/utils";
import ky, { HTTPError } from "ky";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import providerOptions from "../lib/web3/providerOptions";
import {
  contractAddress,
  netName,
  requiredChainId,
  shoppeTokenContractAddress,
} from "../contract/contract";
import appConfig from "../lib/appConfig";
import contract from "../contract/FeetAndEyesGuys.json";
import shoppeTokenContract from "../contract/PaintShoppeAccessToken.json";

const abi = contract.abi;
const shoppeTokenABI = shoppeTokenContract.abi;
interface ContractState {
  remainingSupply: number;
  allowlistSaleOpen: boolean;
  publicSaleOpen: boolean;
  onAllowlist: boolean;
  mintAllowance: number;
  numMinted: number;
  mintClosed: boolean;
  totalSupply: number;
  soldOut: boolean;
}

const InitialContractState: ContractState = {
  remainingSupply: 0,
  allowlistSaleOpen: false,
  publicSaleOpen: false,
  onAllowlist: false,
  mintAllowance: 0,
  numMinted: 0,
  mintClosed: true,
  totalSupply: 0,
  soldOut: true,
};
interface ShoppeTokenContractState {
  balanceOf: number;
  balanceOfGuys: number;
  saleOpen: boolean;
  canBuy: boolean;
  mintPrice: string;
}

const InitialShoppeTokenContractState: ShoppeTokenContractState = {
  balanceOf: -1,
  balanceOfGuys: -1,
  saleOpen: false,
  canBuy: false,
  mintPrice: "",
};

// enum UserLoginStatus {
//   unknown,
//   loggedIn,
//   loggedOut,
// }

export enum SigningStatus {
  unknown,
  signed,
  notSigned,
  errorSigning,
}

const useFEGWallet = (autoQuery = false, useLogout = true) => {
  const web3Modal = useMemo(
    () =>
      new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: true, // optional
        providerOptions, // required
      }),
    []
  );
  const [instance, setInstance] = useState<any>();
  const [provider, setProvider] = useState<any>();
  // const [library, setLibrary] = useState<
  //   ethers.providers.Web3Provider | undefined
  // >();
  const [account, setAccount] = useState<string | undefined>();
  const [network, setNetwork] = useState<
    ethers.providers.Network | undefined
  >();
  // const [error, setError] = useState("");
  const [chainId, setChainId] = useState<number | undefined>();
  const [signer, setSigner] = useState<
    ethers.providers.JsonRpcSigner | undefined
  >();
  const [signerAddress, setSignerAddress] = useState<string | undefined>();
  // const [txSubmitting, setTxSubmitting] = useState<boolean>(false);
  // const [queryingContractStatus, setQueryingContractStatus] =
  //   useState<boolean>(false);

  const [queryingShoppeContractStatus, setQueryingShoppeContractStatus] =
    useState<boolean>(false);

  const [queryingGuyBalance, setQueryingGuyBalance] = useState<boolean>(false);

  const [contractState, setContractState] =
    useState<ContractState>(InitialContractState);

  const [shoppeTokenContractState, setShoppeTokenContractState] =
    useState<ShoppeTokenContractState>(InitialShoppeTokenContractState);

  const [
    txSubmittingPaintShoppeAccessToken,
    SetTxSubmittingPaintShoppeAccessToken,
  ] = useState<boolean>(false);

  const [mintedShoppeAccessTokenId, setMintedShoppeAccessTokenId] =
    useState<number>(0);

  const [isSigning, setIsSigning] = useState<boolean>(false);
  const [signingState, setSigningState] = useState<SigningStatus>(
    SigningStatus.unknown
  );
  // const [userLoginStatus, setUserLoginStatus] = useState<UserLoginStatus>(
  //   UserLoginStatus.unknown
  // );

  const [isVerifyingSession, setIsVerifyingSession] = useState<boolean>(false);
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const connectWallet = useCallback(async () => {
    console.debug("connectWallet");
    try {
      console.debug("connecing to wallet");
      const instance = await web3Modal.connect();
      console.debug("connected to wallet");
      const provider = new ethers.providers.Web3Provider(instance);
      const accounts = await provider.listAccounts();
      const network = await provider.getNetwork();
      // const signer = undefined;
      // const signerAddress = '';
      const signer = await provider.getSigner();
      const signerAddress = await signer.getAddress();
      // console.debug("signer address is", signerAddress);
      const chainId = await network.chainId;
      if (chainId !== requiredChainId) {
        setProvider(null);
        setAccount(undefined);
        // setLibrary(undefined);
        setNetwork(undefined);
        setChainId(undefined);
        setSigner(undefined);
        setSignerAddress(undefined);
        alert(
          `Please make sure you are connected to the ${netName} network and try again.`
        );
        return;
      }
      console.debug("setting connected wallet data");
      setInstance(instance);
      setProvider(provider);
      if (accounts) setAccount(accounts[0]);
      setNetwork(network);
      setChainId(chainId);
      setSigner(signer);
      setSignerAddress(signerAddress);
    } catch (error) {
      console.error("connectWallet error");
      console.error(error);
    }
  }, [web3Modal]);

  // try and reconnect if the hook is remounted
  useEffect(() => {
    console.debug("connect wallet cache provider available");
    if (web3Modal.cachedProvider) {
      connectWallet();
    }
  }, [connectWallet, web3Modal.cachedProvider]);

  const refreshState = useCallback(() => {
    setAccount(undefined);
    setChainId(undefined);
    setNetwork(undefined);
    setProvider(null);
    // setLibrary(undefined);
    setNetwork(undefined);
    setSigner(undefined);
    setSignerAddress(undefined);
    setContractState(InitialContractState);
    setShoppeTokenContractState(InitialShoppeTokenContractState);
    setSigningState(SigningStatus.unknown);
  }, [setAccount, setChainId, setNetwork]);

  const logout = useCallback(async () => {
    try {
      const walletAddress = signerAddress;
      console.debug("logout for wallet", { walletAddress });
      if (!executeRecaptcha) {
        console.error("Execute recaptcha not yet available");
        return false;
      }
      setIsLoggingOut(true);
      const res: any = await ky
        .post(`${appConfig.functions.formBaseUrl}/user/logout`, {
          timeout: 60 * 1000,
          json: {
            wallet_address: walletAddress,
            token: (await executeRecaptcha(`logout`)),
          },
          credentials: "same-origin",
        })
        .json();
      console.debug(`logout res`, res);
      if (res.status !== "yes") {
        throw new Error("Unable to logout user.  not valid status");
      }
      return res;
    } catch (e) {
      if (e instanceof HTTPError) {
        try {
          const res = await e.response.json();
          console.error("verify error", res);
          let code = "001";
          if (res.code) {
            code = res.code;
          }
          alert(
            `There was an error verifying your login (e:2)-${code}.\n\nPlease grab a screenshot (and, if you can, grab a screenshot of your browser's console too) and send a DM to @FeetAndEyesGuys.  You can try reloading the page.`
          );
        } catch (e) {
          console.error(
            "error verify unable to parse response.  maybe a 404?"
          );
          alert(
            "There was an error verifying your login.\n\nPlease grab a screenshot (and, if you can, grab a screenshot of your browser's console too) and send a DM to @FeetAndEyesGuys.  You can try reloading the page."
          );
        }
      } else {
        let unknownError: any = e;
        console.error("error with logout user");
        console.error({
          errorInstance: typeof e,
          errorCode: unknownError.code,
          errorMesage: unknownError.message,
        });
        console.error(e);
        alert(
          `There was an error verifying your login (e:2)-${unknownError.code ? unknownError.code : '?'}.\n\nPlease grab a screenshot (and, if you can, grab a screenshot of your browser's console too) and send a DM to @FeetAndEyesGuys.  You can try reloading the page.`
          );
      }
      return {
        success: false,
        error: e,
      };
    } finally {
      setIsLoggingOut(false);
    }
  }, [executeRecaptcha, signerAddress]);

  const disconnect = useCallback(() => {
    if (useLogout) {
      logout();
    }
    web3Modal.clearCachedProvider();
    refreshState();
  }, [web3Modal, useLogout, refreshState, logout]);

  useEffect(() => {
    if (instance?.on) {
      const handleAccountsChanged = (accounts: string[]) => {
        disconnect();
        // console.debug("accountsChanged", accounts);
        // if (accounts) setAccount(accounts[0]);
        // connectWallet();
      };

      const handleChainChanged = (_hexChainId: number) => {
        disconnect();
        // setChainId(_hexChainId);
        // connectWallet();
      };

      const handleDisconnect = () => {
        // console.debug("disconnect", error);
        disconnect();
      };

      instance.on("accountsChanged", handleAccountsChanged);
      instance.on("chainChanged", handleChainChanged);
      instance.on("disconnect", handleDisconnect);

      return () => {
        if (instance.removeListener) {
          instance.removeListener("accountsChanged", handleAccountsChanged);
          instance.removeListener("chainChanged", handleChainChanged);
          instance.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [connectWallet, instance, disconnect]);

  // const queryContractStatus = useCallback(async () => {
  //   console.debug("query contract status", signer);
  //   if (!signer || !signerAddress) {
  //     console.debug("no signed or no signer address");
  //     return;
  //   }
  //   try {
  //     setQueryingContractStatus(true);
  //     console.debug('app state', {
  //       contractAddress,
  //       signerAddress,
  //       chainId,
  //       network: network ? network.name : "unknown",
  //     })
  //   } catch (e) {
  //     console.error("error while querying contract");
  //     console.error(e);
  //   } finally {
  //     setQueryingContractStatus(false);
  //   }
  // }, [signer, signerAddress, chainId, network]);

  // useEffect(() => {
  //   queryContractStatus();
  // }, [queryContractStatus]);

  const queryShoppeContractStatus = useCallback(async () => {
    console.debug("query shoppe contract status", signer);
    if (!signer || !signerAddress) {
      console.debug("no signed or no signer address");
      return;
    }
    try {
      setQueryingShoppeContractStatus(true);
      const shoppeContract = new ethers.Contract(
        shoppeTokenContractAddress,
        shoppeTokenABI,
        signer
      );
      const balanceOf = 0;
      const saleOpen = await shoppeContract.saleOpen();
      const mintPriceRes = await shoppeContract.mintPrice();
      const mintPrice: string = ethers.utils.formatEther(mintPriceRes);

      const balanceOfGuys = 0;

      const canBuy = true;
      setShoppeTokenContractState({
        balanceOf,
        balanceOfGuys,
        saleOpen,
        canBuy,
        mintPrice,
      });
      console.debug("query queryShoppeContractStatus state object", {
        balanceOf,
        balanceOfGuys,
        saleOpen,
        canBuy,
        mintPrice,
      });
      console.debug("app queryShoppeContractStatus state", {
        shoppeTokenContractAddress,
        signerAddress,
        chainId,
        network: network ? network.name : "unknown",
      });
    } catch (e) {
      console.error("error while querying paintshoppe access token contract");
      console.error(e);
    } finally {
      setQueryingShoppeContractStatus(false);
    }
  }, [signer, signerAddress, chainId, network]);

  const queryGuyBalance = useCallback(
    async (address: string) => {
      console.debug("query shoppe contract status", signer);
      let balanceOfGuys = -1;
      let balanceOf = -1;
      if (!signer || !address) {
        console.debug("no signed or no address");
        return {
          balanceOf,
          balanceOfGuys,
        }
      }
      try {
        setQueryingGuyBalance(true);
        const shoppeContract = new ethers.Contract(
          shoppeTokenContractAddress,
          shoppeTokenABI,
          signer
        );
        const balanceOfRes = await shoppeContract.balanceOf(address);
        balanceOf = balanceOfRes.toNumber();

        const contract = new ethers.Contract(contractAddress, abi, signer);
        const balanceOfGuysRes = await contract.balanceOf(address);
        balanceOfGuys = balanceOfGuysRes.toNumber();

        setShoppeTokenContractState((prev) => ({
          ...prev,
          balanceOfGuys,
          balanceOf,
        }));
        console.debug("query queryGuyBalance state object", {
          balanceOfGuys,
          balanceOf,
        });
      } catch (e) {
        console.error("error while queryGuyBalance contract");
        console.error(e);
      } finally {
        setQueryingGuyBalance(false);
        return { balanceOfGuys, balanceOf };
      }
    },
    [signer]
  );

  useEffect(() => {
    if (autoQuery) {
      queryShoppeContractStatus();
    }
  }, [queryShoppeContractStatus, autoQuery]);

  const buyPaintShoppeAccessToken = async (recipient: string) => {
    if (txSubmittingPaintShoppeAccessToken) {
      console.debug("waiting for tx to complete");
      return;
    }
    if (!shoppeTokenContractState.canBuy || !signerAddress) {
      console.debug("either cant mint, or no signeraddress - state error", {
        canBuy: shoppeTokenContractState.canBuy,
        signerAddress,
      });
      alert(
        "Sorry, something went wrong with the mint request.  Please ensure that you wallet is still connected and that you have enough funds (for gas) and you are on the Mainnet.\n\nIf the problem persists, please DM @FeetAndEyesGuys"
      );
      return;
    }
    try {
      setMintedShoppeAccessTokenId(0);
      const contract = new ethers.Contract(
        shoppeTokenContractAddress,
        shoppeTokenABI,
        signer
      );
      const overrides = {
        value: ethers.utils.parseEther(shoppeTokenContractState.mintPrice),
      };
      const tx = await contract.buyToken(recipient, overrides);
      SetTxSubmittingPaintShoppeAccessToken(true);
      const receipt = await tx.wait();
      console.debug(receipt.events);
      const tokenId = receipt.events.map((event: any) =>
        event.args[2].toNumber()
      )[0];
      console.debug(`tokenId: ${tokenId}`);
      setMintedShoppeAccessTokenId(tokenId);
      console.debug(`bbuyToken token with id: ${tokenId}`);
    } catch (err: any) {
      console.error("err", err);
      console.error("code", err.code);
      const reason = get(err as object, "error.message", null);
      console.error("reason", reason);
      if (reason) {
        alert(
          `There was an error during the Mint Access Token transaction.\n\nThe following reason was given:\n\n${reason}`
        );
      } else {
        alert(
          "There was an unknown error during the Mint Access Token transaction. Please check your wallet is still connected and that you have enough funds (including gas), your wallet has at least one Feet And Eyes Guy NFT and try again.  If the problem persits, please DM @FeetAndEyesGuys"
        );
      }
      return;
    } finally {
      SetTxSubmittingPaintShoppeAccessToken(false);
      queryShoppeContractStatus(); // update the shoppe contract data
    }
  };

  const doSignRequest = useCallback(async () => {
    if (!signer) {
      return {
        error: true,
        code: 701,
        message: "no account",
      };
    }
    try {
      if (isSigning) {
        return;
      }
      if (!executeRecaptcha) {
        console.error("Execute recaptcha not yet available");
        // alert(
        //   "There was an internal error generating the sign request (error code 1).  Please try reloading the page.  If the problem persists, please DM @FeetAndEyesGuys"
        // );
        return;
      }
      setIsSigning(true);
      const res: any = await ky
        .post(`${appConfig.functions.formBaseUrl}/user/sign_request`, {
          timeout: 60 * 1000,
          json: {
            wallet_address: await signer.getAddress(),
            token: await executeRecaptcha(`${await signer.getAddress()}_sign`),
          },
          credentials: "same-origin",
        })
        .json();
      console.debug(`getSignRequest res`, res);
      console.debug(`res.status: ${res.status}`);

      const signature = await signer.signMessage(res.toSign);

      const signResponse: any = await ky
        .post(`${appConfig.functions.formBaseUrl}/user/signed_message`, {
          timeout: 60 * 1000,
          json: {
            wallet_address: await signer.getAddress(),
            token: await executeRecaptcha(
              `${await signer.getAddress()}_sign_response`
            ),
            signature,
          },
          credentials: "same-origin",
        })
        .json();
      console.debug(`signResponse`, signResponse);
      setSigningState(SigningStatus.signed);
    } catch (e) {
      let code = 1;
      setSigningState(SigningStatus.errorSigning);
      if (e instanceof HTTPError) {
        try {
          const res = await e.response.json();
          console.error("doSignRequest error", res);
          if (res.code) {
            code = res.code;
          }
          if (res.relogin) {
            // alert(
            //   `There was an error creating the sign request (e:2) - ${code}.\n\nPlease press the "Disconnect Wallet" button and log back in.\n\nIf the issue persists, please grab a screenshot (and, if you can, grab a screenshot of your browser's console too) and send a DM to @FeetAndEyesGuys`
            // );
            disconnect();
          } else {
            alert(
              `There was an error creating the sign request (e:2) - ${code}.  Please grab a screenshot (and, if you can, grab a screenshot of your browser's console too) and send a DM to @FeetAndEyesGuys`
            );
          }
          return {
            success: true,
            code,
            ...res,
          };
        } catch (e) {
          console.error("unable to parse response.  maybe a 404?");
        }
      } else {
        let unknownError: any = e;
        console.error("error with doUserSign");
        console.error({
          errorInstance: typeof e,
          errorCode: unknownError.code,
          errorMesage: unknownError.message,
        });
        console.error(e);
        code = unknownError.code;
        if (unknownError.code && unknownError.code === 4001) {
          // metamask signing error
          alert(
            "Please complete the signing request.  This will allow us to authenticate you."
          );
        } else {
          code = 500;
          alert(
            "There was an error generating a sign request.  Please grab a screenshot (and, if you can, grab a screenshot of your browser's console too) and send a DM to @FeetAndEyesGuys"
          );
        }
      }
      return {
        success: false,
        error: e,
        code,
      };
    } finally {
      setIsSigning(false);
    }
  }, [disconnect, executeRecaptcha, signer, isSigning]);

  const verifySession = useCallback(async () => {
    try {
      const walletAddress = signerAddress;
      console.debug("verifySession for wallet", { walletAddress });
      if (!executeRecaptcha) {
        console.error("Execute recaptcha not yet available");
        return false;
      }
      if (!signerAddress) {
        console.error("signerAddress not yet available");
        return false;
      }
      setIsVerifyingSession(true);
      const res: any = await ky
        .post(`${appConfig.functions.formBaseUrl}/user/logged_id`, {
          timeout: 60 * 1000,
          json: {
            testing: '123',
            wallet_address: walletAddress,
            token: (await executeRecaptcha(`checkLogin`)),
          },
          credentials: "same-origin",
        })
        .json();
      console.debug(`verify res`, res);
      console.debug(`verify res.status: ${res.status}`);
      if (res.status !== "yes") {
        throw new Error("Unable to verify user");
      }
      if (!res.loggedId) {
        setSigningState(SigningStatus.notSigned);
      } else {
        setSigningState(SigningStatus.signed);
      }
      return res;
    } catch (e) {
      if (e instanceof HTTPError) {
        try {
          const res = await e.response.json();
          console.error("verify error", res);
          let code = "001";
          if (res.code) {
            code = res.code;
          }
          alert(
            `There was an error loading your guys (e:2)-${code}.\n\nPlease grab a screenshot (and, if you can, grab a screenshot of your browser's console too) and send a DM to @FeetAndEyesGuys.  You can try reloading the page.`
          );
        } catch (e) {
          console.error(
            "error verify unable to parse response.  maybe a 404?"
          );
          alert(
            "There was an error verifying your login.\n\nPlease grab a screenshot (and, if you can, grab a screenshot of your browser's console too) and send a DM to @FeetAndEyesGuys.  You can try reloading the page."
          );
        }
      } else {
        let unknownError: any = e;
        console.error("error with verify user");
        console.error({
          errorInstance: typeof e,
          errorCode: unknownError.code,
          errorMesage: unknownError.message,
        });
        console.error(e);
        alert(
          `There was an error loading your guys (e:2)-${unknownError.code ? unknownError.code : '?'}.\n\nPlease grab a screenshot (and, if you can, grab a screenshot of your browser's console too) and send a DM to @FeetAndEyesGuys.  You can try reloading the page.`
          );
      }
      return {
        success: false,
        error: e,
      };
    } finally {
      setIsVerifyingSession(false);
    }
  }, [executeRecaptcha, signerAddress]);

  return {
    account,
    buyPaintShoppeAccessToken,
    chainId,
    connectWallet,
    contractState,
    disconnect,
    doSignRequest,
    isConnected: !!account,
    isLoggingOut,
    isSigning,
    isVerifyingSession,
    logout,
    mintedShoppeAccessTokenId,
    network,
    provider,
    queryGuyBalance,
    // queryingContractStatus,
    queryingGuyBalance,
    queryingShoppeContractStatus,
    queryShoppeContractStatus,
    refreshState,
    shoppeTokenContractState,
    signer,
    signerAddress,
    signingState,
    // txSubmitting,
    txSubmittingPaintShoppeAccessToken,
    // userLoginStatus,
    verifySession,
  };
};

export default useFEGWallet;
