import * as React from "react";
import { HStack, Icon, Link } from "@chakra-ui/react";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import ReactGA from "react-ga4";
import { OpenSeaIcon } from "./OpenSeaIcon";
import { RaraAvisIcon } from "./RaraAvisIcon";

const trackNav = (name: string) => {
  ReactGA.event("page_event", {
    category: "social navigation event",
    action: "click",
    label: name,
  });
};

const Social: React.FC<any> = () => {
  const size = 6;
  return (
    <HStack spacing={3} justifyContent="center">
      <Link
        href="https://twitter.com/feetandeyesguys"
        target="_blank"
        onClick={() => trackNav("twitter")}
        title="Twitter"
        rel="noopener"
      >
        <Icon as={FaTwitter} w={size} h={size} color="current" />
      </Link>
      <Link
        href="https://discord.gg/v5fH48cFFy"
        target="_blank"
        title="Discord"
        rel="noopener"
        onClick={() => trackNav("discord")}
      >
        <Icon w={size} h={size} as={FaDiscord} color="current" />
      </Link>
      <Link
        href="https://www.instagram.com/feetandeyesguys/"
        target="_blank"
        title="Instagram"
        rel="noopener"
        onClick={() => trackNav("instagram")}
      >
        <Icon w={size} h={size} as={AiFillInstagram} color="current" />
      </Link>
      <Link
        href="https://opensea.io/collection/feet-and-eyes-guys"
        target="_blank"
        title="OpenSea"
        rel="noopener"
        onClick={() => trackNav("opensea")}
      >
        <Icon mt="0px" mb="2px" w={size} h={size} as={OpenSeaIcon} color="current" />
      </Link>
      <Link
        href="https://www.raraavis.app/feet-and-eyes-guys-tokens"
        target="_blank"
        title="Rara Avis Rarity"
        rel="noopener"
        onClick={() => trackNav("raraavis")}
      >
        <Icon mt="0px" mb="6px" w={5} h={5} as={RaraAvisIcon} color="current" />
      </Link>

    </HStack>
  );
};

export default Social;
