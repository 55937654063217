import * as React from "react";
import {
  Box,
  Center,
  Container,
  Heading,
  Text,
} from "@chakra-ui/react";
const Terms: React.FC<any> = (props) => {
  return (
    <Box as="section" w={"100%"}>
      <Container maxW={{ xl: "1200px" }} px={"30px"}>
        <Center>
          <Heading>Terms and Conditions</Heading>
        </Center>
        <Text my="5">
          Feet and Eyes Guys is a collection of digital artworks (NFTs) running
          on the Ethereum network. This website (“Site”) is only an interface
          allowing participants to purchase digital collectibles. Users are
          entirely responsible for the safety and management of their own
          private Ethereum wallets and validating all transactions and contracts
          generated by this website before approval. Furthermore, as the Feet
          and Eyes Guys smart contract runs on the Ethereum network, there is no
          ability to undo, reverse, or restore any transactions. This website
          and its connected services are provided “as is” and “as available”
          without warranty of any kind. By using this website you are accepting
          sole responsibility for any and all transactions involving Feet and
          Eyes Guys digital collectibles.
        </Text>
        <Heading size="md">1. Ownership</Heading>
        <Text my="5">
          A. You Own the NFT. Each Feet and Eyes Guys product is an NFT on the
          Ethereum blockchain. When you purchase an NFT, you own the underlying
          Feet and Eyes Guys product, the artistic work contained in the NFT
          (“Art”), completely. Ownership of the NFT is mediated entirely by the
          Smart Contract and the Ethereum Network: at no point may we seize,
          freeze, or otherwise modify the ownership of any Feet and Eyes Guys
          product.
        </Text>
        <Text my="5">
          B. Personal Use. Subject to your continued compliance with these
          Terms, Feet and Eyes Guys Inc. grants you a worldwide, royalty-free
          license to use, copy, and display the purchased Art, along with any
          extensions that you choose to create or use, solely for the following
          purposes: (i) for your own personal, non-commercial use; (ii) as part
          of a marketplace that permits the purchase and sale of your Feet and
          Eyes Guys product / NFT, provided that the marketplace
          cryptographically verifies each Feet and Eyes Guys product owner’s
          rights to display the Art for their Feet and Eyes Guys product to
          ensure that only the actual owner can display the Art; or (iii) as
          part of a third party website or application that permits the
          inclusion, involvement, or participation of your Feet and Eyes Guys
          product, provided that the website/application cryptographically
          verifies each Feet and Eyes Guys product owner’s rights to display the
          Art for their Feet and Eyes Guys product to ensure that only the
          actual owner can display the Art, and provided that the Art is no
          longer visible once the owner of the Feet and Eyes Guys product leaves
          the website/application.
        </Text>
        <Text my="5">
          C. Commercial Use. Subject to your continued compliance with these
          Terms, Feet and Eyes Guys Inc. grants you a non-exclusive worldwide
          license to use, copy, and display the purchased Art for the purpose of
          creating derivative works based upon the Art (“Commercial Use”).
          Examples of such Commercial Use would e.g. be the use of the Art to
          produce and sell merchandise products (T-Shirts etc.) displaying
          copies of the Art. For the sake of clarity, nothing in this Section
          will be deemed to restrict you from (i) owning or operating a
          marketplace that permits the use and sale of Feet and Eyes Guys
          products generally, provided that the marketplace cryptographically
          verifies each Feet and Eyes Guys product owner’s rights to display the
          Art for their Feet and Eyes Guys product to ensure that only the
          actual owner can display the Art; (ii) owning or operating a third
          party website or application that permits the inclusion, involvement,
          or participation of Feet and Eyes Guys products generally, provided
          that the third party website or application cryptographically verifies
          each Feet and Eyes Guys product owner’s rights to display the Art for
          their Feet and Eyes Guys product to ensure that only the actual owner
          can display the Art, and provided that the Art is no longer visible
          once the owner of the Purchased Feet and Eyes Guys product leaves the
          website/application; or (iii) earning revenue from any of the
          foregoing.
        </Text>
        <Text my="5">
          D. IP.  Other than the rights to the Art, nothing herein gives you any
          rights to any other trademarks or other intellectual property rights
          belonging to Feet and Eyes Guys Inc. including all logos. All of these
          rights are expressly reserved in the name of Feet and Eyes Guys Inc..
        </Text>
        <Text my="5">
          E. Feedback. You may choose to submit comments, bug reports, ideas or
          other feedback about the Site, including without limitation about how
          to improve the Site (collectively, “Feedback”). By submitting any
          Feedback, you agree that we are free to use such Feedback in any way
          we choose without additional compensation to you and you hereby grant
          us a perpetual, irrevocable, nonexclusive, worldwide license to
          incorporate and use the Feedback for any purpose.
        </Text>
        <Heading size="md">2. Your Obligations</Heading>
        <Text my="5">
          You are solely responsible for your own conduct while accessing or
          using the Site, and for any consequences thereof. You agree to use the
          Site only for purposes that are legal, proper and in accordance with
          these Terms and any applicable laws or regulations. By way of example,
          and not as a limitation, you may not, and may not allow any third
          party to: (i) send, upload, distribute or disseminate any unlawful,
          defamatory, harassing, abusive, fraudulent, hateful, violent, obscene,
          or otherwise objectionable content; (ii) distribute viruses, worms,
          defects, Trojan horses, corrupted files, hoaxes, or any other items of
          a destructive or deceptive nature; (iii) impersonate another person;
          (iv) upload, post, transmit or otherwise make available through the
          Site any content that infringes the intellectual property or
          proprietary rights of any party or otherwise violates the legal rights
          of others; (v) engage in, promote, or encourage illegal activity
          (including, without limitation, money laundering); (vi) interfere with
          other users' use of the Site; (vii) use the Site for any unauthorized
          commercial purpose; (viii) modify, adapt, translate, or reverse
          engineer any portion of the Site; (ix) remove any copyright, trademark
          or other proprietary rights notices contained in or on the Site or any
          part of it; (x) use any technology to collect information about the
          Site’s for any unauthorized purpose; (xi) access or use the Site for
          the purpose of creating a product or service that is competitive with
          any of our products or services. If you engage in any of the
          activities prohibited by this Section, we may, at our sole and
          absolute discretion, without notice to you, and without limiting any
          of our other rights or remedies at law or in equity, immediately
          suspend or terminate your user account, block your IP address or take
          any action we deem necessary.
        </Text>
        <Heading size="md">3. Fees and Payment</Heading>
        <Text my="5">
          A. If you elect to purchase a Feet and Eyes Guys product through the
          Site, any financial transactions that you engage in will be conducted
          solely through the Ethereum network. We will have no insight into or
          control over these payments or transactions, nor do we have the
          ability to reverse any transactions.  We will have no liability to you
          or to any third party for any claims or damages that may arise as a
          result of any transactions that you engage or any other transactions
          that you conduct via the Ethereum network.
        </Text>
        <Text my="5">
          B. Ethereum requires the payment of a transaction fee (a “Gas Fee”)
          for every transaction that occurs on the Ethereum network. The Gas Fee
          funds the network of computers that run the decentralized Ethereum
          network. This means that you will need to pay a Gas Fee for each
          transaction.
        </Text>
        <Heading size="md">4. Disclaimers</Heading>
        <Text my="5">
          A. YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF
          THE SITE IS AT YOUR SOLE RISK, AND THAT THE SITE IS PROVIDED "AS IS"
          AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
          IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW,
          WE MAKE NO EXPRESS WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED
          WARRANTIES REGARDING THE SITE AND ANY PART OF IT (INCLUDING, WITHOUT
          LIMITATION, THE SITE, ANY SMART CONTRACT, OR ANY EXTERNAL WEBSITES),
          INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR
          RELIABILITY. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE, OUR
          SUBSIDIARIES, AFFILIATES, AND LICENSORS DO NOT REPRESENT OR WARRANT TO
          YOU THAT: (I) YOUR ACCESS TO OR USE OF THE SITE WILL MEET YOUR
          REQUIREMENTS, (II) YOUR ACCESS TO OR USE OF THE SITE WILL BE
          UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR, (III) USAGE DATA
          PROVIDED THROUGH THE SITE WILL BE ACCURATE, (III) THE SITE OR ANY
          CONTENT, SERVICES, OR FEATURES MADE AVAILABLE ON OR THROUGH THE SITE
          ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR (IV) THAT ANY DATA
          THAT YOU DISCLOSE WHEN YOU USE THE SITE WILL BE SECURE. SOME
          JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN
          CONTRACTS WITH CONSUMERS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY
          NOT APPLY TO YOU.
        </Text>
        <Text my="5">
          B. YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND
          DEALING ONLINE OVER THE INTERNET, AND AGREE THAT WE HAVE NO LIABILITY
          OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR
          WILLFULL MISCONDUCT.
        </Text>
        <Text my="5">
          C. WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU
          INCUR AS THE RESULT OF YOUR USE OF THE ETHEREUM NETWORK NOR DO WE HAVE
          NO CONTROL OVER AND MAKE NO GUARANTEES REGARDING ANY SMART CONTRACTS.
        </Text>
        <Heading size="md">5. Limitation of Liability</Heading>
        <Text my="5">
          A. YOU UNDERSTAND AND AGREE THAT WE WILL NOT BE LIABLE TO YOU OR TO
          ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
          OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER
          ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF
          PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR
          BUSINESS REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE
          GOODS OR SERVICES, OR ANY OTHER INTANGIBLE LOSS, EVEN IF WE HAVE BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </Text>
        <Text my="5">
          B. YOU AGREE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND
          ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS OR YOUR ACCESS TO
          OR USE OF (OR YOUR INABILITY TO ACCESS OR USE) ANY PORTION OF THE
          SITE, WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL
          THEORY, IS LIMITED TO THE GREATER OF (A) THE AMOUNTS YOU ACTUALLY PAID
          US UNDER THESE TERMS IN THE 12 MONTH PERIOD PRECEDING THE DATE THE
          CLAIM AROSE, OR (B) $500.
        </Text>
        <Text my="5">
          C. YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE SITE AVAILABLE TO
          YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE WARRANTY
          DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN. WE WOULD
          NOT BE ABLE TO PROVIDE THE SITE TO YOU WITHOUT THESE LIMITATIONS.
        </Text>
        <Heading size="md">6. Risk Assumption</Heading>
        <Text my="5">You accept and acknowledge each of the following:</Text>
        <Text my="5">
          A. To the extent that you sell your Feet and Eyes Guys product NFT,
          please be aware that the prices of NFTs are extremely volatile and
          fluctuations in the prices of other NFTs and impact the price of your
          Feet and Eyes Guys product both positively and negatively.  Given the
          volatility, NFTs such as Feet and Eyes Guys product should not be
          considered an investment. You assume all risks in that connection.
        </Text>
        <Text my="5">
          B. Ownership of a Feet and Eyes Guys product confers ownership of
          digital artwork only. Accordingly, no information on this Site (or any
          other documents mentioned therein) is or may be considered to be
          financial advice, advice pertaining to NFTs generally or an invitation
          to enter into an agreement for any investment purpose. Further,
          nothing on this Site qualifies or is intended to be an offering of
          securities in any jurisdiction nor does it constitute an offer or an
          invitation to purchase shares, securities or other financial
          products.  Due to the artistic nature of the project, FEET AND EYES
          GUYS INC. has not been registered with or approved by any regulator in
          any jurisdiction. It remains your sole responsibility to assure that
          the purchase of the Feet and Eyes Guys product and the associated art
          is in compliance with laws and regulations in your jurisdiction.
        </Text>
        <Text my="5">
          C. You assume all risks associated with using an Internet-based
          currency, including, but not limited to, the risk of hardware,
          software and Internet connections, the risk of malicious software
          introduction, and the risk that third parties may obtain unauthorized
          access to information stored within your wallet.
        </Text>
        <Text my="5">
          D. NFTs, cryptocurrencies and blockchain technology are relatively new
          and the regulatory landscape is unsettled.  New regulations could
          negatively impact such technologies impacting the value for your Feet
          and Eyes Guys product. You understand and accept all risk in that
          regard.
        </Text>
        <Text my="5">
          E. You assume all responsibility for any adverse effects of
          disruptions or other issues impacting Ethereum or the Ethereum
          platform.
        </Text>
        <Heading size="md">7. Indemnification</Heading>
        <Text my="5">
          You agree to hold harmless and indemnify FEET AND EYES GUYS INC. and
          its subsidiaries, affiliates, officers, agents, employees,
          advertisers, licensors, suppliers or partners from and against any
          claim, liability, loss, damage (actual and consequential) of any kind
          or nature, suit, judgment, litigation cost, and reasonable attorneys'
          fees arising out of or in any way related to (i) your breach of these
          Terms, (ii) your misuse of the Site, or (iii) your violation of
          applicable laws, rules or regulations in connection with your access
          to or use of the Site.
        </Text>
        <Heading size="md">8. Changes to the Terms and Conditions</Heading>
        <Text my="5">
          We may make changes to the Terms at our discretion. Please check these
          Terms periodically for changes. Any changes to the Terms will apply on
          the date that they are made, and your continued access to or use after
          the Terms have been updated will constitute your binding acceptance of
          the updates. If you do not agree to any revised Terms, you may not
          access or use the Site.
        </Text>
        <Heading size="md">9. Children</Heading>
        <Text my="5">
          Our Site is not intended for children.  You must be at least 18 years
          old to access this Site or purchase a Feet and Eyes Guys product. If
          you are under 18 years old you are not permitted to use this Site for
          any reason. By accessing the Site, you represent and warrant that you
          are at least 18 years of age.
        </Text>
        <Heading size="md">10. Dispute Resolution; Arbitration</Heading>
        <Text my="5">
          All disputes arising out of or in connection with these Terms,
          including without limitation your access or use of the Site, or to any
          products sold or distributed through the Site, will be referred to and
          finally resolved by arbitration under the rules of the American
          Arbitration Association. The case will be adjudicated by a single
          arbitrator and will be administered by JAMS in accordance with its
          applicable rules. Each party will cover its own fees and costs
          associated with the arbitration proceedings. The place of arbitration
          will be Toronto, Canada. The award of the arbitrator will be final and
          binding, and any judgment on the award rendered by the arbitrator may
          be entered in any court of competent jurisdiction. Notwithstanding the
          foregoing, we may seek and obtain injunctive relief in any
          jurisdiction in any court of competent jurisdiction.
        </Text>
        <Text my="5">
          WITH RESPECT TO ANY DISPUTE ARISING OUT OF OR RELATED TO THESE TERMS,
          INCLUDING WITHOUT LIMITATION DISPUTES RELATED TO THE SITE OR ANY
          PRODUCTS SOLD OR DISTRIBUTED THROUGH THE SITE, OR THE SMART CONTRACTS:
          (I) YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO HAVE A TRIAL BY JURY;
          AND (II) YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO PARTICIPATE AS A
          MEMBER OF A CLASS OF CLAIMANTS IN ANY LAWSUIT, INCLUDING BUT NOT
          LIMITED TO CLASS ACTION LAWSUITS INVOLVING ANY SUCH DISPUTE
        </Text>
      </Container>
    </Box>
  );
};

export default Terms;
