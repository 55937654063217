import * as React from 'react';
import {
  Box
} from "@chakra-ui/react";
import { useRef, useEffect, useState, forwardRef, useImperativeHandle } from "react";

import { CanvasContext } from "../../../hooks/useCanvas";
import { GameData } from "../GameStructures";
import Simon from "./simon";

interface Props {
  onGameOver: (gameData: GameData) => void;
  onReady: (ready: boolean) => void;
}

const FroggerCanvas = React.memo(forwardRef(({ onGameOver, onReady }: Props, ref: any) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const gameRef = useRef<any>();
  const [context, setContext] = useState<
    CanvasRenderingContext2D | undefined
  >();

  useEffect(() => {
    const ctx = canvasRef?.current?.getContext("2d");
    if (ctx) setContext(ctx);
  }, []);

  useImperativeHandle(ref, () => ({
    startGame() {
      if (gameRef.current) {
        console.log('starting game from canvas')
        gameRef.current.startGame();
        return true;
      }
      return false;
    },
  }));

  
  console.log('render simon canvas');

  return (
    <Box position={"relative"}>
      <CanvasContext.Provider value={{ context: context }}>
        <Simon ref={gameRef} onGameOver={onGameOver} onReady={onReady} />
        <div
          id="simon-parent"
        ></div>
      </CanvasContext.Provider>
    </Box>
  );
}));

export default FroggerCanvas;
