import * as React from "react";
import { Helmet } from "react-helmet";
import AppContainer from "../../components/AppContainer";
import Mint from "../../components/Mint";

const MintRoute: React.FC<any> = () => {
  return (
      <AppContainer>
        <Helmet>
          <title>Mint</title>
          <meta name="apple-mobile-web-app-capable" content="yes"></meta>
        </Helmet>
          <Mint />
    </AppContainer>
  );
};

export default MintRoute;
