import { Box } from "@chakra-ui/react";
import { FC } from "react";

const ButtonBox: FC<any> = ({ children }) => {
  return (
    <Box
      position={"absolute"}
      top={0}
      left={0}
      width="100%"
      height={"100%"}
      backgroundColor="#00000099"
    >
      {children}
    </Box>
  );
};

export default ButtonBox;