import { ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import ReactGA from "react-ga4";

import Fonts from "./theme/Fonts";
import theme from "./theme";

import { App } from "./App";
import Merch from "./routes/Merch";
// import AllowListForm from "./routes/AllowListForm";
// import PublicAllowListForm from "./routes/PublicAllowListForm";
import Terms from "./routes/Terms";

import Mint from "./routes/Mint";
import Footer from "./components/Footer";

import * as serviceWorker from "./serviceWorker";
import AllowListClosed from "./routes/AllowListClosed";
// import CollabListForm from "./routes/CollabListForm";
// import IngestFormForm from "./routes/IngestListForm";
// import WalletConfirm from "./routes/WalletConfirm";
import ArcadeJumpyGuy from "./routes/Arcade/frogger";
import ArcadeNorman from "./routes/Arcade/simon";
import Arcade from "./routes/Arcade";
import PaintShoppeRoute from "./routes/PaintShoppe";
import BuyPaintShoppeTokenRoute from "./routes/BuyPaintShoppeToken";


interface EthereumProvider {
  isMetaMask?: boolean;
}
declare global {
  interface Window {
    web3: any;
    // @ts-ignore
    ethereum: EthereumProvider | undefined;
    Web3Modal: any;
    Phaser: any;
    [name: string]: any;
  }
}

ReactGA.initialize("G-ZDRH2E4B5D");

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <ChakraProvider theme={theme}>
          <Fonts />
          <Routes>
            <Route path="/" key="home" element={<App />} />
            <Route path="/merch" key="merch" index={false} element={<Merch />} />
            <Route path="/arcade/jumpyguy" key="arcadeJumpyguy" index={false} element={<ArcadeJumpyGuy />} />
            <Route path="/arcade/norman" key="arcadeNorman" index={false} element={<ArcadeNorman />} />
            <Route path="/arcade" key="arcade" index={false} element={<Arcade />} />
            <Route path="/collab/:id" key="collablist" index={false} element={<AllowListClosed />} />
            <Route path="/allowlist" key="allowlist" index={false} element={<AllowListClosed />} />
            <Route path="/closed" key="closed" index={false} element={<AllowListClosed />} />
            <Route path="/mint" key="mint" index={false} element={<Mint />} />
            <Route path="/terms" key="terms" index={false} element={<Terms />} />
            <Route path="/paintshoppe" key="paintshoppe" index={false} element={<PaintShoppeRoute />} />
            <Route path="/paintshoppe/buy" key="paintshoppebuy" index={false} element={<BuyPaintShoppeTokenRoute />} />
            <Route path="*" element={<App />} />
          </Routes>
          <Footer/>
        </ChakraProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root"),
)
serviceWorker.unregister();
