import * as React from "react";
import { Helmet } from "react-helmet";

import AppContainer from "../../components/AppContainer";
import Terms from "../../components/Terms";

const TermsRoute: React.FC<any> = () => {
  return (
    <AppContainer>
      <Helmet>
        <title>Terms</title>
      </Helmet>
      <Terms />
    </AppContainer>
  );
};

export default TermsRoute;
