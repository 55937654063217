import * as React from "react";
import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { TwitterFollowButton } from "react-twitter-embed";

import followLeft from "../assets/images/guys/formGuys/left.png";
import followRight from "../assets/images/guys/formGuys/right.png";

import "./traits.css";

const AllowListClosed: React.FC<any> = (props) => {
  return (
    <Box as="section" w={"100%"} backgroundColor={"black"} pb={20}>
      <Container maxW={{ xl: "1000px", md: "1000px" }} px={0}>
        <Flex
          w={"100%"}
          backgroundColor={"black"}
          minHeight={["auto", "375px"]}
          direction={{ base: "column", md: "row" }}
        >
          <Flex width={"100%"} flex={2} justifyContent={"center"}>
            <Image
              src={followLeft}
              alt="Guy"
              maxHeight={["300px", "300px", "2000px"]}
              py={0}
              objectFit="contain"
            />
          </Flex>
          <Flex flex={3}>
            <Box p={5} width={"100%"} textColor={"white"}>
              <Heading textAlign={"center"} mb={8} mt="8" size={"3xl"}>
                Too late...
              </Heading>
              <VStack spacing={4} textAlign={"center"} pb="10">
                <Text>But follow us on Twitter to be the FIRST to know when the allow list will be opening back up!</Text>
                <Box py="4" display={"block"} minH="60px">
                  <TwitterFollowButton
                    screenName="FeetAndEyesGuys"
                    options={{
                      size: "large",
                      showCount: false,
                    }}
                  />
                </Box>
              </VStack>
            </Box>
          </Flex>
          <Flex
            width={"100%"}
            flex={2}
            justifyContent={"center"}
            display={["none", "none", "flex"]}
          >
            <Image
              src={followRight}
              maxHeight={["300px", "300px", "2000px"]}
              alt="Guy"
              py={0}
              objectFit="contain"
            />
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default AllowListClosed;
