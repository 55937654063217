import * as React from "react";
import {
  Avatar,
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Link,
  Text,
  chakra,
} from "@chakra-ui/react";

import m00nage from "../assets/images/guys/m00nage.png";
import frank from "../assets/images/guys/das_frank.png";
import joe from "../assets/images/guys/joe.png";
import jay from "../assets/images/guys/jay.png";
import developer from "../assets/images/guys/developer.png";
import sam from "../assets/images/guys/sam.png";

import "./team.css";

const TeamMember = ({
  src,
  role,
  name,
  href,
}: {
  src: any;
  role: string;
  name: string;
  href?: string;
}) => {
  let boxAs: any = chakra('div');
  if (href) {
    boxAs = Link;
  }
  return (
    <Box
      as={boxAs}
      maxW={["100%", "400px", "30%"]}
      minW={["280px", "285px", "285px"]}
      width={"auto"}
      overflow="hidden"
      my={10}
      className="teamBox"
      alignContent="center"
      mx={1}
      href={href}
      rel="noopener"
      target={"_blank"}
    >
      <Center>
        <Avatar
          src={src}
          alignItems="center"
          alignSelf="center"
          maxW={"93%"}
          width={"auto"}
          size={"1px"}
          borderRadius={"35px"}
          backgroundColor={"white"}
          p={"20px"}
          alignContent={"center"}
          title={name}
          name={name}
        />
      </Center>
      <Box textAlign={"center"} textColor={"white"}>
        <Heading my="2" lineHeight="2.5rem">
          {role}
        </Heading>
        <Text fontSize="3xl" mt="1.0rem" lineHeight="2rem">
          {name}
        </Text>
      </Box>
    </Box>
  );
};

const Traits: React.FC<any> = (props) => {
  const developerName = Math.random() > 0.0 ? "Adam Hunter" : "Nurse Fission"
  return (
    <Box width={"100%"} backgroundColor={"black"} as="section">
      <Container maxW={{ xl: "1200px" }} px={0}>
        <Center><Heading color="white" mt={50} mb={3} textAlign="center">
            <Box as="span" fontSize="4xl">There's no Eye in </Box> Team</Heading>
          </Center>
        <Flex
          width={"100%"}
          p={[0, 0, 5]}
          backgroundColor={"black"}
          justifyContent={["center", "space-around", "space-around"]}
          alignItems="flex-start"
          position={"relative"}
          flexWrap={"wrap"}
          direction={{ base: "row" }}
        >
          <TeamMember role="Creator" name="Caitlin Cronenberg" src={m00nage} href="https://twitter.com/caitcronenberg" />
          <TeamMember role="Artist" name="Sam Gorrie" src={sam} href="https://twitter.com/sam_artypants" />
          <TeamMember role="Artist" name="Das Frank" src={frank} href="https://twitter.com/artist_dasfrank" />
          <TeamMember
            role="Head of Community"
            name="Brandon Davis"
            src={joe}
            href="https://twitter.com/BrandonDavisNFT"
          />
          <TeamMember
            role="Project Advisor"
            name="Jay Rosenzweig"
            src={jay}
            href="https://www.jayrosenzweig.com/about"
          />
          <TeamMember role="Lead Developer" name={developerName} src={developer} href="/" />
        </Flex>
      </Container>
    </Box>
  );
};

export default Traits;
