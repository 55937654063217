import { useCallback, useEffect, useState } from "react";

function preloadImage(src: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = img.onabort = function () {
      reject(src);
    };
    img.src = src;
  });
}

export default function useImagePreloader() {
  const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false);
  const [imageList, setImageList] = useState<string[]>([]);
  useEffect(() => {
    if (imageList.length < 1) {
      return;
    }
    let isCancelled = false;

    async function doPreload() {
      if (isCancelled) {
        return;
      }

      const imagesPromiseList: Promise<any>[] = [];
      for (const i of imageList) {
        imagesPromiseList.push(preloadImage(i));
      }
      await Promise.all(imagesPromiseList);

      if (isCancelled) {
        return;
      }
      setImagesPreloaded(true);
    }

    doPreload();

    return () => {
      isCancelled = true;
    };
  }, [imageList]);

  const preloadImages = useCallback(
    (filenames: string[]) => {
      setImageList(filenames);
    },
    [setImageList]
  );

  return { imagesPreloaded, preloadImages };
}
