import { Box, Button, Center, Image } from "@chakra-ui/react";
import { FC } from "react";
import ButtonBox from "./ButtonBox";

import pickATeam from "../../assets/images/games/pickATeam.png";
import { TeamType } from "./GameStructures";

interface StartProps {
  onComplete: (team: TeamType) => void;
}

const PickATeamButton: FC<StartProps> = ({ onComplete }) => {
  return (
    <ButtonBox>
      <Box position={"absolute"} top={0} left={0} bottom={0} right={0}>
        <Box
          position={"absolute"}
          top={0}
          left={0}
          bottom={0}
          right={0}
          backgroundColor={"#00000099"}
        />
      </Box>
      <Center height={"100%"}>
        <Box
          backgroundColor="#000000ee"
          py="10"
          height={"auto"}
          zIndex={1000}
          width={"100%"}
          border="0"
        >
          <Image src={pickATeam} alt="Pick a team!" />
          <Button
            position={"absolute"}
            top="37%"
            left="23.0%"
            h="110px"
            w="110px"
            maxW={"22%"}
            variant={"clear"}
            zIndex={999}
            onClick={() => onComplete(TeamType.eyes)}
          >
          </Button>
          <Button
            position={"absolute"}
            top="37%"
            right="23.0%"
            h="110px"
            w="110px"
            maxW={"22%"}
            variant={"clear"}
            zIndex={999}
            onClick={() => onComplete(TeamType.feet)}
          >
          </Button>
        </Box>
      </Center>
    </ButtonBox>
  );
};

export default PickATeamButton;
