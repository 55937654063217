import * as React from "react";
import { Helmet } from "react-helmet";
import AppContainer from "../../components/AppContainer";
import BuyPaintShoppeToken from "../../components/PaintShoppe/BuyPaintShoppeToken";

const BuyPaintShoppeTokenRoute: React.FC<any> = () => {
  return (
      <AppContainer>
        <Helmet>
          <title>Buy Paint Shoppe Token</title>
          <meta name="apple-mobile-web-app-capable" content="yes"></meta>
        </Helmet>
        <BuyPaintShoppeToken />
    </AppContainer>
  );
};

export default BuyPaintShoppeTokenRoute;
