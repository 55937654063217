import { Autoplay, Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Center, Heading, Image, Link } from "@chakra-ui/react";

import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";

import "./carousel.css";

import page1 from "../assets/images/carousel/page1.png";
import page2 from "../assets/images/carousel/page2.png";
import page3 from "../assets/images/carousel/page3.png";

interface Props {
  height: number;
}

const Carousel = ({ height }: Props) => {
  return (
    <Swiper
      modules={[Autoplay, Navigation, Pagination, A11y]}
      height={height}
      spaceBetween={50}
      slidesPerView={1}
      loop
      navigation
      centeredSlides
      pagination={{
        dynamicBullets: false,
        clickable: true,
      }}
      // autoplay={{
      //   delay: 4200,
      //   disableOnInteraction: false,
      //   pauseOnMouseEnter: true,
      // }}
      className="carouselSwiper"
    >
      <SwiperSlide style={{ height: `${height}px`, position: "relative" }}>
          <Image src={page3} />
          <Box
            position={"absolute"}
            w="100%"
            top="0"
            left="45%"
            maxWidth={{ base: "45%", md: "45%" }}
            height={height}
            // backgroundColor="purple"
          >
        <Link href="/paintshoppe/buy" p="0" m="0">
            <Center height="100%" alignItems="center">
              <Heading
                // backgroundColor={"yellow"}
                textColor={"white"}
                fontSize={["3xl", "4xl", "3.5rem", "4.5rem"]}
              >
                MINT YOUR PAINTSHOPPE ACCESS TOKEN NOW!
              </Heading>
            </Center>
        </Link>
          </Box>
      </SwiperSlide>
      <SwiperSlide style={{ height: `${height}px` }}>
        <Image src={page1} />
      </SwiperSlide>
      <SwiperSlide style={{ height: `${height}px` }}>
        <Image src={page2} />
      </SwiperSlide>
    </Swiper>
  );
};

export default Carousel;
