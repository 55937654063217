import React from 'react';
import {
  Box,
  Image,
  ListItem,
} from '@chakra-ui/react';

function LineItemm(props: any){

  const decrementQuantity = (lineItemId: number) => {
    const updatedQuantity = props.line_item.quantity - 1
    props.updateLineItemInCart(lineItemId, updatedQuantity);
  }

  const incrementQuantity = (lineItemId: number) => {
    const updatedQuantity = props.line_item.quantity + 1
    props.updateLineItemInCart(lineItemId, updatedQuantity);
  }

  return (
    <ListItem className="Line-item" key={props.line_item.id}>
        {props.line_item.variant.image ? <Image 
          maxW="65px"
          maxH="65px"
          position="absolute"
        
         src={props.line_item.variant.image.src} alt={`${props.line_item.title} product shot`}/> : null}
      <Box className="Line-item__content">
        <Box className="Line-item__content-row">
          <Box className="Line-item__variant-title">
            {props.line_item.variant.title === 'Default Title' ? null : props.line_item.variant.title}
          </Box>
          <Box>
            {props.line_item.title}
          </Box>
        </Box>
        <Box className="Line-item__content-row">
          <Box className="Line-item__quantity-container">
            <button className="Line-item__quantity-update" onClick={() => decrementQuantity(props.line_item.id)}>-</button>
            <Box className="Line-item__quantity" color="currentcolor">{props.line_item.quantity}</Box>
            <button className="Line-item__quantity-update" onClick={() => incrementQuantity(props.line_item.id)}>+</button>
          </Box>
          <Box className="Line-item__price">
            $ { (props.line_item.quantity * props.line_item.variant.price).toFixed(2) }
          </Box>
          <button className="Line-item__remove" onClick={()=> props.removeLineItemInCart(props.line_item.id)}>×</button>
        </Box>
      </Box>
    </ListItem>
  );
}

export default LineItemm;
