import * as React from "react";
import { Box, Container, Heading, Image } from "@chakra-ui/react";
import { useState } from "react";
import useSound from "use-sound";

import roadmapBaseDesktop from "../assets/images/roadmap/roadmapv2b.jpg";
// import roadmapBaseDesktop from "../assets/images/roadmap/ROADMAP_MASTER_WITHARM.jpg";
// import armWithBrush from "../assets/images/roadmap/ARMWITHBRUSH.png";

import colorStatesGif from "../assets/images/roadmap/colorstates/colorStates.gif";
import RoadmapItem from "./RoadmapItem2022V2";

// import "./roadmapv2.css";

// function importAll(r: any) {
//   let images: any = [];
//   r.keys().forEach((item: any, index: number) => {
//     console.log(item);
//     images[index] = r(item);//`../assets/images/roadmap/colorstates/${item.replace("./","")}`);
//     console.log(`${index}`, images[index]);
//   });
//   return images;
// }

// const colorStates = importAll(
//   require.context("../assets/images/roadmap/colorstates", false, /\.(png)$/)
// );

const jingleSfx = "/sounds/jingle.mp3";

const Roadmap2022V2: React.FC<any> = (props) => {
  const [playJingle, { stop: stopJingle, sound }] = useSound(jingleSfx, {
    volume: 0.5,
    playbackRate: 1.0,
    interrupt: true,
  });
  const [showCard, setShowCard] = useState<number>(-1);
  const handleHideCard = () => {
    setShowCard(-1);
  };
  const handleShowCard = (
    e: React.SyntheticEvent<HTMLImageElement, Event>,
    cardId: number
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (showCard === cardId) {
      handleHideCard();
    } else {
      setShowCard(cardId);
    }
  };
  const toggleJingle = () => {
    if (sound.playing()) {
      stopJingle();
    } else {
      playJingle();
    }
  };
  return (
    <>
      <Box
        overflow={"hidden"}
        position="relative"
        pt="10"
        pb="20"
        className="roadmap2022Box"
        backgroundColor="black"
      >
        <Container maxW={{ xl: "1200px" }} px={0} as="section">
          <Box
            overflow={"hidden"}
            position="relative"
            pt="0"
            pb="00"
            onClick={showCard > -1 ? handleHideCard : undefined}
          >
            <Heading
              alignContent={"center"}
              textAlign="center"
              mb={5}
              textColor="white"
            >
              Roadmap
            </Heading>
            <Box
              mx="20px"
              position={"relative"}
              // display={["none", "none", "block"]}
            >
              <Image src={roadmapBaseDesktop} />

              <Image
                src={colorStatesGif}
                width={"26%"}
                height="auto"
                position={"absolute"}
                top="23.1%"
                left="29.5%"
              />
              {/* <Image
                src={armWithBrush}
                width={"16.4%"}
                height="auto"
                position={"absolute"}
                top="43.0%"
                left="46.5%"
              /> */}
              <RoadmapItem
                w="18%"
                h="28.9%"
                top="1.3%"
                left="9.7%"
                title="Color Ways"
                cardId={0}
                activeCardId={showCard}
                onClick={handleShowCard}
              />
              <RoadmapItem
                w="18%"
                h="28.9%"
                top="35.2%"
                left="35.5%"
                title="Paint Shoppe"
                cardId={1}
                activeCardId={showCard}
                onClick={handleShowCard}
              />
              <RoadmapItem
                w="21%"
                h="27%"
                top="68.2%"
                left="12.0%"
                title="Paint Shoppe Access Token"
                cardId={2}
                activeCardId={showCard}
                onClick={handleShowCard}
              />
              <RoadmapItem
                w="7.5%"
                h="28.9%"
                top="35.2%"
                left="27.5%"
                title="Backgrounds"
                cardId={3}
                activeCardId={showCard}
                onClick={handleShowCard}
              />
              <RoadmapItem
                w="7.5%"
                h="28.9%"
                top="35.2%"
                left="54.0%"
                title="Backgrounds"
                cardId={3}
                activeCardId={showCard}
                onClick={handleShowCard}
              />
              <RoadmapItem
                w="28%"
                h="27.0%"
                top="4.5%"
                left="62.5%"
                title="Merch"
                cardId={4}
                activeCardId={showCard}
                onClick={handleShowCard}
              />
              <RoadmapItem
                w="16%"
                h="47.0%"
                top="34.0%"
                left="84.0%"
                title="Arcade"
                cardId={5}
                activeCardId={showCard}
                onClick={handleShowCard}
              />
              <RoadmapItem
                w="8%"
                h="10.0%"
                top="18.5%"
                left="50.5%"
                title="Mystery Boxes"
                cardId={6}
                activeCardId={showCard}
                onClick={handleShowCard}
              />
              <RoadmapItem
                w="19%"
                h="28.9%"
                top="41.0%"
                left="62.0%"
                title="Collabs"
                cardId={7}
                activeCardId={showCard}
                onClick={handleShowCard}
              />
              <RoadmapItem
                w="5.8%"
                h="10.5%"
                top="23.2%"
                left="92.0%"
                title="What time is it?"
                cardId={8}
                activeCardId={showCard}
                onClick={handleShowCard}
              />
              <RoadmapItem
                w="9.5%"
                h="10.5%"
                top="83.2%"
                left="68.0%"
                title=""
                cardId={9}
                activeCardId={showCard}
                onClick={handleShowCard}
              />
              <RoadmapItem
                w="8.5%"
                h="9%"
                top="71.2%"
                left="75.0%"
                title=""
                cardId={10}
                activeCardId={showCard}
                onClick={handleShowCard}
              />
              <RoadmapItem
                w="16.5%"
                h="5.5%"
                top="83.2%"
                left="40.0%"
                title=""
                cardId={11}
                activeCardId={showCard}
                onClick={handleShowCard}
                // backgroundColor={"pink"}
                // opacity="0.5"
              />
              <Box
                position={"absolute"}
                w="2%"
                h="3%"
                top="56.7%"
                left="5.3%"
                // backgroundColor={"pink"}
                // opacity="0.5"
                onClick={toggleJingle}
                cursor="pointer"
              ></Box>
              {/* <Box>
                {colorStates.map((image: any, index: number) => (
                  <Image
                    id={`colour-guy-${index}`}
                    key={`colour-guy-${index}`}
                    className="image"
                    alt="colour guy 1"
                    src={image.default}
                    style={{
                      animationDelay: `${index * 4}s`, // delay animation for next images
                      zIndex: (1000 - index), // make images as layers, not same layer -1
                    }}
                  />
                ))}
              </Box> */}
            </Box>
            {/* <Box
              mx="20px"
              position={"relative"}
              display={["block", "block", "none"]}
            >
              <Image src={roadmapBaseMobile} />
              <RoadmapItem
                src={houseplant}
                // maxW="60px"
                w="8.5%"
                top="17.0%"
                left="51.7%"
                title="Community Chest"
                cardId={0}
                activeCardId={showCard}
                onClick={handleShowCard}
              />
              <RoadmapItem
                src={manSword}
                // maxW="40px"
                w="5.5%"
                top="25.0%"
                left="49.5%"
                title="TEAM PLAYER"
                cardId={1}
                activeCardId={showCard}
                onClick={handleShowCard}
              />
              <RoadmapItem
                src={drumstick}
                // maxW="45px"
                w="6.5%"
                top="32.5%"
                left="47.5%"
                title="HELLO MERCH"
                cardId={2}
                activeCardId={showCard}
                onClick={handleShowCard}
              />
              <RoadmapItem
                src={bubbles}
                // maxW="45%"
                w="7.5%"
                top="39%"
                left="46%"
                title="HOWDY PARTNER"
                cardId={3}
                activeCardId={showCard}
                onClick={handleShowCard}
              />
              <RoadmapItem
                src={sword}
                // maxW="60px"
                w="8.6%"
                top="46.5%"
                left="45%"
                title="THE ARCADE"
                cardId={4}
                activeCardId={showCard}
                onClick={handleShowCard}
              />
              <RoadmapItem
                src={goldenSkull}
                // maxW="45px"
                w="8.5%"
                top="54.0%"
                left="45.0%"
                title="MINT"
                cardId={5}
                activeCardId={showCard}
                onClick={handleShowCard}
              />
              <RoadmapItem
                src={pipe}
                w="7%"
                top="60.5%"
                left="45.5%"
                cardId={6}
                title="AND BEYOND"
                activeCardId={showCard}
                onClick={handleShowCard}
              />
              <RoadmapItem
                src={paintBrush}
                w="9%"
                top="67.5%"
                left="47.0%"
                title="PROGRESSIVE"
                cardId={7}
                activeCardId={showCard}
                onClick={handleShowCard}
              />
            </Box> */}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Roadmap2022V2;
