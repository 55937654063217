export enum GameState {
  ready,
  playing,
  prepare,
  pickATeamToPlay,
  submittingScore,
  subittingScoreError,
  done,
}

export enum GameDataState {
  gameOver = "gameOver",
  won = "won",
}

export interface GameData {
  state: GameDataState;
  score: string;
}

export enum TeamType {
  feet = "feet",
  eyes = "eyes",
  none = "none",
}

export interface GameScore {
  eyes: number;
  feet: number;
}