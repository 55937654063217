/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import * as React from "react";
import { Box, Center  } from "@chakra-ui/react";
// import ReactGA from "react-ga4";

import AppContainer from "./components/AppContainer";
// import Timeline from "./components/Timeline";
import About from "./components/About";
import Traits from "./components/Traits";
import Hero from "./components/Hero";
import Team from "./components/Team";
import SlotMachine from "./components/SlotMachine";
import FAQ from "./components/FAQ";
import Rarity from "./components/Rarity";
import ArcadeHomepage from './components/ArcadeHomepage';
import FoundersMessage from "./components/FoundersMessage";
import Roadmap from "./components/Roadmap2022V2";

import "./main.css";
import ProgressiveReveal from "./components/ProgressiveReveal";

const Spacer = () => <Box my={["2em", "2em", "5em"]}></Box>;

const LinkPlacer = ({ id, top = "-200" }: { id: string, top?: any }) => (
  <Box position="relative">
    <Box
      id={id}
      h="1"
      position="absolute"
      zIndex="10000000"
      top={top}
      left="0"
      width="100%"
      backgroundColor="transparent"
      opacity="0.0"
    />
  </Box>
);

// ReactGA.initialize("G-ZDRH2E4B5D");

export const App = () => (
  <>
    <a id="top" />
    <AppContainer>
      <Hero />
      <Spacer />
      <LinkPlacer id="about" top={["-10", "-100", "-100"]} />
      <About />
      <Spacer />
      <LinkPlacer id="traits" top={["-10", "-50", "-90"]} />
      <Traits />
      <LinkPlacer id="arcade" top={["-10", "-100", "-100"]} />
      <ArcadeHomepage />
      {/* <Center pb={10} mt={20}>
        <LinkPlacer id="roadmap" top={["-200", "-250", "-200"]} />
        <Heading alignContent={"center"} textAlign="center"><Box as="span" fontSize="4xl">Extra Stellar</Box> Roadmap</Heading>
        </Center>
        <Timeline />
      <Spacer /> */}
      <LinkPlacer id="progressive-reveal" top={["-10", "-50", "-90"]}/>
      <ProgressiveReveal />
      <LinkPlacer id="rarity" top={["-10", "-50", "-90"]}/>
      <Rarity />
      <LinkPlacer id="roadmap" top={["-10", "-50", "-90"]}/>
      <Roadmap />
      <LinkPlacer id="founders-message" top={["-10", "-50", "-90"]}/>
      <FoundersMessage />
      <LinkPlacer id="team" top={["-2", "-50", "-90"]} />
      <Team />
      <Center mb={0} mt={0}>
        <LinkPlacer id="slots-headers" top={["-100", "-50", "-90"]}/>
      </Center>
      <LinkPlacer id="slots"  top={["-100", "-50", "-90"]} />
      <SlotMachine />
      <LinkPlacer id="faq"  top={["-30", "-50", "-90"]} />
      <FAQ />
      </AppContainer>
  </>
);
