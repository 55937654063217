import React, { ReactNode, useEffect, useState } from "react";
import {
  Box,
  Container,
  Flex,
  HStack,
  Link,
  IconButton,
  useColorMode,
  useColorModeValue,
  Center,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  VStack,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Link as SmoothLink } from "react-scroll";
import ReactGA from "react-ga4";

import ColorModeSwitch from "../components/ColorModeSwitcher";
import Social from "./Social";
import "./app.css";
import { useLocation } from "react-router-dom";

const bgColor = {
  light: "rgba(255, 255, 255, 0.9)",
  dark: "rgba(30, 30, 30, 0.9)",
};

const color = {
  light: "black",
  dark: "white",
};

interface LinkItem {
  title: string;
  link: string;
  active: boolean;
}

const Links: LinkItem[] = [
  {
    title: "About",
    link: "/#about",
    active: true,
  },
  // {
  //   title: "Traits",
  //   link: "/#traits",
  //   active: true,
  // },
  {
    title: "Arcade",
    link: "/#arcade",
    active: true,
  },
  // {
  //   title: "Reveal",
  //   link: "/#progressive-reveal",
  //   active: true,
  // },
  {
    title: "Rarity",
    link: "/#rarity",
    active: true,
  },
  {
    title: "Roadmap",
    link: "/#roadmap",
    active: true,
  },
  {
    title: "Founder",
    link: "/#founders-message",
    active: true,
  },
  {
    title: "Team",
    link: "/#team",
    active: true,
  },
  {
    title: "FAQ",
    link: "/#faq",
    active: true,
  },
  {
    title: "Merch",
    link: "/merch",
    active: true,
  },
  {
    title: "Buy",
    link: "/paintshoppe/buy",
    active: true,
  },
  {
    title: "Paint",
    link: "/paintshoppe",
    active: true,
  },
];

const trackNav = (name: string) => {
  ReactGA.event("page_event", {
    category: "navigation event",
    action: "click",
    label: name,
  });
};

const NavLink = ({ link }: { link: LinkItem }) => {
  const location = useLocation();
  if (!link.active) {
    return (
      <Link
        px={2}
        py={1}
        rounded={"md"}
        color="gray.300"
        _active={{
          bg: "transparent",
        }}
        _hover={{
          bg: "transparent",
        }}
        _focus={{
          bg: "transparent",
        }}
        cursor="not-allowed"
        href="#"
      >
        {link.title}
      </Link>
    );
  }
  if (link.link.startsWith("/#") && location.pathname === "/") {
    return (
      <Link
        as={SmoothLink}
        px={2}
        py={1}
        rounded={"md"}
        to={link.link.replace("/#", "")}
        hashSpy
        spy
        smooth={true}
        duration={500}
        onClick={() => trackNav(link.link)}
        href={link.link}
      >
        {link.title}
      </Link>
    );
  }
  return (
    <Link
      px={2}
      py={1}
      rounded={"md"}
      onClick={() => trackNav(link.link)}
      href={link.link}
    >
      {link.title}
    </Link>
  );
};

const MenuLink = ({ link }: { link: any }) => {
  if (!link.active) {
    return (
      <MenuItem
        bg={"transparent"}
        _active={{
          bg: "transparent",
        }}
        _hover={{
          bg: "transparent",
        }}
        _focus={{
          bg: "transparent",
        }}
      >
        <Link
          px={2}
          py={1}
          rounded={"md"}
          color="gray.300"
          _active={{
            bg: "transparent",
          }}
          _hover={{
            bg: "transparent",
          }}
          _focus={{
            bg: "transparent",
          }}
          cursor="not-allowed"
          href="#"
        >
          {link.title}
        </Link>
      </MenuItem>
    );
  }
  return (
    <MenuItem
      bg={"transparent"}
      _active={{
        bg: "transparent",
      }}
      _hover={{
        bg: "transparent",
      }}
      _focus={{
        bg: "transparent",
      }}
      onKeyPress={() => {
        window.open(link.link, "_self");
        trackNav(link.link);
      }}
    >
      <Link href={link.link}>{link.title}</Link>
    </MenuItem>
  );
};

const AppContainer = ({ children }: { children: ReactNode }) => {
  const { colorMode } = useColorMode();

  const [smallHeader, setSmallHeader] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setSmallHeader(window.pageYOffset > 0);
    };
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", () => handleScroll);
      };
    }
  }, []);

  return (
    <Container maxW={{ xl: "100%" }} px={0}>
      <Flex
        as={"header"}
        pos="sticky"
        top="0"
        w={"100%"}
        px={[0, 0, 0]}
        py={2}
        mt={[0, 0, 0]}
        mb={[0, 0, 0]}
        minH={"60px"}
        boxShadow={"sm"}
        zIndex="999"
        justify={"center"}
        css={{
          backdropFilter: "saturate(180%) blur(5px)",
          backgroundColor: useColorModeValue(bgColor.light, bgColor.dark),
        }}
      >
        <Container maxW={{ xl: "1200px" }} px={0}>
          <Box
            px={4}
            w={"100%"}
            // backgroundColor="purple"
          >
            <Center>
              <Link
                href="/#top"
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Heading
                  fontSize={["3xl", "4xl", "4.5rem"]}
                  //size="4xl"
                  className={smallHeader ? "small" : ""}
                  textAlign="center"
                >
                  Feet and Eyes Guys
                </Heading>
              </Link>
            </Center>
            <Flex
              h={"auto"}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
            >
              {/* HAMBURGER MENU */}
              <Box display={{ lg: "none" }}>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<HamburgerIcon />}
                    variant="unstyled"
                    onClick={() => trackNav("hamburger")}
                  />
                  <MenuList>
                    {Links.map((link) => (
                      <MenuLink key={link.title} link={link} />
                    ))}
                  </MenuList>
                </Menu>
              </Box>
              {/* NAV MENU */}
              <VStack
                pl={[0, 0, "48px"]}
                spacing={0}
                alignItems={"center"}
                justifyContent={"center"}
                flex="1"
              >
                <HStack
                  as={"nav"}
                  spacing={4}
                  display={{ base: "none", lg: "flex" }}
                >
                  {Links.map((link) => (
                    <NavLink key={link.title} link={link} />
                  ))}
                </HStack>
                <Social />
              </VStack>
              <Flex alignItems={"flex-end"}>
                <ColorModeSwitch />
              </Flex>
            </Flex>
          </Box>
        </Container>
      </Flex>
      <Container
        as="main"
        maxW={{ xl: "100%" }}
        px={0}
        bg={bgColor[colorMode]}
        color={color[colorMode]}
        py={0}
        mb={[0, 0, 8]}
      >
        {children}
      </Container>
    </Container>
  );
};

export default AppContainer;
