import * as React from "react";
import {
  Avatar,
  Box,
  Container,
  Flex,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";

import feet from "../assets/images/feet_hobbit_edit.png";
import joint from "../assets/images/joint.png";
import eyes from "../assets/images/eye_clockwork_torture_edit.png";

import "./traits.css";

const Traits: React.FC<any> = (props) => {
  return (
    <Box as="section" w={"100%"} backgroundColor={"black"}>
      <Container maxW={{ xl: "1200px" }} px={0}>
        <Flex
          align="stretch"
          w={"100%"}
          backgroundColor={"black"}
          direction={{ base: "column", md: "row" }}
        >
          <Box p={5} width={"100%"} textColor={"white"}>
            <Heading textAlign={"center"} mb={8} mt="8">
              What's with this guy?
            </Heading>
            <VStack spacing={4} textAlign={"center"} pb="10">
              <Text>
                Each Guy is made up of up to 6 categories of attributes, some
                more rare than others. The combination of attributes will
                determine the rarity of your Guy.
              </Text>
              <Text>
                With over 300 Million possible combinations, your Guy is like a
                snowflake. One of a kind, and a little bit melty.
                <br />
                OK, they don’t melt, but they ARE unique!
              </Text>
              <Text>
                The EYES have it!
                <br />
                Eyes are the main focus of this collection.
                <br />
                They tell the tale and set the personality of each Guy.
                <br />
                Over 50 different eye designs were created to give your Guy a
                point of view.
              </Text>
              <Text>
                FEET first!
                <br />
                The other main component of the Feet and Eyes Guys is, well, the
                feet. Bare, bear or wearing boots, there are over 50 feet
                options for each guy to kick it into high gear!
              </Text>
              <Text>
                More things!
                <br />
                100’s more attributes surround each Guy!
                <br />
                Get excited to discover them all!
              </Text>
            </VStack>
          </Box>
          <Flex
            width={"100%"}
            p={5}
            backgroundColor={"black"}
            justifyContent={"center"}
            position={"relative"}
            direction={{ base: "column" }}
            display={{ base: "none", md: "flex" }}
          >
            <Avatar
              className={"guy-avatar"}
              src={feet}
              position={"absolute"}
              top={"10%"}
              left={"10%"}
              maxW={"35%"}
              width={"auto"}
              size={"10px"}
              backgroundColor={"white"}
              m={0}
              mx={"auto"}
              p={"40px"}
              alignContent={"center"}
              title="Example Feet"
              name="Example Eyes"
            />
            <Avatar
              className={"guy-avatar avatar-eyes"}
              src={eyes}
              position={"absolute"}
              top={"25%"}
              left={"44%"}
              maxW={"47%"}
              width={"auto"}
              size={"10px"}
              backgroundColor={"white"}
              m={0}
              p={"20px"}
              alignContent={"center"}
              title="Example Eyes"
              name="Example Eyes"
            />
            <Avatar
              className={"guy-avatar avatar-joint"}
              src={joint}
              position={"absolute"}
              top={"65%"}
              left={"25%"}
              maxW={"30%"}
              width={"auto"}
              size={"10px"}
              backgroundColor={"white"}
              m={0}
              p={"20px"}
              alignContent={"center"}
              title="Joint"
              name="Joint"
            />
          </Flex>
          <Flex
            width={"100%"}
            p={5}
            backgroundColor={"black"}
            justifyContent={"center"}
            position={"relative"}
            direction={{ base: "column" }}
            display={{ base: "flex", md: "none" }}
          >
            <Avatar
              className={"guy-avatar"}
              src={feet}
              maxW={"50%"}
              width={"auto"}
              size={"10px"}
              backgroundColor={"white"}
              my={10}
              mx={"auto"}
              p={"40px"}
              alignContent={"center"}
              title="Example Feet"
              name="Example Eyes"
            />
            <Avatar
              className={"guy-avatar avatar-eyes"}
              src={eyes}
              maxW={"50%"}
              width={"auto"}
              size={"10px"}
              backgroundColor={"white"}
              my={10}
              mx={"auto"}
              p={"20px"}
              alignContent={"center"}
              title="Example Eyes"
              name="Example Eyes"
            />
            <Avatar
              className={"guy-avatar avatar-joint"}
              src={joint}
              maxW={"50%"}
              width={"auto"}
              size={"10px"}
              backgroundColor={"white"}
              my={10}
              mx={"auto"}
              p={"20px"}
              alignContent={"center"}
              title="Joint"
              name="Joint"
            />
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default Traits;
