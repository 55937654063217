import * as React from "react";
import {
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import Social from "./Social";

function FooterModal({
  onClose,
  isOpen,
  title,
  BodyComponent,
}: {
  onClose: any;
  isOpen: boolean;
  title: string;
  BodyComponent: any;
}) {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent zIndex="999999">
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton
          _active={{
            border: "2px",
            borderColor: "black",
          }}
          _focus={{
            border: "2px",
            borderColor: "black",
          }}
        />
        <ModalBody zIndex="999999">
          <BodyComponent />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Got it!</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

const Disclaimer = () => (
  <Text>
    Feet and Eyes Guys is meant to be fun! This is a creative collectable NFT
    series designed to make you smile and connect you with likeminded members of
    our community. You should not think of Feet and Eyes Guys as an investment.
    Buy one because they make you happy and because you think they are adorable!{" "}
  </Text>
);

const Footer: React.FC<any> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex
      w={"100%"}
      mt={5}
      color={"current"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignContent={"center"}
      alignItems={"center"}
      flexWrap={"wrap"}
    >
      <Social />
      <Text mt={4}>&copy;2022, FEET AND EYES GUYS INC.</Text>
      <Flex
        color={"current"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignContent={"center"}
        flexWrap={"wrap"}
        textAlign={"center"}
      >
        <Link w="auto" onClick={onOpen} fontStyle="italic">
          NFA
        </Link>
        {/* <Link
        w="auto" 
                href="https://www.raraavis.app/feet-and-eyes-guys-tokens"
                target="_blank"
                rel="noopener"
                fontStyle="italic"
              >
                Rarity
              </Link> */}
        <Link w="auto" href="https://etherscan.io/address/0xbf6301236d71c0d49144b35241905794f5a10bb9" target="_blank" rel="noopener"  fontStyle="italic">
          Contract
        </Link>
        <Link w="auto" href="/terms" fontStyle="italic" rel="noopener">
          Terms and Conditions
        </Link>
      </Flex>
      <FooterModal
        isOpen={isOpen}
        onClose={onClose}
        title="NFA - Not Financial Advice"
        BodyComponent={Disclaimer}
      />
    </Flex>
  );
};

export default Footer;
