import * as React from "react";
import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";

const VideoModal = ({
  onClose,
  isOpen,
  BodyComponent,
}: {
  onClose: any;
  isOpen: boolean;
  BodyComponent: any;
}) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} size="6xl" isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton onClick={onClose} 
        
        _active={{
          border: "2px",
          borderColor: "black",
        }}
        _focus={{
          border: "2px",
          borderColor: "black",
        }}
        />
        <ModalBody my="10" mx="auto" >
          <Center maxW="1920px" mx="auto">
              {BodyComponent}
            </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}


export default VideoModal;
