import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnect from "@walletconnect/web3-provider";

const infuraId = 'ef32fbb481af492eae68c70e82b832a1';
const appName = 'Feet And Eyes Guys';

const providerOptions = {
 coinbasewallet: {
   package: CoinbaseWalletSDK, 
   options: {
     appName,
     infuraId,
   }
 },
 walletconnect: {
   package: WalletConnect, 
   options: {
    appName,
    infuraId, 
   }
 }
};

export default providerOptions;