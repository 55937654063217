import {
  // FC,
  forwardRef,
  memo,
  // useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Center,
  Text
} from "@chakra-ui/react";
// import { useCanvasContext } from "../../../hooks/useCanvas";
import SimonGame from "./entity/simon";
// import useResponsiveSize from "../../../hooks/useResponsiveSize";


const scale = 1;
const width = Math.floor(496 * scale);
const height = Math.floor(330 * scale); // for the controls

const Simon = memo(forwardRef((props: any, ref: any) => {
  const { onGameOver, onReady } = props;
  // const { context } = useCanvasContext();
  const [game, setGame] = useState<SimonGame | null>(null);
  const [message, setMessage] = useState<string>("");
  // const { width, height } = useResponsiveSize();
  useEffect(() => {
    if (!game) {
      console.log("mounting game engine");
      const simonGame = new SimonGame('simon-parent', width, height);
      // simonGame.init();
      simonGame.setOnGameOverHandler((data: any) => {
        if (onGameOver) {
          onGameOver(data);
        }
      });
      simonGame.setOnGameStatusHandler((text: string) => {
        setMessage(text);
      });
      setGame(simonGame);
    }
    return () => {
      if (game) {
        console.log("un-mounting game engine");
        game.close();
        setGame(null);
      }
    };
  }, [game, onGameOver, onReady]);

  useEffect(() => {
    if (game) {
      onReady(true);
    }
  }, [game, onReady]);

  useImperativeHandle(ref, () => ({
    startGame() {
      if (game) {
        game.start();
      } else {
        alert('There was an error starting the game.  Please report this to @FeetAndEyesGuys or let a mod know via Discord')
      }
    },
  }));

  return (
    <Center>
      <Text color={"white"} fontFamily={"Arcade Classic"} fontSize={"50px"} textAlign={"center"}>{message}</Text>
    </Center>
  )
}));

export default Simon;
