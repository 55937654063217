/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  VStack,
  Divider,
  ListItem,
  UnorderedList,
  Link,
} from "@chakra-ui/react";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import ky, { HTTPError } from "ky";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ReactGA from "react-ga4";

import providerOptions from "../../lib/web3/providerOptions";
import FroggerCanvas from "../Games/Frogger";
import {
  GameData,
  GameScore,
  GameState,
  TeamType,
} from "../Games/GameStructures";
import StartButton from "../Games/StartButton";
import CompleteButton from "../Games/CompleteButton";
// import TryAgainButton from "../Games/TryAgainButton";
import ErrorButton from "../Games/ErrorButton";
import SubmittingScore from "../Games/SubmittingScores";
import { requiredChainId } from "../../contract/contract";

import PickATeamButton from "../Games/PickATeamButton";
import LeaderBoard from "./Leaderboard";
import { useGameContext } from "./GameContext";

interface GameViewProps {
  onSubmitScore: (data: any) => Promise<any>;
  address?: string;
}

const trackStartGame = () => {
  ReactGA.event("page_event", {
    category: "interaction event",
    action: "click",
    label: "StartGameJumpyguy",
  });
};

const formBaseUrl = "";
//const formBaseUrl = "https://us-central1-feetandeyesguys-b3e2f.cloudfunctions.net/form";
// const formBaseUrl =
//   "http://localhost:5001/feetandeyesguys-b3e2f/us-central1/form";

const GameView = ({ onSubmitScore, address }: GameViewProps) => {
  const froggerRef = useRef<any>();
  const [showGame, setShowGame] = useState<boolean>(false);
  const [gameState, setGameState] = useState<GameState>(GameState.ready);
  const [gameData, setGameData] = useState<GameData | null>(null);
  const {team, setTeam} = useGameContext();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [submitErrorCode, setSubmitErrorCode] = useState<number>(0);
  const [revealState, setRevealState] = useState<any>(null);

  const handleOnGameOver = useCallback(
    (gameData: any) => {
      setModalOpen(false);
      setRevealState(null);
      setGameData(gameData);
      setGameState(GameState.submittingScore);
      setTimeout(() => {
        onSubmitScore({
          ...gameData,
          score: String(gameData.score),
          team: team,
          gameName: "jumpyguy",
          stage: String(gameData.timesAtGoal),
          many_bothans_have_died_to_bring_us_this_information: true,
        }).then((res: any) => {
          console.log("res for score submit is:", res);
          if (res && res.success && res.status === "yes") {
            setGameState(GameState.done);
            if (res.code) {
              setRevealState(res);
              if (res.code === "revealed") {
                console.debug(`revealed ${res.fileList.length} guys`);
                // alert(
                //   `[Temp Message] New traits have been revealed on ${res.fileList.length} GUYS.  Check the meta data feed for the updated image paths`
                // );
              } else if (res.code === "no-guys-can-reveal") {
                console.debug(`all guys are up-to-date`);
                // alert(
                //   `[Temp Message] Your GUYS are all up-to-date on their reveals. Check back tomorrow to reveal more traits`
                // );
              }
            }
          } else {
            if (res && res.code) {
              setSubmitErrorCode(res.code);
            }
            setGameState(GameState.subittingScoreError);
          }
        });
      }, 10);
    },
    [setGameData, setGameState, onSubmitScore, team]
  );

  useEffect(() => {
    if (
      !showGame &&
      (gameState === GameState.playing || gameState === GameState.prepare)
    ) {
      setModalOpen(true);
      setShowGame(true);
      trackStartGame();
    }
  }, [gameState, showGame]);

  const handleOnModalClose = () => {
    setModalOpen(false);
    setGameData(null);
    setGameState(GameState.ready);
  };

  const handleOnComplete = () => {
    setGameData(null);
    setModalOpen(false);
    setSubmitErrorCode(0);
    setGameState(GameState.ready);
  };

  const handleOnPickATeam = (team: TeamType) => {
    setTeam(team);
    if (gameState === GameState.pickATeamToPlay) {
      setGameState(GameState.prepare);
      setModalOpen(true);
      trackStartGame();
    }
  };

  const handleOnStart = () => {
    if (team && team !== TeamType.none) {
      setGameState(GameState.prepare);
      setModalOpen(true);
      trackStartGame();
    } else {
      setGameState(GameState.pickATeamToPlay);
    }
  };

  const onReady = useCallback(
    (ready: boolean) => {
      // console.log("on ready called");
      if (ready) {
        // console.log(
        //   `game state is now ${gameState} prepare is: ${GameState.playing}, ref is: ${froggerRef.current}`
        // );
        if (gameState === GameState.prepare) {
          if (froggerRef.current) {
            if (froggerRef.current.startGame()) {
              // console.log("started game.  updating state");
              // setGameState(GameState.playing);
            } else {
              console.log("error starting Jumpy Guy :(");
              setGameState(GameState.ready);
            }
          } else {
            console.log(
              "no jumpyguyref (dom messed?), setting to ready game state"
            );
            setGameState(GameState.ready);
          }
        }
      }
    },
    [gameState]
  );

  return (
    <>
      {gameState === GameState.ready && <StartButton onStart={handleOnStart} />}
      {showGame && (
        <Modal
          isOpen={modalOpen}
          onClose={handleOnModalClose}
          isCentered
          size={"full"}
        >
          <ModalOverlay
            bg="rgba(0,0,0,0.8)"
            backdropFilter="auto"
            backdropInvert="0"
            backdropBlur="2px"
            m="0"
            height={"100vh"}
          />
          <ModalContent bg="#ffffff" px="0" m="0">
            <ModalHeader pb="20px" />
            <ModalCloseButton
              outline={"none"}
              _active={{
                border: "2px",
                borderColor: "black",
              }}
              _focus={{
                border: "2px",
                borderColor: "black",
              }}
            />
            <ModalBody px="0" m="0" backgroundColor={"black"}>
              <FroggerCanvas
                onGameOver={handleOnGameOver}
                ref={froggerRef}
                onReady={onReady}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
      {gameState === GameState.done && (
        <CompleteButton
          gameData={gameData}
          onComplete={handleOnComplete}
          revealData={revealState}
          address={address}
        />
      )}
      {gameState === GameState.pickATeamToPlay && (
        <PickATeamButton onComplete={handleOnPickATeam} />
      )}
      {gameState === GameState.subittingScoreError && (
        <ErrorButton onComplete={handleOnComplete} code={submitErrorCode} />
      )}
      {gameState === GameState.submittingScore && <SubmittingScore />}
    </>
  );
};

const GuyList = ({ guys }: { guys: any }) => {
  if (!guys) {
    return <Text>No Guys / Loading</Text>;
  }
  console.log(guys);
  const list = guys.tokenIds.map((t: number) => {
    const guy = guys.tokenMeta[t];
    return (
      <>
        <Box textAlign={"left"} key={guy.name}>
          <Text>{guy.name}</Text>
          <UnorderedList>
            {guy.attributes.map((a: any) => (
              <ListItem>
                {a.trait_type}:{a.value}
              </ListItem>
            ))}
          </UnorderedList>
          <Link
            href={guy.image}
            target="_blank"
            width={"auto"}
            display={"inline-block"}
          >
            <Image src={guy.image} maxH="200px" />
          </Link>
          <Divider />
        </Box>
      </>
    );
  });
  return (
    <>
      <Text textColor={"#ff5555"}>
        TESTNG ONLY: THIS LIST IS FOR REVIEWING GUYS FROM THE TESTNET CONTRACT
        WITH PROGRESSIVE REVEAL.
      </Text>
      <Text textColor={"#ff5555"}>
        USE THE SAME WALLET YOU USED TO MINT WITH ON TESTNET TO SEE YOUR GUYS.
        THESE CHANGES WILL NOT BE REFLECTED ON OPENSEA. *DO NOT SHARE THESE
        IMAGES*
      </Text>

      {list}
    </>
  );
};

const Arcade: React.FC<any> = (props) => {
  const web3Modal = useMemo(
    () =>
      new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: true, // optional
        providerOptions, // required
      }),
    []
  );
  const [provider, setProvider] = useState<any>();
  const [library, setLibrary] = useState<
    ethers.providers.Web3Provider | undefined
  >();
  const [account, setAccount] = useState<string | undefined>();
  const { setTeam } = useGameContext();
  const [showPickTeam, setShowPickTeam] = useState<boolean>(false);
  const [network, setNetwork] = useState<
    ethers.providers.Network | undefined
  >();
  // const [signature, setSignature] = useState("");
  const [error, setError] = useState("");
  const [chainId, setChainId] = useState<number | undefined>();
  const [loadScore, setLoadScore] = useState<boolean>(true);
  const [currentScore, setCurrentScore] = useState<GameScore>({
    eyes: 0,
    feet: 0,
  });
  // const [message, setMessage] = useState("");
  // const [signedMessage, setSignedMessage] = useState("");
  // const [verified, setVerified] = useState();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [viewGuys, setViewGuys] = useState<boolean>(false);
  const [myGuys, setMyGuys] = useState<any>(null);

  const connectWallet = useCallback(async () => {
    try {
      const provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(provider);
      const accounts = await library.listAccounts();
      const network = await library.getNetwork();
      const chainId = await network.chainId;
      if (chainId !== requiredChainId) {
        setProvider(null);
        setAccount(undefined);
        setLibrary(undefined);
        setNetwork(undefined);
        setChainId(undefined);
        alert(
          "Please make sure you are connected to the Etherium Mainnet network and try again."
        );
        return;
      }
      setProvider(provider);
      setLibrary(library);
      if (accounts) setAccount(accounts[0]);
      setNetwork(network);
      setChainId(chainId);
    } catch (error) {
      console.error(error);
    }
  }, [web3Modal]);

  // try and reconnect if the page is reloaded
  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connectWallet();
    }
  }, [web3Modal.cachedProvider, connectWallet]);

  const refreshState = useCallback(() => {
    setAccount(undefined);
    setChainId(undefined);
    setNetwork(undefined);
    // setMessage("");
    // setSignature("");
    // setVerified(undefined);
  }, [
    setAccount,
    setChainId,
    setNetwork,
    // setMessage,
    // setSignature,
    // setVerified,
  ]);

  const disconnect = useCallback(() => {
    web3Modal.clearCachedProvider();
    refreshState();
  }, [web3Modal, refreshState]);

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts: string[]) => {
        console.log("accountsChanged", accounts);
        if (accounts) setAccount(accounts[0]);
      };

      const handleChainChanged = (_hexChainId: number) => {
        setChainId(_hexChainId);
      };

      const handleDisconnect = () => {
        console.log("disconnect", error);
        disconnect();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider, disconnect, error]);

  const handleShowPickTeam = useCallback(() => {
    setShowPickTeam(true);
  }, [setShowPickTeam]);

  const handlePickTeam = useCallback(
    (team: TeamType) => {
      setTeam(team);
      setShowPickTeam(false);
    },
    [setShowPickTeam, setTeam]
  );

  useEffect(() => {
    if (loadScore) {
      setLoadScore(false);
      const fetchScore = async () => {
        try {
          // const formBaseUrl =
          //   // "http://localhost:5001/feetandeyesguys-b3e2f/us-central1/form";
          //   // const formBaseUrl =
          //   "https://us-central1-feetandeyesguys-b3e2f.cloudfunctions.net/form";
          /// const formBaseUrl = 'http://localhost:5001/feetandeyesguys-b3e2f/us-central1/form'; // for testing.  blank for production deploy
          const res: any = await ky
            .post(`${formBaseUrl}/games/todder/getScore`, {
              json: {
                // wallet_address: (await library.listAccounts())[0],
              },
            })
            .json();
          return {
            success: true,
            ...res,
          };
        } catch (e) {
          if (e instanceof HTTPError) {
            try {
              const res = await e.response.json();
              console.error(res);
              return {
                success: true,
                ...res,
              };
            } catch (e) {
              console.error("unable to parse response.  maybe a 404?");
            }
          } else {
            console.error(e);
          }
          return {
            success: false,
            error: e,
          };
        }
      };

      fetchScore().then((res) => {
        if (res.success) {
          console.log("score is", {
            ...res,
          });
          setCurrentScore({
            eyes: res.eyes,
            feet: res.feet,
          });
        } else {
          console.error("error fetching score", res);
        }
      });
    }
  }, [loadScore]);

  const handleSubmitScore = useCallback(
    async (gameData: any) => {
      try {
        if (!executeRecaptcha) {
          console.error("Execute recaptcha not yet available");
          alert(
            "There was an internal error with the score submission (error code 1).  Please try reloading the page."
          );
          return;
        }
        if (!library) {
          console.error("no library object");
          alert(
            "There was an internal error with the score submission (error code 2).  Please try reloading the page and re-conneting your wallet."
          );
          return;
        }
        // const formBaseUrl =
        //   "http://localhost:5001/feetandeyesguys-b3e2f/us-central1/form";
        // const formBaseUrl =
        //   "https://us-central1-feetandeyesguys-b3e2f.cloudfunctions.net/form";
        /// const formBaseUrl = 'http://localhost:5001/feetandeyesguys-b3e2f/us-central1/form'; // for testing.  blank for production deploy
        // console.log(token);
        // "/public-allowlist",
        const res: any = await ky
          .post(`${formBaseUrl}/games/toedder/progressiveScore`, {
            timeout: 60 * 1000,
            json: {
              ...gameData,
              wallet_address: (await library.listAccounts())[0],
              token: await executeRecaptcha(
                `${(await library.listAccounts())[0]}_toedder_${gameData.stage}`
              ),
            },
          })
          .json();
        setLoadScore(true);
        return {
          success: true,
          ...res,
        };
      } catch (e) {
        if (e instanceof HTTPError) {
          try {
            const res = await e.response.json();
            console.error("error", res);
            let code = "001";
            if (res.code) {
              code = res.code;
            }
            // alert(
            //   `There was an error with the score submission.  Grab a screenshot and send a DM to @FeetAndEyesGuys\n\nCode: ${code}`
            // );
            return {
              success: true,
              ...res,
            };
          } catch (e) {
            console.error("unable to parse response.  maybe a 404?");
          }
        } else {
          console.error(e);
          alert(
            "There was an error with the score submission.  Grab a screenshot and send a DM to @FeetAndEyesGuys"
          );
        }
        return {
          success: false,
          error: e,
        };
      }
    },
    [executeRecaptcha, library]
  );

  const hideGuys = () => {
    setViewGuys(false);
    setMyGuys(null);
  };

  const showGuys = useCallback(async () => {
    setViewGuys(true);
    setMyGuys(null);
    try {
      if (!library) {
        console.error("no library object");
        alert(
          "There was an internal error with the score submission (error code 2).  Please try reloading the page and re-conneting your wallet."
        );
        return;
      }
      // const formBaseUrl =
      //   "http://localhost:5001/feetandeyesguys-b3e2f/us-central1/form";
      // const formBaseUrl =
      //   "https://us-central1-feetandeyesguys-b3e2f.cloudfunctions.net/form";
      /// const formBaseUrl = 'http://localhost:5001/feetandeyesguys-b3e2f/us-central1/form'; // for testing.  blank for production deploy
      // console.log(token);
      // "/public-allowlist",
      const res: any = await ky
        .post(`${formBaseUrl}/pr-getMyGuys`, {
          timeout: 60 * 1000,
          json: {
            wallet_address: (await library.listAccounts())[0],
          },
        })
        .json();
      if (res.status === "yes") {
        console.log(res);
        setMyGuys(res);
      } else {
        console.log(res);
        alert("Error loading my guy tokens.  Please report this.");
      }
    } catch (e) {
      if (e instanceof HTTPError) {
        try {
          const res = await e.response.json();
          console.error("error", res);
          alert("2 error getting my guys.  please report");
        } catch (e) {
          console.error("unable to parse response.  maybe a 404?");
        }
      } else {
        console.error(e);
        alert("There was an loading my guys");
      }
    }
  }, [library]);

  return (
    <Box as="section" w={"100%"} backgroundColor={"black"}>
      <Container maxW={{ xl: "1200px" }} px={0}>
        {viewGuys ? (
          <Box p={5} width={"100%"} textColor={"white"} textAlign={"center"}>
            <Heading>View Guys</Heading>
            <Box>
              <Button
                variant={"invertTextButton"}
                onClick={hideGuys}
                wordBreak="break-word"
                whiteSpace={"normal"}
              >
                Hide Reveal Guys
              </Button>
            </Box>
            <GuyList guys={myGuys} />
          </Box>
        ) : (
          <>
            <Box>
              <LeaderBoard
                score={currentScore}
                onPickTeam={handleShowPickTeam}
                isConnected={!!account}
              />
            </Box>
            <Flex
              align="stretch"
              w={"100%"}
              backgroundColor={"black"}
              direction={{ base: "column", md: "row" }}
            >
              <Box
                p={5}
                width={"100%"}
                textColor={"white"}
                textAlign={"center"}
              >
                <Heading textAlign={"center"} mb={8} mt="8">
                  JUMPY GUY
                </Heading>
                <Text>
                  The object of JUMPY GUY is to get your GUY across the road and
                  over the river into one of their HOMES. Avoid cars and jump on
                  logs to get the GUY HOME!
                </Text>
                <Text mt="5">
                  DESKTOP: Use the arrow keys to control JUMPY GUY across the
                  road and river.
                </Text>
                <Text mt="5">
                  MOBILE: Tap the arrow buttons to control JUMPY GUY across the
                  road and river.
                </Text>

                <Text mt="5">
                  TO REVEAL: Navigate at least 3 GUYS to their homes to reveal
                  your next trait!
                </Text>
                <Text mt="5">
                  You can play as much and as often as you like, but only ONE
                  TRAIT will be revealed PER DAY! (Days start at midnight Pacific Time)
                </Text>
                <VStack spacing={4} textAlign={"center"} pb="10" mt="10">
                  {!account ? (
                    <div>
                      <Button
                        variant={"invertTextButton"}
                        onClick={connectWallet}
                        wordBreak="break-word"
                        whiteSpace={"normal"}
                      >
                        Connect Wallet to begin
                      </Button>
                    </div>
                  ) : (
                    <>
                      <Box
                        position={"relative"}
                        className="canvas"
                        height="calc(80vw * (660/480))"
                        maxHeight={"660px"}
                        backgroundColor={"black"}
                      >
                        <GameView onSubmitScore={handleSubmitScore} address={account} />
                        {showPickTeam && (
                          <PickATeamButton onComplete={handlePickTeam} />
                        )}
                      </Box>
                      <Box>
                        <Button
                          variant={"invertTextButton"}
                          onClick={disconnect}
                          wordBreak="break-word"
                          whiteSpace={"normal"}
                          height={"auto"}
                        >
                          Disconnect Wallet
                        </Button>
                      </Box>
                      {/* <Box>
                        <Button
                          variant={"invertTextButton"}
                          onClick={showGuys}
                          wordBreak="break-word"
                          height={"auto"}
                          whiteSpace={"normal"}
                        >
                          [Test] View Reveal Guys
                        </Button>
                      </Box> */}
                    </>
                  )}
                </VStack>
              </Box>
            </Flex>
          </>
        )}
      </Container>
    </Box>
  );
};

export default Arcade;
