import * as React from "react";
// import { useState } from "react";
import { Box, useBreakpointValue } from "@chakra-ui/react";
// import Marquee from "react-fast-marquee";
// import { motion } from "framer-motion";

// import guy1 from "../assets/images/guys/marquee/1.png";
// import guy2 from "../assets/images/guys/marquee/2.png";
// import guy3 from "../assets/images/guys/marquee/3.png";
// import guy4 from "../assets/images/guys/marquee/4.png";
// import guy5 from "../assets/images/guys/marquee/5.png";
// import guy6 from "../assets/images/guys/marquee/6.png";
// import guy7 from "../assets/images/guys/marquee/7.png";

// import thingyImage from "../assets/images/thatthinginthatplace.png";

import "./hero.css";
import Carousel from "./Carousel";

// const items = [
//   {
//     guy: guy1,
//     aspect: 229 / 315,
//   },
//   {
//     guy: guy4,
//     aspect: 451 / 630,
//   },
//   {
//     guy: guy2,
//     aspect: 409 / 630,
//   },
//   {
//     guy: guy3,
//     aspect: 353 / 630,
//   },
//   {
//     guy: guy5,
//     aspect: 11 / 15,
//   },
//   {
//     guy: guy6,
//     aspect: 32 / 45,
//   },
//   {
//     guy: guy7,
//     aspect: 50 / 63,
//   },
// ];

// const margin = ["0px", "0px", "0px"];

// const GuyList = ({
//   height,
//   showThingy,
// }: {
//   height: number;
//   showThingy: () => void;
// }) => (
//   <>
//     {items.map((item, index) => {
//       // if (index === 3) {
//       //   return (
//       //     <Box pos={"relative"}>
//       //       <Box
//       //         bgColor={"transparent"}
//       //         h="50px"
//       //         w="50px"
//       //         pos={"absolute"}
//       //         top={["35px", "35px", "50px", "80px"]}
//       //         left={["70px", "75px", "100px", "165px"]}
//       //         zIndex={"1000"}
//       //         onClick={() => {
//       //           showThingy();
//       //         }}
//       //       ></Box>
//       //       <Image
//       //         key={index}
//       //         height={`${height}px`}
//       //         width={`${height * item.aspect}px`}
//       //         mx={margin}
//       //         src={item.guy}
//       //         alt={`Example Guy ${index + 1}`}
//       //       />
//       //     </Box>
//       //   );
//       // }
//       return (
//         <Image
//           key={index}
//           height={`${height}px`}
//           width={`${height * item.aspect}px`}
//           mx={margin}
//           src={item.guy}
//           alt={`Example Guy ${index + 1}`}
//         />
//       );
//     })}
//   </>
// );

const Hero: React.FC<any> = () => {
  const variant = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
  });
  let height = 300;
  let maxHeight = "530px";
  let paddingY = 20;
  switch (variant) {
    case "base": {
      height = 256;
      maxHeight = "256px";
      paddingY = 0;
      break;
    }
    case "sm": {
      height = 287;
      maxHeight = "287px";
      paddingY = 0;
      break;
    }
    case "md": {
      height = 359;
      maxHeight = "359px";
      paddingY = 0;
      break;
    }
    case "lg": {
      height = 530;
      maxHeight = "530px";
      paddingY = 0;
      break;
    }
  }

  return (
    <Box
      backgroundColor={"black"}
      py={paddingY}
      overflow={"hidden"}
      maxHeight={maxHeight}
    >
      <Carousel height={height} />
    </Box>
  );
};

export default Hero;
