import * as React from "react";
import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";

import founder from "../assets/images/founder.jpg";

const Rarity: React.FC<any> = (props) => {
  return (
    <Container maxW={{ xl: "1200px" }} px={0} py="40px" as="section">
      <Flex
        align="stretch"
        w={"100%"}
        direction={{ base: "column-reverse", md: "row" }}
        px={"1rem"}
      >
        <Flex
          width={"100%"}
          // p={5}
          // shadow="md"
          // borderWidth="1px"
          // borderRadius="40px"
          // backgroundColor={"black"}
          justifyContent={"center"}
        >
          <Center flexDirection="column">
            <Image src={founder} maxW={500} w={"100%"} alignContent={"center"} alt="Caitlin Cronenberg" />
          </Center>
        </Flex>
        <Box p={5} width={"100%"}>
          <Heading textAlign={"center"} mb={8}>
            Founder's Message
          </Heading>
          <VStack spacing={4} textAlign={"center"}>
            <Text>
              When I first heard about the NFT space I was intrigued, of course.
              At that time, photography wasn’t booming yet, and PFP projects
              were making headlines left and right. For me, the best way to
              learn about something new is to jump in with two feet, so I set
              out to create a PFP project of my own. I teamed up with other
              talented folks with a similar desire to learn from the inside out,
              and we got to work.
            </Text>
            <Text pt="5">
              A few months later I was introduced to some incredible people in
              the NFT photography space, and I started getting excited about
              sharing my work. What I came to realize was that I was joining the
              most supportive community of artists and collectors I could
              imagine. Total strangers were taking phone calls with me, giving
              me their time, teaching me what they knew and introducing me to
              more people. I was blown away.
            </Text>
            <Text pt="5">
              But that is what Web3 is all about. Community. Helping others.
              Building something together. It became clear that Feet And Eyes
              Guys needed to represent all the best things about Web3. So we
              made it our mission to bring new artists into the space, teach
              them, give them our time. To take the kindness and generosity that
              was shown to me, and pay it forward.
            </Text>
          </VStack>
        </Box>
      </Flex>
    </Container>
  );
};

export default Rarity;
