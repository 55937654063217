import React, { useCallback, useState } from 'react';
import {
  Button,
  Box,
  Heading,
  Image,
  Select,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';

import VariantSelector from './VariantSelector';

function Product(props: any) {

  const { product, paymentSettings } = props;
  const bg = useColorModeValue("black", "grey.100");
  const color = useColorModeValue("white", "gray.100");
  let defaultOptionValues: any = {};

  product.options.forEach((selector: any) => {
    defaultOptionValues[selector.name] = selector.values[0];
  });

  const [selectedOptions] = useState(defaultOptionValues);

  const [variantImage, setVariantImage] = useState(product.images.edges[0].node);
  const [variant, setVariant] = useState(product.variants.edges[0].node);
  const [variantQuantity, setVariantQuantity] = useState(1);

  const buttonTitle = useCallback(() => {
    if (product.tags.includes('pre-order')) {
      return 'Pre-order';
    }
    return 'Add to cart';
  }, [product]);

  const handleOptionChange = (event: any) => {
    const target = event.target
    selectedOptions[target.name] = target.value;

    const selectedVariant = product.variants.edges.find((variant: any) => {
      return variant.node.selectedOptions.every((selectedOption: any) => {
        return selectedOptions[selectedOption.name] === selectedOption.value;
      });
    }).node;

    setVariant(selectedVariant);
    setVariantImage(selectedVariant.image);
  }

  const handleQuantityChange = (event: any) => {
    setVariantQuantity(event.target.value);
  }

  const validVariants = product.options.filter((option: any) => {
    return option.name !== 'Title';
  });

  let variantSelectors = validVariants.map((option: any) => {
    return (
      <VariantSelector
        handleOptionChange={handleOptionChange}
        key={option.id.toString()}
        option={option}
      />
    );
  });

  return (
    <Box 
      flex="0 1 45%"
      marginX="2%"
      mb={["60px","50px","3%"]}
      minW={["100%", "100%", "auto"]}
      paddingX={["20px","20px","0"]}
      
    >
      <VStack alignContent={["flex-start"]} alignItems="stretch">

        {product.images.edges.length && (
          <Image
            src={variantImage.src} 
            alt={`${product.title} product shot`} />)
        }
        <Heading fontSize="2xl">{product.title}</Heading>
        <Text minH={["auto", "9rem", "9rem"]} dangerouslySetInnerHTML={{__html: product.descriptionHtml}} />
        <Text color="#aaaaaa">${variant.price} {paymentSettings.currencyCode}</Text>
        {variantSelectors}
        <Text>Quantity:</Text>
        <Select placeholder="Select quantity" defaultValue={variantQuantity} onChange={handleQuantityChange}>
          {Array.from(Array(10).keys()).map(key => {
            return (<option key={key} value={key + 1}>{key + 1}</option>)
          })}
        </Select>
        <Button variant="solid" color={color} backgroundColor={bg} onClick={() => props.addVariantToCart(variant.id, variantQuantity)}>{buttonTitle()}</Button>

      </VStack>
    </Box>
  );
}

export default Product;
