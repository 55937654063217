import * as React from "react";
import {
  Box,
  Center,
  Image as ChakraImage,
  ListItem,
  Text,
  UnorderedList,
  ListProps,
  Heading,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useInterval, useWindowSize } from "react-use";
import ReactGA from "react-ga4";
import useSound from "use-sound";

import slotMachine from "../assets/images/slot_machine.png";
import arm2 from "../assets/images/ARM_frame_arm_2.png";
import arm3 from "../assets/images/ARM_frame_arm_3.png";
import arm4 from "../assets/images/ARM_frame_arm_4.png";
import arm5 from "../assets/images/ARM_frame_arm_5.png";
import arm6 from "../assets/images/ARM_frame_arm_6.png";

import slotGradient from "../assets/images/slot_gradient.png";

import "./slotmachine.css";

const spinningSfx = "/sounds/rotate.mp3";
const leverSfx = "/sounds/lever.mp3";
const winSfx = "/sounds/win2.mp3";

const eyes = [
  "/images/slot_machine/eye_big_bird_edit.png",
  "/images/slot_machine/eye_blackeye_edit.png",
  "/images/slot_machine/eye_clockwork_edit.png",
  "/images/slot_machine/eye_dagger_edit.png",
  "/images/slot_machine/eye_goat_edit.png",
  "/images/slot_machine/eye_homer_edit.png",
  "/images/slot_machine/eye_patch_kill_bill_witheyes.png",
  "/images/slot_machine/eye_snake_edit_2.png",
  "/images/slot_machine/eye_zombie_edit.png",
  "/images/slot_machine/glasses_nerd_edit_witheyes.png",
  "/images/slot_machine/ZOMBIE_EYES.PNG",
  "/images/slot_machine/SILENCE_EYES.PNG",
  "/images/slot_machine/STAR_EYES.PNG",
  "/images/slot_machine/SURPRISED_EYES.PNG",
  "/images/slot_machine/TORTURE_EYES.PNG",
  "/images/slot_machine/DOLLARSIGN_EYES.PNG",
  "/images/slot_machine/EVERGREEN_EYES.PNG",
  "/images/slot_machine/3D_GLASSES.PNG",
  "/images/slot_machine/ANGRY_EYES.PNG",
  "/images/slot_machine/STONED_EYES.PNG",
];
const feet = [
  "/images/slot_machine/ballet_slippers.png",
  "/images/slot_machine/crocs_and_socks.png",
  "/images/slot_machine/feet_beast_edit.png",
  "/images/slot_machine/feet_hobbit_edit.png",
  "/images/slot_machine/feet_sneakers_edit.png",
  "/images/slot_machine/flip_flops.png",
  "/images/slot_machine/legs_das_frank_edit.png",
  "/images/slot_machine/legs_edit.png",
  "/images/slot_machine/COWBOYBOOT_FEET.PNG",
  "/images/slot_machine/FLIPPER_FEET.PNG",
  "/images/slot_machine/HIGHTOP_FEET.PNG",
  "/images/slot_machine/HOT_ROD_LEGS.png",
  "/images/slot_machine/MUMMY_FEET.PNG",
  "/images/slot_machine/PRIME_FEET.PNG",
  "/images/slot_machine/SNOWSHOE_FEET.PNG",
  "/images/slot_machine/STRAIGHT_JACKET_FEET.PNG",
  "/images/slot_machine/WATCH_FEET.PNG",
  "/images/slot_machine/YEEZY_FEET.PNG",
];
const minLength = 3;

const eyesHorizontalMargin = -20 * 2;

const handleImages = [
  arm2,
  arm3,
  arm4,
  arm5,
  arm6,
  //  arm6,
  arm5,
  //  arm4,
  arm3,
  arm2,
];

const MotionList = motion<ListProps>(UnorderedList);

const SlotMachine: React.FC<any> = (props) => {
  const [tracks, setTracks] = React.useState([
    [
      "/images/slot_machine/eye_big_bird_edit.png",
      "/images/slot_machine/eye_blackeye_edit.png",
      "/images/slot_machine/eye_clockwork_edit.png",
    ],
    [
      "/images/slot_machine/ballet_slippers.png",
      "/images/slot_machine/crocs_and_socks.png",
      "/images/slot_machine/feet_beast_edit.png",
    ],
  ]);
  // console.log("tracks", tracks);
  const [activeImage, setActiveImage] = React.useState(0);
  const [rolling, setRolling] = React.useState(false);
  const [shouldCycle, setShouldCycle] = React.useState(false);
  const [hasInited, setHasInited] = React.useState(false);
  const [reset, setReset] = React.useState(false);
  const [shouldSpin, setShouldSpin] = React.useState(false);
  const [result, setResult] = React.useState({ top: "", bottom: "" });
  const [playSpin, { stop: stopSpin, sound }] = useSound(spinningSfx, {
    volume: 0.5,
    playbackRate: 0.5,
  });
  const [playLever] = useSound(leverSfx, { volume: 0.3, playbackRate: 1.2 });
  const [playWin] = useSound(winSfx, { volume: 0.35, playbackRate: 1.0 });
  const slotRef1 = React.useRef(null);
  const slotRef2 = React.useRef(null);
  const slotRefs = React.useMemo(() => {
    return [slotRef1, slotRef2];
  }, [slotRef1, slotRef2]);
  const [transitionSpeeds, setTransitionSpeeds] = React.useState({
    top: 3000,
    bottom: 5000,
  });
  //create pre-load for images
  React.useEffect(() => {
    handleImages.forEach((imgSrc) => {
      const img = new Image();
      img.src = imgSrc;
    });
  });
  useInterval(
    () => {
      // console.log(
      //   `activeImage: ${activeImage}, handleImages.length:${handleImages.length}`
      // );
      cycleImage();
    },
    shouldCycle ? 75 : null
  );
  React.useEffect(() => {
    if (shouldSpin && !rolling && !reset && tracks[0].length > minLength) {
      // console.log(transitionSpeeds);
      //setShouldCycle(true);
      slotRefs.forEach((slot, i) => {
        if (slot !== null) {
          const current: any = slot.current;
          if (current) {
            let transitionMS = 1000;
            const width = parseInt(getComputedStyle(current.children[0]).width);
            if (i === 0) {
              // top track
              current.style.right =
                (width + eyesHorizontalMargin) * tracks[i].length + "px";
              transitionMS = transitionSpeeds.top;
            } else {
              current.style.left =
                (width + eyesHorizontalMargin) * tracks[i].length + "px";
              transitionMS = transitionSpeeds.bottom;
            }
            const bezierRange = Math.random() * 0.1;
            const positiveRange = Math.random() >= 0.5;
            const bezierP3 = positiveRange
              ? 1.0 + bezierRange
              : 1.0 - bezierRange;
            current.style.transition = `all ${transitionMS}ms cubic-bezier(.17,.67,.66, ${bezierP3})`;
          }
        }
      });
      setResult({ top: "", bottom: "" });
      //      console.log("setting should cycle true");
      setRolling(true);
      setShouldSpin(false);
      trackSpin();
      setTimeout(() => {
        //        console.log("completed spin");
        setRolling(false);
        stopSpin();
        //        console.log("completed spin (2)");
        setResult({
          top: tracks[0][tracks[0].length - 2],
          bottom: tracks[1][1],
        });
        setTimeout(() => {
          playWin();
        }, 20);
      }, Math.max(transitionSpeeds.bottom, transitionSpeeds.top));
    } else if (!rolling && !shouldSpin && (reset || !hasInited)) {
      slotRefs.forEach((slot, i) => {
        if (slot !== null) {
          const current: any = slot.current;
          if (current) {
            current.style.transition = "none";
            const width = parseInt(getComputedStyle(current.children[0]).width);
            if (i === 0) {
              // top track
              current.style.right =
                (width + eyesHorizontalMargin) * minLength + "px";
            } else {
              current.style.left =
                (width + eyesHorizontalMargin) * minLength + "px";
            }
          }
        }
      });
      if (hasInited) {
        setReset(false);
        spinTracks();
      } else {
        setHasInited(true);
      }
    }
  }, [
    tracks,
    slotRefs,
    reset,
    setReset,
    transitionSpeeds,
    hasInited,
    setHasInited,
    activeImage,
    setShouldCycle,
    rolling,
    setRolling,
    setShouldSpin,
    shouldSpin,
    stopSpin,
    playWin,
  ]);

  const windowSize = useWindowSize();

  const cycleImage = () => {
    if (activeImage < handleImages.length - 1) {
      let newValue = activeImage + 1;
      // if (activeImage === 0) {
      //   newValue += 1; // advance past first frame
      // }
      setActiveImage(newValue);
    } else {
      setActiveImage(0);
      setShouldCycle(false);
      //        console.log("closing should cycle.  set should cycle to be false");
    }
  };

  const trackSpin = () => {
    ReactGA.event("page_event", {
      category: "interaction event",
      action: "click",
      label: "slotMachinePull",
    });
  };

  // to trigger roolling and maintain state
  const roll = () => {
    //    console.log("starting...");
    setShouldCycle(true);
    cycleImage();
    playLever();
    setTimeout(() => {
      console.log(`sound is :${sound.loop}`);
      sound.loop(true);
      playSpin();
    }, 500);
    if (tracks[0].length > minLength) {
      //      console.log("resetting");
      setReset(true); // this will reset and then spin
    } else {
      spinTracks();
    }
  };

  const spinTracks = () => {
    //    console.log("spinning...");
    const minSpeed = 3000;
    const speedRange = 5000;
    setTransitionSpeeds({
      top: Math.floor(Math.random() * speedRange + minSpeed),
      bottom: Math.floor(Math.random() * speedRange + minSpeed),
    });
    const minimumItems = Math.floor(Math.random() * 40 + 20);
    let randomNumberDraw = Math.floor(Math.random() * 50 + minimumItems);
    let indexDraw = Math.floor(Math.random() * eyes.length);
    const eyesItems = [];
    for (let i = 0; i < randomNumberDraw; i += 1) {
      if (indexDraw === eyes.length) {
        indexDraw = 0;
      }
      eyesItems.push(eyes[indexDraw]);
      indexDraw += 1;
    }

    randomNumberDraw = Math.floor(Math.random() * 50 + minimumItems);
    indexDraw = Math.floor(Math.random() * feet.length);
    const feetItems = [];
    for (let i = 0; i < randomNumberDraw; i += 1) {
      if (indexDraw === feet.length) {
        indexDraw = 0;
      }
      feetItems.push(feet[indexDraw]);
      indexDraw += 1;
    }
    setShouldSpin(true);
    setTracks([[...eyesItems], [...feetItems]]);
  };

  const maxWidth = ["250px", "250px", "600px"];
  const maxContentWidth = ["100%", "100%", "80%"];

  const width = ["156px", "156px", "62.5%"];
  const ml = ["47px", "47px", "112.5px"];

  const widthToHeight = 22 / 17;

  const topTrackWidths = [105, 105, 200];
  const topTrackDims = {
    w: [
      `${topTrackWidths[0]}px`,
      `${topTrackWidths[1]}px`,
      `${topTrackWidths[2]}px`,
    ], // 17:22
    h: [
      `${topTrackWidths[0] * widthToHeight}px`,
      `${topTrackWidths[1] * widthToHeight}px`,
      `${topTrackWidths[2] * widthToHeight}px`,
    ],
  };

  const bottomTrackWidths = [105, 105, 200];
  const bottomTrackDims = {
    w: [
      `${bottomTrackWidths[0]}px`,
      `${bottomTrackWidths[1]}px`,
      `${bottomTrackWidths[2]}px`,
    ], // 17:22
    h: [
      `${bottomTrackWidths[0] * widthToHeight}px`,
      `${bottomTrackWidths[1] * widthToHeight}px`,
      `${bottomTrackWidths[2] * widthToHeight}px`,
    ],
  };

  const itemMinWidths = ["125px", "125px", "250px"];
  const topTrackMl = ["430px", "430px", "1065px"];
  const topItemMt = ["20px", "20px", "-65px"];
  const bottomTrackMl = ["-390px", "-390px", "-945px"];
  const bottomItemMt = ["-57px", "-57px", "-100px"];

  let animationOffsets = {
    top: [0, 12, 12, 0],
    bottom: [0, -6, -6, 0],
  };

  if (windowSize.width > 768) {
    animationOffsets = {
      top: [0, 38, 38, 0],
      bottom: [0, -24, -24, 0],
    };
  }

  // console.log("render slot", topTrackDims, bottomTrackDims);
  return (
    <Box
//      backgroundColor={"black"}
      overflow={"hidden"}
      position="relative"
      pt="10"
      pb="20"
      onClick={() => !rolling && roll()}
      className="slot-machine"
    >
      <Heading
        alignContent={"center"}
//        color={"white"}
        textAlign="center"
        mb={5}
      >
        Feet-Eye-Matic 3000
      </Heading>

      <Box
        maxW={maxContentWidth}
        textAlign="center"
        mx="auto"
        px="5"
  //      textColor={"white"}
        pb={["2", "2", "10"]}
      >
        <Text>
          Pull the lever and try your luck! Well, this is really just a fun way
          to check out some additional combinations of FEET and EYES!
        </Text>
      </Box>
      <Center>
        <Box
          position="relative"
          mx="auto"
          //backgroundColor="yellow"
        >
          {" "}
          <Box
            maxW={maxWidth}
            position="absolute"
            top={["32px", "32px", "96px"]}
            left={0}
            width={width}
            height="100%"
            zIndex={0}
            ml={ml}
            // display="none"
            // backgroundColor="rgba(255, 0, 255, 0.5)"
          >
            <ChakraImage
              src={slotGradient}
              zIndex="0"
              alt="background gradient"
            />
          </Box>
          <Box position="relative">
            <ChakraImage
              src={slotMachine}
              maxW={maxWidth}
              zIndex="100"
              alt="Slot Machine"
            />
          </Box>
          <Box
            position="absolute"
            top={["-115px", "-115px", "-290px"]}
            left={["234px", "234px", "560px"]}
            width={["60px", "60px", "150px"]}
            backgroundSize="contain"
            height={["450px", "450px", "1415px"]}
            cursor="pointer"
            backgroundRepeat="no-repeat"
            // backgroundColor="purple"
            backgroundImage={`url('${handleImages[activeImage]}')`}
          ></Box>
          <Box
            maxW={maxWidth}
            position="absolute"
            top={0}
            left={0}
            width={width}
            height="100%"
            ml={ml}
            mr="107px"
            // backgroundColor="rgba(255, 0, 255, 0.5)"
          >
            <Box
              className="cards-container"
              width="100%"
              height="100%"
              marginTop="-13%"
              //              backgroundColor="rgba(255,255,0,0.5)"
            >
              <UnorderedList
                p="0"
                m="0"
                ref={slotRefs[0]}
                justifyContent="flex-start"
                ml={topTrackMl}
                // className="top-track"
              >
                {tracks[0].map((item, i) => {
                  let bgImage = (
                    <ChakraImage
                      src={item}
                      w={topTrackDims.w}
                      h={topTrackDims.h}
                      p="0"
                      m="0"
                      alt="Eye Item"
                    />
                  );
                  if (result.top && i === tracks[0].length - 2) {
                    bgImage = <></>;
                  }
                  return (
                    <ListItem
                      key={i}
                      mt={topItemMt}
                      mx={["-20px"]}
                      minWidth={itemMinWidths}
                    >
                      {bgImage}
                    </ListItem>
                  );
                })}
              </UnorderedList>
              <UnorderedList
                p="0"
                m="0"
                ref={slotRefs[1]}
                justifyContent="flex-end"
                ml={bottomTrackMl}
              >
                {tracks[1].map((item, i) => {
                  let bgImage = (
                    <ChakraImage
                      src={item}
                      w={bottomTrackDims.w}
                      h={bottomTrackDims.h}
                      p="0"
                      m="0"
                      alt="Feet Item"
                    />
                  );
                  if (result.top && i === 1) {
                    bgImage = <></>;
                  }
                  return (
                    <ListItem
                      key={i}
                      mt={bottomItemMt}
                      mx={["-20px"]}
                      minWidth={itemMinWidths}
                    >
                      {bgImage}
                    </ListItem>
                  );
                })}
              </UnorderedList>
            </Box>
          </Box>
          {result.top && (
            <Box
              maxW={maxWidth}
              position="absolute"
              top={0}
              left={0}
              width={width}
              height="100%"
              ml={ml}
              mr="107px"
            >
              <motion.div
                style={{ position: "relative", width: "100%", height: "100%" }}
                animate={{
                  scale: [1, 5],
                  opacity: [1, 0],
                  y: [0, -100, 600],
                  transitionEnd: {
                    scale: 1,
                    opacity: 1,
                    y: 0,
                  },
                }}
                transition={{
                  delay: 2,
                  duration: 3,
                  type: "spring",
                  bounce: 0.25,
                }}
              >
                <Box
                  className="cards-container"
                  width="100%"
                  height="100%"
                  marginTop="-13%"
                >
                  <MotionList
                    key="top-result"
                    animate={{
                      y: animationOffsets.top,
                      opacity: [1, 1],
                      x: [0, 0],
                    }}
                    // @ts-ignore
                    transition={{ duration: 5.5 }}
                    zIndex="99999999"
                    // className="top-track spinResults spinResultTop"
                    right={["-38px", "-38px", "-103px"]}
                  >
                    <ListItem
                      key={"result-top"}
                      mt={topItemMt}
                      mx={["-20px"]}
                      minWidth={itemMinWidths}
                    >
                      <ChakraImage
                        src={result.top}
                        w={topTrackDims.w}
                        h={topTrackDims.h}
                        p="0"
                        m="0"
                        alt="Eye Item"
                      />
                    </ListItem>
                  </MotionList>
                  <MotionList
                    key="bottom-result"
                    animate={{
                      y: animationOffsets.bottom,
                      opacity: [1, 1],
                      x: [0, 0],
                    }}
                    // @ts-ignore
                    transition={{ duration: 5.5 }}
                    justifyContent="flex-end"
                    marginLeft={bottomTrackMl}
                    className="bottom-track spinResults spinResultBottom"
                    right={["-170px", "-170px", "-420px"]}
                  >
                    <ListItem
                      key={"result-bottom"}
                      mt={bottomItemMt}
                      mx={["-20px"]}
                      minWidth={itemMinWidths}
                    >
                      <ChakraImage
                        src={result.bottom}
                        w={bottomTrackDims.w}
                        h={bottomTrackDims.h}
                        p="0"
                        m="0"
                        alt="Feet Item"
                      />
                    </ListItem>
                  </MotionList>
                </Box>
              </motion.div>
            </Box>
          )}
        </Box>
      </Center>
    </Box>
  );
};

export default SlotMachine;
