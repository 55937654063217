import { Box, Button, Center, Heading, Text } from "@chakra-ui/react";
import { FC } from "react";
import ButtonBox from "./ButtonBox";

interface ErrorProps {
  onComplete?: () => void;
  code: number;
}
const ErrorButton: FC<ErrorProps> = ({ code, onComplete }) => {
  return (
    <ButtonBox>
      <Center height={"100%"}>
        <Box backgroundColor="#000000ee" border="1px" py="10" zIndex={1000} width={"100%"}>
          <Heading>Error :(</Heading>
          <Text>
            There was an error submitting your score.
          </Text>
          <Text>
            DM @FeetAndEyesGuys if the problem persists.  Include a screenshot if you can.
          </Text>
          <Text>
            Code: {code}
          </Text>
          <Button onClick={onComplete} variant={"invertTextButton"} my="5">
            Got it
          </Button>
        </Box>
      </Center>
    </ButtonBox>
  );
};

export default ErrorButton;