import * as React from "react";
import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Link,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";

// const textColor = "currentColor";
const textColor = "white";

const QA = ({ q, children }: { q: string; children: any }) => {
  const accordionIconSize = ["30px", "30px", "60px"];
  return (
    <AccordionItem py="5" borderColor="rgba(255,255,255,0.3)">
      <AccordionButton _focus={{ boxShadow: "none" }}>
        <Box flex="1" textAlign="left">
          <Heading color={textColor} fontSize={["2xl", "2xl", "3xl"]}>
            Q: {q}
          </Heading>
        </Box>
        <AccordionIcon
          color={textColor}
          w={accordionIconSize}
          h={accordionIconSize}
        />
      </AccordionButton>
      <AccordionPanel pb={4}>
        {/* <Box flex="1" textAlign="left">
          <Heading color={textColor} fontSize="3xl" display="inline-block">A:</Heading>
        </Box> */}
        {children}
      </AccordionPanel>
    </AccordionItem>
  );
};

const FAQ: React.FC<any> = (props) => {
  return (
    <Box width={"100%"} as="section" pb="10" backgroundColor={"black"}>
      <Container maxW={{ xl: "1200px" }} px={0}>
        <Center>
          <Heading color="currentColor" mt={50} mb={3} textAlign="center" textColor={"white"}>
            Feetquently Asked Questions
          </Heading>
        </Center>
        <Flex
          width={"100%"}
          p={[0, 0, 5]}
          justifyContent={["center", "space-around", "space-around"]}
          alignItems="flex-start"
          position={"relative"}
          flexWrap={"wrap"}
          direction={{ base: "row" }}
        >
          <Accordion allowMultiple width="100%">
            <QA q="What are Feet and Eyes Guys?">
              <Text color={textColor}>
                Feet and Eyes Guys are a collection of randomly generated NFTs
                on the Ethereum blockchain. Each Feet and Eyes Guy is unique,
                and has a personality of its own. The attributes are all hand
                drawn by our artists Sam and Frank.
              </Text>
            </QA>
            <QA q="Where did the idea for these Guys come from?">
              <Text color={textColor}>
                The original Feet and Eyes Guy was created by two 9 year olds
                back in 1989, and became a family legend. Feet and Eyes Guys
                appeared on every school notebook, every bathroom stall and
                every other doodleable surface a Sharpie could find. Original
                Feet and Eyes Guys evolved to have various kinds of arms
                sticking out of their heads, which could complete most any task.
              </Text>
            </QA>
            <QA q="What is included in the artwork?">
              <Text color={textColor}>
                Each Feet and Eyes Guy is a completely unique combination of
                over 200 traits - no two are the same. They are made up of,
                well, feet and eyes to start, as well as a variety of hats,
                necklaces, and accessories. Some Guys may only end up being feet
                and eyes, some may be the whole kit and caboodle, and their
                rarity is calculated based on how many traits they share with
                the rest of the Guys.
              </Text>
            </QA>
            <QA q="Can you tell me more about the drawings?">
              <Text color={textColor}>
                The Feet and Eyes Guys, though designed in the 80’s, are being
                brought to life in 2022 by a wife and husband team of artists,{" "}
                <Link
                  href="https://twitter.com/sam_artypants"
                  target="_blank"
                  variant="standard"
                  rel="noopener"
                >
                  Sam
                </Link>{" "}
                and{" "}
                <Link
                  href="https://twitter.com/artist_dasfrank"
                  target="_blank"
                  variant="standard"
                  rel="noopener"
                >
                  Frank
                </Link>
                . We are very excited to be melding a classic cartoon style with
                a generative NFT project. As a team, we love and appreciate the
                care that goes into the classic cartoons of our youth. In a
                world where even the Disney animated classics are now computer
                generated, we find ourselves missing that original hand drawn
                Mickey Mouse style, rough edges and all. That is why we decided
                to design the Guys with a vintage feel, and to keep them in
                grayscale, as an homage to the classics.
              </Text>
            </QA>
            <QA q="What is the mint price for one Feet And Eyes Guys NFT?">
              <Text color={textColor}>
                Our mint price is free plus gas! That’s right! We are a FREE
                MINT! We are using a gas optimized contract to try to keep the
                gas fees as low as possible.
              </Text>
            </QA>
            <QA q="What is an NFT?">
              <Text color={textColor}>
                NFT stands for “Non-Fungible Token”. It is a unique digital
                asset that holds its individual value and is not
                interchangeable. An{" "}
                <Link
                  href="https://en.wikipedia.org/wiki/Non-fungible_token"
                  target="_blank"
                  variant="standard"
                  rel="noopener"
                >
                  NFT
                </Link>{" "}
                lives on a blockchain which is a digital ledger which stores all
                of the information about the creation, purchase, sale and resale
                of NFTs. Blockchains are part of the reason that an NFT
                maintains its value because it isn’t as simple as texting a
                photo back and forth between friends; when you buy an NFT, you
                own it because the blockchain says so.
              </Text>
            </QA>
            <QA q="What is MetaMask?">
              <Text color={textColor}>
                <Link
                  href="https://metamask.io/"
                  target="_blank"
                  variant="standard"
                  rel="noopener"
                >
                  MetaMask
                </Link>{" "}
                is a crypto-currency wallet that allows you to buy, store, use
                and transfer Ether (a.k.a. Ethereum,{" "}
                <Link
                  href="https://ethereum.org/en/"
                  target="_blank"
                  variant="standard"
                  rel="noopener"
                >
                  ETH
                </Link>
                ) the main crypto-currency associated with NFTs. You must create
                a MetaMask wallet and have ETH added to it prior to our drop in
                order to mint. To add ETH to your MetaMask wallet, you will need
                to purchase it by signing up with a crypto-trading platform. We
                recommend using either{" "}
                <Link
                  href="https://www.coinbase.com/"
                  target="_blank"
                  variant="standard"
                  rel="noopener"
                >
                  Coinbase
                </Link>{" "}
                or{" "}
                <Link
                  href="https://www.binance.com"
                  target="_blank"
                  variant="standard"
                  rel="noopener"
                >
                  Binance
                </Link>
                .
              </Text>
            </QA>
            <QA q="What happens when I buy my Feet And Eyes Guys NFT?">
              <Text color={textColor}>
                Once your NFT is minted, you will be able to view it using your
                wallet address on an NFT marketplace called{" "}
                <Link
                  href="https://opensea.io"
                  target="_blank"
                  variant="standard"
                  rel="noopener"
                >
                  Opensea
                </Link>
                .
              </Text>
            </QA>
            <QA q="I still don’t understand. What should I do?">
              <Text color={textColor}>
                Don’t worry! This space has a lot of pieces and we understand
                that it can be overwhelming at the start. Feel free to message
                anyone on our team on{" "}
                <Link
                  href="https://twitter.com/feetandeyesguys"
                  target="_blank"
                  variant="standard"
                  rel="noopener"
                  p="2"
                  mx="-2"
                >
                  Twitter
                </Link>{" "}
                or pop into our{" "}
                <Link
                  href="https://discord.gg/v5fH48cFFy"
                  target="_blank"
                  variant="standard"
                  rel="noopener"
                  p="2"
                  mx="-2"
                >
                  Discord
                </Link>{" "}
                and we will be more than happy to steer you in the right
                direction :)
              </Text>
            </QA>
            <QA q="How Can I Buy A Feet And Eyes Guy?">
              <Text color={textColor}>
                All 11,111 Guys are currently in circulation! If you want to
                purchase one you can use an NFT marketplace like <Link href="https://opensea.io/collection/feet-and-eyes-guys" target="_blank" variant="standard"
                  rel="noopener">OpenSea</Link> or {" "}
                <Link href="https://looksrare.org/collections/0xbf6301236d71C0d49144b35241905794F5a10BB9" target="_blank" variant="standard"
                  rel="noopener">LooksRare!</Link>
              </Text>
            </QA>
            <QA q="How Do I Get On The Allow List?">
              <Text color={textColor}>The allow list is now closed!</Text>
            </QA>
            <QA q="What does PROGRESSIVE REVEAL mean?">
              <Text color={textColor}>
                PROGRESSIVE REVEAL is our exciting new way of revealing traits.
                Instead of one big post-mint reveal, your GUY will reveal one
                trait at a time! In order to reveal your GUY you will need to go
                to our ARCADE and play our games! Each day you will be able to
                reveal one new trait until your GUY is fully revealed. Play and
                complete one of our games and a new trait will appear on your
                GUY. If a trait doesn’t appear, it might be because that trait
                is BLANK for your GUY, which will be reflected in the metadata.
                If your metadata doesn’t refresh automatically, click the
                REFRESH button on OpenSea for your token, and it will refresh
                to reveal your new trait. If you do not play the games, or
                cannot complete the games, your GUY will still be revealed on
                the FULL REVEAL DAY, which will be announced soon.
              </Text>
            </QA>
            <QA q="Why did you decide on a free mint?">
              <Text color={textColor}>
                The most important thing a creator can do is listen to and learn
                from the community and the market. We have been watching
                carefully and felt that given the current climate and state of
                the market, the most exciting way to grow our community
                organically would be through a free mint. Our priority is to get
                our beautiful artwork into the hands and wallets of our
                community so it can be appreciated by everyone the same way we
                have been appreciating it! We have some plans down the road that
                may end up involving a paid item or two, but this will be
                completely optional and will not hinder your enjoyment of our
                community if you choose not to participate.
              </Text>
            </QA>

            <QA q="What is your creator royalty?">
              <Text color={textColor}>
                We have decided on a 10% royalty. We are leaving it to the
                community to dictate the value of this project, and doing
                everything we can do to live up to your expectations! In order
                to grow the project further, we will eventually lean on our
                secondary market earnings to invest back into the project and
                carry out all of the plans we have!
              </Text>
            </QA>

            <QA q="What is the total supply?">
              <Text color={textColor}>
                There are 11,111 Feet And Eyes Guys total! The team has held
                back 111 for our treasury and future giveaways!
              </Text>
            </QA>

            <QA q="How will we know the rarity of our GUYS?">
              <Text color={textColor}>
                We are working with a rarity partner, <Link
                href="https://www.raraavis.app/feet-and-eyes-guys-tokens"
                target="_blank"
                variant="standard"
                rel="noopener"
              >Rara Avis</Link>, to calculate
                the concise <Link
                href="#rarity"
                variant="standard"
              >rarity</Link>{" "}
                 on all the GUYS as soon as they are all fully
                revealed!
              </Text>
            </QA>
            <QA q="How do I collab with you? Your project is so cool!">
              <Text color={textColor}>
                Fill out this{" "}
                <Link
                  href="https://mek1zh81oys.typeform.com/to/YWFEeaLo"
                  target="_blank"
                  variant="standard"
                  rel="noopener"
                >
                  collab form
                </Link>{" "}
                and we will get in touch with you!
              </Text>
            </QA>
          </Accordion>
        </Flex>
      </Container>
    </Box>
  );
};

export default FAQ;
