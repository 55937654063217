import { Box, Center, Spinner, Text } from "@chakra-ui/react";
import { FC } from "react";
import ButtonBox from "./ButtonBox";

const SubmittingScore: FC = () => {
  return (
    <ButtonBox>
      <Center height={"100%"}>
        <Box backgroundColor="#000000ee" py="10" height={"auto"} zIndex={50} width={"100%"} border="1px">
          <Text>
            Submitting your score
          </Text>
          <Spinner size={"xl"} mt="5"/>
        </Box>
      </Center>
    </ButtonBox>
  );
};

export default SubmittingScore;