import * as React from "react";
import {
  Box,
  Center,
  Container,
  Heading,
  Icon,
  IconButton,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { RiShoppingBagLine } from "react-icons/ri";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
  useMutation,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

// import ReactGA from "react-ga4";

import {
  useCheckoutEffect,
  createCheckout,
  checkoutLineItemsAdd,
  checkoutLineItemsUpdate,
  checkoutLineItemsRemove,
} from "./checkout";

import Product from "../../components/Merch/Product";
import Cart from "../../components/Merch/Cart";

import AppContainer from "../../components/AppContainer";

import "./app.css";
import { Helmet } from "react-helmet";

// const trackAddToCart = (product: any, quantity: number, variantId: any) => {
//   ReactGA.event("add_to_cart", {
//     currency: "CAD",
//     value: product.variants.edges[0].node.price * quantity,
//     items: [
//       {
//         item_id: product.id.toString(),
//         item_name: product.title,
//         affiliation: "Feet And Eyes Guys Website",
//         item_brand: "Feet And Eyes Guys",
//         item_list_id: "main_product_list",
//         item_list_name: "Main Product List",
//         item_variant: variantId,
//         price: product.variants.edges[0].node.price,
//         quantity: quantity,
//       },
//     ],
//   });
// };

// const trackRemoveFromCart = (product: any, quantity: number, variantId: any) => {
//   ReactGA.event("remove_from_cart", {
//     currency: "CAD",
//     value: product.variants.edges[0].node.price * quantity,
//     items: [
//       {
//         item_id: product.id.toString(),
//         item_name: product.title,
//         affiliation: "Feet And Eyes Guys Website",
//         item_brand: "Feet And Eyes Guys",
//         item_list_id: "main_product_list",
//         item_list_name: "Main Product List",
//         item_variant: variantId,
//         price: product.variants.edges[0].node.price,
//         quantity: quantity,
//       },
//     ],
//   });
// };


// see https://shopify.dev/api/storefront/reference/products/product
const query = gql`
  query query {
    shop {
      name
      description
      paymentSettings {
        countryCode
        currencyCode
        enabledPresentmentCurrencies
      }
      products(first: 20) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            title
            options {
              id
              name
              values
            }
            tags
            description
            descriptionHtml
            variants(first: 250) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              edges {
                node {
                  id
                  title
                  selectedOptions {
                    name
                    value
                  }
                  image {
                    src
                  }
                  price
                }
              }
            }
            images(first: 250) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              edges {
                node {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;

// Production account
const httpLink = createHttpLink({
  uri: "https://shop.feetandeyesguys.io/api/graphql",
});
const middlewareLink = setContext(() => ({
  headers: {
    "X-Shopify-Storefront-Access-Token": "6a66e62b126c3944eaea78dc167e5ed6",
  },
}));

// Adam's test account
// const httpLink = createHttpLink({
//   uri: "https://arhunterdevuser.myshopify.com/api/graphql",
// });
// const middlewareLink = setContext(() => ({
//   headers: {
//     "X-Shopify-Storefront-Access-Token": "34d9990fc0c7f4190f6d4b9fd6243be8",
//   },
// }));

// For Shopify Test Account
// const httpLink = createHttpLink({ uri: 'https://graphql.myshopify.com/api/graphql' })
// const middlewareLink = setContext(() => ({
//   headers: {
//     'X-Shopify-Storefront-Access-Token': 'dd4d4dc146542ba7763305d71d1b3d38'
//   }
// }))

const client = new ApolloClient({
  link: middlewareLink.concat(httpLink),
  cache: new InMemoryCache(),
});

// const client = new ApolloClient({
//   uri: 'https://48p1r2roz4.sse.codesandbox.io',
//   cache: new InMemoryCache()
// });

// function findProductFromCart(shopData: any, checkout: any, lineItemId: any) {
//   const lineItem = checkout.lineItems.edges.find((edge: any) => {
//     return edge.node.id === lineItemId;
//   });
//   if (lineItem) {
//     const product = shopData.shop.products.edges.find((productEdge: any) => {
//       console.log(productEdge);
//       return !!productEdge.node.variants.edges.find((variantEdge: any) => {
//         return variantEdge.node.id === lineItem.node.variant.id;
//       });
//     });
//     console.log(`found product item: ${product}`);
//     return { product, lineItem };
//   }
//   return { product: null, lineItem: null };
// }

function MerchItems() {
  const cartItemCountBackgroundColor = useColorModeValue(
    "gray.200",
    "gray.200"
  );
  const cartIconBackgroundColor = useColorModeValue(
    "rgba(255, 255, 255, 1)",
    "rgba(255, 255, 255, 1)"
  );
  const [isCartOpen, setCartOpen] = React.useState(false);
  const [checkout, setCheckout] = React.useState<any>({
    lineItems: { edges: [] },
  });

  const [
    createCheckoutMutation,
    {
      data: createCheckoutData,
      // loading: createCheckoutLoading,
      // error: createCheckoutError,
    },
  ] = useMutation(createCheckout);

  const [
    lineItemAddMutation,
    {
      data: lineItemAddData,
      // loading: lineItemAddLoading,
      // error: lineItemAddError,
    },
  ] = useMutation(checkoutLineItemsAdd);

  const [
    lineItemUpdateMutation,
    {
      data: lineItemUpdateData,
      // loading: lineItemUpdateLoading,
      // error: lineItemUpdateError,
    },
  ] = useMutation(checkoutLineItemsUpdate);

  const [
    lineItemRemoveMutation,
    {
      data: lineItemRemoveData,
      // loading: lineItemRemoveLoading,
      // error: lineItemRemoveError,
    },
  ] = useMutation(checkoutLineItemsRemove);

  const {
    loading: shopLoading,
    error: shopError,
    data: shopData,
  } = useQuery(query);

  React.useEffect(() => {
    const variables = { input: {} };
    createCheckoutMutation({ variables }).then(
      (res) => {
        // console.log(res);
      },
      (err) => {
        console.error("create checkout error", err);
      }
    );
  }, [createCheckoutMutation]);

  useCheckoutEffect(createCheckoutData, "checkoutCreate", setCheckout);
  useCheckoutEffect(lineItemAddData, "checkoutLineItemsAdd", setCheckout);
  useCheckoutEffect(lineItemUpdateData, "checkoutLineItemsUpdate", setCheckout);
  useCheckoutEffect(lineItemRemoveData, "checkoutLineItemsRemove", setCheckout);

  const handleCartClose = () => {
    setCartOpen(false);
  };

  const addVariantToCart = (variantId: any, quantity: any, product: any) => {
    const variables = {
      checkoutId: checkout.id,
      lineItems: [{ variantId, quantity: parseInt(quantity, 10) }],
    };
    // TODO replace for each mutation in the checkout thingy. can we export them from there???
    // create your own custom hook???

    lineItemAddMutation({ variables }).then((res) => {
      setCartOpen(true);
      // trackAddToCart(product, parseInt(quantity, 10), variantId);
    });
  };

  const updateLineItemInCart = (lineItemId: any, quantity: any) => {
    const variables = {
      checkoutId: checkout.id,
      lineItems: [{ id: lineItemId, quantity: parseInt(quantity, 10) }],
    };
    lineItemUpdateMutation({ variables }).then(() => {
      // const { product, lineItem } = findProductFromCart(shopData, checkout, lineItemId);
      // if (product) {
      //   const managedQuantity = parseInt(quantity, 10);
      //   console.log(`update items in cart.  quantity is: ${managedQuantity}`);
      //   if (managedQuantity > 0) {
      //     trackAddToCart(
      //       product.node,
      //       managedQuantity,
      //       lineItem.node.variant.id,
      //     );
      //   } else {
      //     trackRemoveFromCart(
      //       product.node,
      //       managedQuantity,
      //       lineItem.node.variant.id,
      //     );
      //   }
      // }
    });
  };

  const removeLineItemInCart = (lineItemId: any) => {
    const variables = { checkoutId: checkout.id, lineItemIds: [lineItemId] };
    lineItemRemoveMutation({ variables }).then(() => {
      // const { product, lineItem } = findProductFromCart(shopData, checkout, lineItemId);
      // if (product) {
      //   console.log('remove item in cart', lineItem);
      // }
    });
  };

  if (shopLoading) {
    return (
      <Box w="100%" textAlign="center">
        <Text>Loading ...</Text>
      </Box>
    );
  }

  if (shopError) {
    return <p>{shopError.message}</p>;
  }

  return (
    <Box className="App">
      {!isCartOpen && (
        <Box
          mr={["0.5rem", "0.5rem", "2rem"]}
          right="0"
          position="fixed"
          bottom={["2rem", "2rem", "2rem"]}
          border="solid 1px transparent"
          borderRadius="20px"
          backgroundColor={cartIconBackgroundColor}
          zIndex="999999"
          boxShadow={`0 0 5px 5px ${cartIconBackgroundColor}`}
        >
          <IconButton
            position="relative"
            size="sm"
            colorScheme="black"
            aria-label="Open Cart"
            onClick={() => setCartOpen(true)}
            color="black"
            bgColor="transparent"
            icon={
              <>
                <Icon as={RiShoppingBagLine} w="1.5rem" h="1.5rem" />
                <Box
                  as={"span"}
                  color={"currentColor"}
                  width="26px"
                  textAlign="center"
                  position={"absolute"}
                  top={"32px"}
                  right={"3px"}
                  fontSize={"0.8rem"}
                  bgColor={cartItemCountBackgroundColor}
                  borderRadius={"lg"}
                  p={"1px"}
                >
                  {checkout.lineItems.edges.length || 0}
                </Box>
              </>
            }
          />
        </Box>
      )}

      <Box
        maxW={"2xl"}
        margin="40px auto 0"
        display="flex"
        flexWrap="wrap"
        justifyContent={["center", "center", "flex-start"]}
        alignItems="flex-end"
        position="relative"
      >
        {shopData.shop.products.edges.map((product: any) => (
          <Product
            addVariantToCart={(variantId: any, quantity: any) =>
              addVariantToCart(variantId, quantity, product.node)
            }
            paymentSettings={shopData.shop.paymentSettings}
            checkout={checkout}
            key={product.node.id.toString()}
            product={product.node}
          />
        ))}
      </Box>
      <Cart
        removeLineItemInCart={removeLineItemInCart}
        updateLineItemInCart={(lineItemId: any, quantity: any) =>
          updateLineItemInCart(lineItemId, quantity)
        }
        checkout={checkout}
        isCartOpen={isCartOpen}
        handleCartClose={handleCartClose}
      />
    </Box>
  );
}

const Merch: React.FC<any> = (props) => {
  return (
    <ApolloProvider client={client}>
      <AppContainer>
        <Helmet>
          <title>Merch</title>
        </Helmet>
        <Box width={"100%"} as="section" pb="10">
          <Container maxW={{ xl: "1200px" }} px={0}>
            <Center>
              <Heading color="currentColor" mt={50} mb={3} textAlign="center">
                Toe-tally Amazing Merch
              </Heading>
            </Center>
            <MerchItems />
          </Container>
        </Box>
      </AppContainer>
    </ApolloProvider>
  );
};

export default Merch;
