import React from 'react';
import { createIcon } from "@chakra-ui/icons"

export const RaraAvisIcon = createIcon({
  displayName: "RaraAvisIcon",
  viewBox: "0 0 1024 1024",
  path: (
    <g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
fill="currentColor" stroke="none">
    <path d="M4800 10234 c-380 -33 -623 -71 -915 -144 -911 -226 -1719 -683
-2385 -1350 -840 -839 -1345 -1900 -1477 -3100 -26 -234 -26 -806 0 -1040 132
-1200 637 -2261 1477 -3100 839 -839 1901 -1345 3100 -1477 234 -26 806 -26
1040 0 1200 132 2261 637 3100 1477 839 839 1345 1901 1477 3100 26 234 26
806 0 1040 -73 666 -276 1333 -569 1878 l-45 83 -84 -93 c-47 -51 -253 -279
-458 -505 -205 -227 -375 -413 -378 -413 -3 0 22 30 54 68 380 430 843 966
843 974 0 30 -215 364 -357 553 -156 210 -530 631 -546 615 -2 -3 -159 -203
-348 -445 -527 -677 -660 -845 -665 -845 -5 0 68 97 614 819 205 271 372 496
370 500 -2 3 -30 32 -64 63 l-61 58 -20 -23 c-11 -12 -173 -218 -360 -457
-505 -648 -554 -710 -559 -710 -3 0 140 192 318 428 177 235 383 507 458 606
l135 178 -50 44 c-28 24 -54 42 -60 41 -10 -3 -110 -130 -674 -852 -299 -383
-332 -425 -316 -395 9 16 791 1054 902 1197 l64 82 -83 64 c-121 94 -321 237
-333 237 -5 -1 -41 -42 -80 -92 -224 -293 -2553 -3229 -2588 -3263 l-21 -20
16 20 c8 11 489 628 1069 1370 875 1122 1346 1723 1542 1968 19 23 27 41 21
46 -35 34 -429 257 -561 316 -73 34 -76 35 -89 16 -14 -19 -807 -1036 -1037
-1328 -71 -90 -130 -163 -133 -163 -3 0 91 127 209 283 117 155 377 497 576
761 199 263 359 482 354 486 -12 11 -262 112 -378 154 -379 134 -784 226
-1195 272 -137 15 -736 28 -850 18z m1197 -2086 c-28 -49 -1307 -2321 -1326
-2355 -12 -24 -45 -34 393 121 71 25 132 44 134 41 3 -3 -38 -105 -91 -227
-53 -122 -95 -223 -93 -225 3 -3 1652 1592 2236 2163 l75 73 94 -24 c51 -13
97 -27 101 -32 4 -4 24 -61 44 -126 l35 -117 -911 -1463 c-501 -804 -916
-1468 -922 -1476 -9 -12 -8 -13 4 -6 12 7 2792 1993 2840 2029 13 10 13 9 0
-8 -8 -11 -700 -780 -1538 -1710 l-1522 -1691 -478 -304 c-262 -167 -483 -305
-490 -308 -7 -3 -36 13 -65 35 l-52 41 -17 112 c-10 62 -19 114 -21 115 -2 2
-46 -2 -99 -8 l-96 -11 -358 -261 -359 -262 -159 -57 c-138 -50 -167 -64 -217
-108 l-58 -50 -3 -80 -3 -80 -71 80 -72 79 -12 89 c-7 48 -21 111 -32 138 -19
46 -20 65 -14 225 l7 175 97 210 96 210 103 106 103 105 10 145 c10 139 12
147 52 232 l42 87 -25 26 -25 25 -72 -66 -72 -67 -117 108 c-65 59 -120 109
-122 111 -2 1 65 233 150 514 l154 512 1380 1628 c759 896 1381 1629 1383
1629 2 0 -7 -19 -21 -42z"/>
</g>
  ),
});
